import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  id: string = '';
  constructor(private route: ActivatedRoute,private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        this.id = params.latest;
        if (this.id==='developments'){
          this.scrollToTest();
        }
        if (this.id==='foursight'){
          this.scrollToSub();
        }
      }
    );
  }

  scrollToTest() { 
    this.viewportScroller.scrollToAnchor('target');
  }

  scrollToSub() { 
    this.viewportScroller.scrollToAnchor('foursight');
  }
}
