import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-ambition',
  templateUrl: './our-ambition.component.html',
  styleUrls: ['./our-ambition.component.scss']
})
export class OurAmbitionComponent implements OnInit {

  constructor() { }
  showTopic:boolean= false;

  ngOnInit(): void {
  }

  openTopic(){
    this.showTopic = true;
  }

  close(){
    this.showTopic = false;
  }

}
