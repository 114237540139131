import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COUNTRIES } from '../../../foursight/press-release-data';
import { Observable } from "rxjs";
import { ContentfulService } from "../../../../services/contentful.service";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {DomSanitizer} from '@angular/platform-browser';
import { Location } from '@angular/common';

export interface articles {
  title: string
  excerpt:string
  bannerImg:string
  content:string
  url:string
  videoId:string
  newsletterLink:string | undefined
}


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  lessons$!: Observable<any>;
  allArr: articles[] =[];

  id: string = '';
  data: Array<any>=[];
  
  constructor(
    private route: ActivatedRoute,
    private contentful: ContentfulService,
    private sanitizer:DomSanitizer,
    private _location: Location,
    private router: Router
    ) {}

  ngOnInit(): void {
   // this.data = COUNTRIES
    this.route.queryParams.subscribe(params => {
      this.id = params.id;
     // console.log(this.id);
      var countAll = 0;

      this.lessons$ = this.contentful.getArticles();
      this.contentful.getArticles().subscribe(result => {
        for (let i = 0; i < result.length; i++) {
          
          var url = (result[i].fields as {url:string}).url;
          if (url.toLowerCase()===this.id){
          this.allArr[countAll]={ 
            title: '',
            excerpt:'',
            bannerImg:'',
            content:'',
            url:'',
            videoId:'',
            newsletterLink: ''
          };
          
            var title = (result[i].fields as {title:string}).title;
            var excerpt = (result[i].fields as {excerpt:string}).excerpt;
            var holdtest =  (result[i].fields as {bannerImg:any}).bannerImg.fields;
            var videoId = (result[i].fields as {videoId:string}).videoId;
            var newsletterLink = (result[i].fields as {newsletterLink:string}).newsletterLink;
            // console.log(result[i]);
            if(holdtest){
              var bannerImg = (result[i].fields as {bannerImg:any}).bannerImg.fields.file.url;
            }else{
              var bannerImg =null;
            }
            var content = (result[i].fields as {content:string}).content;
            this.allArr[countAll].title = title;
            this.allArr[countAll].excerpt = excerpt;
            this.allArr[countAll].bannerImg = bannerImg;
            this.allArr[countAll].content = content;
            this.allArr[countAll].url = url.toLowerCase();
            this.allArr[countAll].videoId = videoId;
            this.allArr[countAll].newsletterLink = newsletterLink;
            countAll++;
          }
        }
      });
    }

    
  );
  //this.showArticle = this.data[parseInt(this.id)];

  }

  backClicked(link: string) {
    const cleanLink = link.split('?')[0];
    const queryString = link.split('?')[1];
    const queryStringId = queryString.split('=')[1];
    this.router.navigate([cleanLink], {queryParams: {
      id: queryStringId
    }} )
  }

  _returnHtmlFromRichText(richText:any) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Error</p>';
    }
    return documentToHtmlString(richText);
}

  getImg(img:string){
    var hold = 'http://'+ img.slice(2);
    return hold;
  }
  
  getURL(video:string){
    let sanitizedUrl =this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+video);
    return sanitizedUrl;
   
  }
}
