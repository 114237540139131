import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-title-col',
  templateUrl: './title-col.component.html',
  styleUrls: ['./title-col.component.scss']
})
export class TitleColComponent implements OnInit {
  @Input() titleGreen: string = "";
  @Input() titleBlack: string = "";
  @Input() text: string = "";
  @Input() buttonText: string = "";
  @Input() link: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
