<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Investor Relations" childTitle="Investor Documents" [isLarge]=false></app-breadcrumbs>

    <app-documents-search-filter></app-documents-search-filter>

    <app-reit-with-a-difference-blocks
        title = "Fortress Investors"
        desc = ""
        box1icon = "../../../assets/images/investors-callout.svg"
        box1Title = "Investors Overview"
        box1desc = ""
        box1btnText = "Learn more"
        box1link = "/investor-relations/overview"
        box2icon = "../../../assets/images/sens-callout.svg"
        box2Title = "SENS"
        box2desc = "We provide our shareholders with information via Stock Exchange News Service (“SENS”) announcements and press releases. We provide the market with regular operational and distribution SENS announcements."
        box2btnText = "Learn more"
        box2link = "/investor-relations/investor-documents"
    ></app-reit-with-a-difference-blocks>

    <app-foursight-news></app-foursight-news>

    <app-staggered-four-blocks></app-staggered-four-blocks>

    <app-subscribe-banner></app-subscribe-banner>

    <app-explore-our-properties></app-explore-our-properties>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div>