import { Component, OnInit,Input  } from '@angular/core';

@Component({
  selector: 'app-form-enquiry',
  templateUrl: './form-enquiry.component.html',
  styleUrls: ['./form-enquiry.component.scss']
})
export class FormEnquiryComponent implements OnInit {
  @Input() id: string = '';
  @Input() title: string = '';
  @Input() contacts:any;
  url1:string="";
  url =   'https://forms.zohopublic.com/onecustom/form/ContactUs/formperma/EAj_2_n9iRUqlKiazd-5jo1N3EM-sHg6v_K82I1Qdf4/htmlRecords/submit';
  constructor() { }

  ngOnInit(): void {
    this.url1 = window.location.href;
    //console.log(this.contacts);
  }

}
