<div class="bg">
    <div class="container">
        <div class="content">
            <h1 class="main-title-colour">Let’s get</h1>
            <h1 class="main-title">in touch</h1>
        </div>
    </div>
    <div class="form-container">
        <div class="form-inner">
            <div class="col">
                <img src="../../../../assets/images/whistle-callout.svg" alt=""/>
               
            </div>
            <div class="col">
                <p>General Enquiries</p>
                <p class="text">We have established an independent whistleblowers hotline. Submissions made in relation to allegations are routed directly and exclusively to the following independent non-executive directors:</p>
                <p class="text"><b>Robin Lockhart-Ross</b></p>
                <div class="hr"></div>
                <p class="text-small">Disclosures, including anonymous disclosures, may be made as follows:</p>
                <p>Contact Us</p>
                <div class="row">
                    <p class="text">Toll free number: </p>
                    <p class="text">+27 80 202 8887</p>
                </div>
                <div class="row">
                    <p class="text">Email us:</p>
                    <a class="text link" href="mailto:fortress@tip-offs.com">fortress@tip-offs.com</a>
                </div>
                <div class="hr"></div>
               <!--  <p class="text-small">or fill out this tip-off form</p>

               <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <input id="first-name" type="text" formControlName="firstName" placeholder="Your Name (Optional)">
                    <input id="first-name" type="text" formControlName="email" placeholder="Your Email (Optional)">
                    <textarea id="first-name" type="text" formControlName="tip">Tip-off</textarea>
                    <button class="primary-btn-arrow" type="submit" [disabled]="!profileForm.valid">Submit Tip-off</button>
                </form>-->
                
            </div>
        </div>
    </div>

    <app-reit-with-a-difference-blocks
        title = "About Fortress"
        desc = "Explore what it is that makes us different, or take a look through our property portfolio."
        box1icon = "../../../assets/images/details-callout.svg"
        box1Title = "Find our Details"
        box1desc = ""
        box1btnText = "Learn more"
        box1link = "/our-details"
        box2icon = "../../../assets/images/whistle-callout.svg"
        box2Title = "Get in Touch"
        box2desc = "We strive to provide our shareholders with consistent returns through income and capital growth. Liquidity remains our focus. We continue achieving organic growth from our existing assets by developing core assets and recycling non-core assets. We regularly assess new opportunities and geographies, remaining opportunistic in a challenging market to create new avenues of growth for investors."
        box2btnText = "Learn more"
        box2link = "/get-in-touch"
    ></app-reit-with-a-difference-blocks>

    <app-subscribe-banner></app-subscribe-banner>


</div>