import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit {
  @Input() showText: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }
  
}
