import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss']
})
export class LoginBannerComponent implements OnInit {

  constructor() { }
  @Output() onClose = new EventEmitter();
  ngOnInit(): void {
  }

  onclicked(){
    this.onClose.emit();
  }


}
