import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } 
    from '@angular/forms';

@Component({
  selector: 'app-subscribe-banner',
  templateUrl: './subscribe-banner.component.html',
  styleUrls: ['./subscribe-banner.component.scss']
})
export class SubscribeBannerComponent  {
      
  form = new FormGroup({
    "firstName": new FormControl("", Validators.required),
    "email": new FormControl("", Validators.required),
});

  onSubmit() {
   // console.log("reactive form submitted");
   // console.log(this.form);
  }
}
