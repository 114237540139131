import {  Component } from "@angular/core";

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent  {
  count:number=0;
  max: number = 1;
  slide1:boolean=true;
  slide2:boolean=false;
  slide3:boolean=false;
  slide4:boolean=false;

  navigate(nav:number){
    if (nav === 1){
        if (this.max === this.count){
          this.count = 0;
        }else{
          this.count++;
        }
        
    }else{
      if (nav === -1){
        if (this.count === 0){
          this.count = this.max;
        }else{
          this.count--;
        }
      } 
    }
   // console.log(this.count);
    switch(this.count){
      case 0: this.slide1 = true;
              this.slide2 = false;
              this.slide3 = false;
              this.slide4 = false;
              break;
      case 1: this.slide1 = false;
              this.slide2 = true;
              this.slide3 = false;
              this.slide4 = false;
              break;  
      case 2: this.slide1 = false;
              this.slide2 = false;
              this.slide3 = true;
              this.slide4 = false;
              break;  
      case 3: this.slide1 = false;
              this.slide2 = false;
              this.slide3 = false;
              this.slide4 = true;
              break;  
    }
  }
}
