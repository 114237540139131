import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-impact',
  templateUrl: './our-impact.component.html',
  styleUrls: ['./our-impact.component.scss']
})
export class OurImpactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
