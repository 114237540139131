import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable }     from 'rxjs';

export interface props {
  askingPrice: any
  buildingCode: string
  city: string
  country: string
  description: any
  erfNo: any
  latitude:number
  latitudeString: string
  legalEntity: any
  lessorRegNo: any
  location: any
  longitude: number
  longitudeString: string
  buildingContactableList:any
  marketProperty: boolean
  totalUnits:number
  buildingImages:any
  buildingFeatures: any
  modified: boolean
  postalCode: any
  priceOnRequest: boolean
  propertyCode: string
  id: number
  buildingName: string
  province: string
  saleStatus: any
  saleStatusInt: any
  saleStatusString: any
  salesMarketingDescription: any
  sector: string
  sectorInt: number
  status: number
  statusInt: number
  streetAddress: string
  streetNumber: any
  subCategory: number
  subCategoryInt: number
  suburb: string
  buildingGla: number
  updatePropertyName: boolean
  vatNo: any
  videoUrl: string
  virtualTourUrl: string
  webUrl: string
  youTubeUrl: any
  _originalPropertyName: string
  frontPageImage:string
  imageBucketUrl:string
  compare:boolean
  units:any
  buildingId:any
  unitCount:any
}

@Injectable({
  providedIn: 'root'
})


export class FortressPropertyService {

  constructor(private http: HttpClient) { }

  authenticate(){
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    const body = 'grant_type=client_credentials&client_id=d8f30d20-a1a2-486e-86dd-637553f266ee&client_secret=juI7Q~r2gcUVjDMdY3ozMuEMy4QGIUDcrxnRX&scope=https://graph.microsoft.com/.default';
    this.http.post<any>('https://login.microsoftonline.com/43d0cb3a-4686-4c3b-976d-71d5da947c8a/oauth2/token', body, { headers }).subscribe({
      next: data => {
         // console.log(data)
      },
      error: error => {
          console.error('There was an error!', error);
      }
  })
  }

  getProperties(): Observable<props[]> {
    //return this.http.get('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings') .toPromise().then(response => response.json().data as props[]).catch(this.handleError);
    return this.http.get<props[]>('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings')
  }

  getUnits(id:number): Observable<props[]> {
    var hold = id.toString();
    //return this.http.get('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings') .toPromise().then(response => response.json().data as props[]).catch(this.handleError);
    return this.http.get<props[]>('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings/'+hold+'/units')
  }

  getBrochure(propID:string, unitList:any): Observable<any>{
    var hold = propID.toString();
    var body = {"buildingId":+propID,"unitIdList":unitList}
    return this.http.post('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings/'+hold+'/brochure',body,{
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    })                                            
  }

 

 

  getPropertyFeatures(propID:string){
    this.http.get<any>('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings/'+propID+'/industrialfeatures').subscribe({
      next: data => {
         // console.log(data)
      },
      error: error => {
          console.error('There was an error!', error);
      }
  })
  }



  getPropertyImages(propID:string): Observable<any[]>{
    return this.http.get<any>('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings/'+propID+'/images')
  }

  getPropertyContacts(propID:string){
    this.http.get<any>('https://fortressincomefund.azurewebsites.net/api/v1/marketingbuildings/'+propID+'/contacts/contactable').subscribe({
      next: data => {
        //  console.log(data)
      },
      error: error => {
          console.error('There was an error!', error);
      }
  })
  }


}
