<div class="container">
  <img [src]="icon" alt="" />
  <div>
    <h4>{{ title }}</h4>
    <p>{{ text }}</p>
    <a [routerLink]="url" [queryParams]="queryParams" class="clearLink"
      >{{ linkName }}
      <img src="../../../assets/icons/icon-arrow-green.svg" alt=""
    /></a>
  </div>
</div>
