import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-access',
  templateUrl: './data-access.component.html',
  styleUrls: ['./data-access.component.scss']
})
export class DataAccessComponent implements OnInit {
 
  constructor() { 
    
  }

  ngOnInit(): void {
    
    
  }

}
