<div class="container">
    <div class="content">
        <div class="heading-content">
            <h1 class="main-title">The power of</h1>
            <h1 class="main-title-colour">collaboration</h1>
        </div>
        <div class="inner-content">
            <div class="col">
                <img class="large-img" src="../../../../../assets/images/power-of-collab.webp" alt=""/>
                <div class="quote">
                    <img src="../../../../../assets/icons/icon-quote-green.svg" class="topQuote" alt=""/>
                    <p>A healthy community is a happy one and the happier the community, the greater the long-term sustainability of all parties that have a stake in its success.</p>
                    <p class="quote-auth">– Jodie Ellinor-Dreyer, Manager at Fortress</p>
                    <img src="../../../../../assets/icons/icon-quote-green.svg" class="bottomQuote" alt=""/>
                </div>
            </div>
            <div class="col">
                <p class="main-text">Fortress implemented an impactful approach to its Corporate Social Investment initiatives. Instead of a blanket approach, Fortress developed thoughtful resolutions tailored to the communities that Fortress operates in and aims to serve and grow. In doing this, Fortress takes responsibility for its activities and practices that affect the community, environment and society – while contributing to a community-driven solution. By developing integrated programmes that are responsive to community needs, the Fortress partnerships aims to drive real social impact. </p>
            </div>
           <!-- <div class="col">
                <p class="title">OUR MEASURABLE IMPACT</p>
                <div class="row">
                    <div class="small-col">
                        <img src="../../../../../assets/images/collab-tree.webp" alt=""/>
                        <p class="number">17,802</p>
                        <p>Trees planted</p>
                    </div>
                    <div class="small-col">
                        <img src="../../../../../assets/images/collab-family.webp" alt=""/>
                        <p class="number">435</p>
                        <p>Children educated</p>
                    </div>
                    <div class="small-col">
                        <img src="../../../../../assets/images/collab-people.webp" alt=""/>
                        <p class="number">432</p>
                        <p>Community trainees</p>
                    </div>
                    <div class="small-col">
                        <img src="../../../../../assets/images/collab-home-heart.webp" alt=""/>
                        <p class="number">39,667</p>
                        <p>Homestead and food garden support beneficiaries</p>
                    </div>
                    <div class="small-col">
                        <img src="../../../../../assets/images/collab-education.webp" alt=""/>
                        <p class="number">115</p>
                        <p>Tertiary education
                            bursaries</p>
                    </div>
                    <div class="small-col">
                        <img src="../../../../../assets/images/collab-business.webp" alt=""/>
                        <p class="number">40</p>
                        <p>Companies supported in enterprise development</p>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</div>
<div class="partner-container">
    <div class="content">
        <div class="col-logo">
            <img src="../../../../../assets/logo/logo-black.svg" alt=""/>
            <p>Organisations we partner with</p>
        </div>
        <div class="col-partners">
            <a href="https://www.propertycharter.co.za/" target="_blank"><img src="../../../../../assets/logo/partners/PropertyCharter.webp" alt=""/></a>
            <a href="https://raysofhope.co.za/" target="_blank"><img src="../../../../../assets/logo/partners/RaysOfHope.webp" alt=""/></a>
            <a href="https://kidshaven.co.za/" target="_blank"><img src="../../../../../assets/logo/partners/KidsHaven.webp" alt=""/></a>
            <a href="https://trees.org.za/" target="_blank"><img src="../../../../../assets/logo/partners/FoodAndTreesForAfrica.webp" alt=""/></a>
            <a href="https://www.propertypoint.org.za/" target="_blank"> <img src="../../../../../assets/logo/partners/PropertyPoint.webp" alt=""/></a>
            <a href="https://anchorsb.co.za/" target="_blank"><img src="../../../../../assets/logo/partners/Anchor.webp" alt=""/></a>
            <a href="https://saibpp.co.za/" target="_blank"><img src="../../../../../assets/logo/partners/SAIBPP.webp" alt=""/></a>
            <a href="https://presidentsaward.co.za/" target="_blank"><img src="../../../../../assets/logo/partners/ThePresidentsAward.webp" alt=""/></a>
            <a href="https://sparrowschools.co.za/" target="_blank"> <img src="../../../../../assets/logo/partners/SparrowSchools.webp" alt=""/></a>
        </div>
    </div>
</div>