import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showSubLinks1: boolean = false;
  showSubLinks2: boolean = false;
  showSubLinks3: boolean = false;
  showSubLinks4: boolean = false;
  showSubLinks5: boolean = false;
  showSubLinks6: boolean = false;

  toggleSubLinks(panelNumber: number) {
    if (panelNumber === 1) {
      this.showSubLinks1 = !this.showSubLinks1;
    } else if (panelNumber === 2) {
      this.showSubLinks2 = !this.showSubLinks2;
    } else if (panelNumber === 3) {
      this.showSubLinks3 = !this.showSubLinks3;
    } else if (panelNumber === 4) {
      this.showSubLinks4 = !this.showSubLinks4;
    } else if (panelNumber === 5) {
      this.showSubLinks5 = !this.showSubLinks5;
    } else if (panelNumber === 6) {
      this.showSubLinks6 = !this.showSubLinks6;
    }
  }

  constructor() {}

  testPos: boolean = false;

  @HostListener('mouseenter')
  mouseenter() {
    this.testPos = true;
  }

  @HostListener('mouseover')
  mouseover() {
    this.testPos = true;
  }

  @HostListener('mouseout')
  mouseout() {
    this.testPos = false;
  }

  @HostListener('document:click')
  clickedOut() {
    if (!this.testPos) {
      this.showMenu('empty');
    }
  }
  ngOnInit(): void {}

  burgerMenu: boolean = false;
  showSubMenu = {
    about: false,
    sustainability: false,
    properties: false,
    investor: false,
    login: false,
    foursight: false,
    showGradient: false,
  };
  showSubMenuMobile = {
    about: false,
    sustainability: false,
    properties: false,
    investor: false,
    foursight: false,
  };

  showMenu(item: string) {
    switch (item) {
      case 'about':
        this.showSubMenu.about = !this.showSubMenu.about;
        this.showSubMenu.properties = false;
        this.showSubMenu.investor = false;
        this.showSubMenu.login = false;
        this.showSubMenu.foursight = false;
        this.showSubMenu.sustainability = false;
        this.showSubMenu.showGradient = !this.showSubMenu.showGradient;
        break;
      case 'sustainability':
        this.showSubMenu.sustainability = !this.showSubMenu.sustainability;
        this.showSubMenu.properties = false;
        this.showSubMenu.investor = false;
        this.showSubMenu.login = false;
        this.showSubMenu.foursight = false;
        this.showSubMenu.about = false;
        this.showSubMenu.showGradient = !this.showSubMenu.showGradient;

        break;

      case 'properties':
        this.showSubMenu.properties = !this.showSubMenu.properties;
        this.showSubMenu.investor = false;
        this.showSubMenu.about = false;
        this.showSubMenu.login = false;
        this.showSubMenu.foursight = false;
        this.showSubMenu.sustainability = false;
        this.showSubMenu.showGradient = !this.showSubMenu.showGradient;

        break;
      case 'investor':
        this.showSubMenu.investor = !this.showSubMenu.investor;
        this.showSubMenu.properties = false;
        this.showSubMenu.about = false;
        this.showSubMenu.login = false;
        this.showSubMenu.foursight = false;
        this.showSubMenu.sustainability = false;
        this.showSubMenu.showGradient = !this.showSubMenu.showGradient;

        break;
      case 'login':
        this.showSubMenu.login = !this.showSubMenu.login;
        this.showSubMenu.investor = false;
        this.showSubMenu.properties = false;
        this.showSubMenu.about = false;
        this.showSubMenu.foursight = false;
        this.showSubMenu.sustainability = false;
        this.showSubMenu.showGradient = !this.showSubMenu.showGradient;

        break;
      case 'foursight':
        this.showSubMenu.foursight = !this.showSubMenu.foursight;
        this.showSubMenu.investor = false;
        this.showSubMenu.properties = false;
        this.showSubMenu.about = false;
        this.showSubMenu.login = false;
        this.showSubMenu.sustainability = false;
        this.showSubMenu.showGradient = !this.showSubMenu.showGradient;

        break;
      case 'empty':
        this.showSubMenu.foursight = false;
        this.showSubMenu.investor = false;
        this.showSubMenu.properties = false;
        this.showSubMenu.about = false;
        this.showSubMenu.login = false;
        this.showSubMenu.sustainability = false;
        this.showSubMenu.showGradient = false;
        break;
    }
  }

  showMenuMobile(item: string) {
    switch (item) {
      case 'about':
        this.showSubMenuMobile.about = !this.showSubMenuMobile.about;
        this.showSubMenuMobile.properties = false;
        this.showSubMenuMobile.investor = false;
        this.showSubMenuMobile.foursight = false;
        this.showSubMenuMobile.sustainability = false;
        break;
      case 'sustainability':
        this.showSubMenuMobile.sustainability =
          !this.showSubMenuMobile.sustainability;
        this.showSubMenuMobile.properties = false;
        this.showSubMenuMobile.about = false;
        this.showSubMenuMobile.investor = false;
        this.showSubMenuMobile.foursight = false;
        break;
      case 'properties':
        this.showSubMenuMobile.properties = !this.showSubMenuMobile.properties;
        this.showSubMenuMobile.investor = false;
        this.showSubMenuMobile.about = false;
        this.showSubMenuMobile.foursight = false;
        this.showSubMenuMobile.sustainability = false;
        break;
      case 'investor':
        this.showSubMenuMobile.investor = !this.showSubMenuMobile.investor;
        this.showSubMenuMobile.properties = false;
        this.showSubMenuMobile.about = false;
        this.showSubMenuMobile.foursight = false;
        this.showSubMenuMobile.sustainability = false;
        break;
      case 'foursight':
        this.showSubMenuMobile.foursight = !this.showSubMenuMobile.foursight;
        this.showSubMenuMobile.investor = false;
        this.showSubMenuMobile.properties = false;
        this.showSubMenuMobile.about = false;
        this.showSubMenuMobile.sustainability = false;
        break;
    }
  }

  toggleMenu() {
    this.burgerMenu = !this.burgerMenu;
  }

  closeAll() {
    this.burgerMenu = false;
    this.showSubMenu.showGradient = false
    this.showSubLinks1 = false;
    this.showSubLinks2 = false;
    this.showSubLinks3 = false;
    this.showSubLinks4 = false;
    this.showSubLinks5 = false;
    this.showSubLinks6 = false;
  }

  execOnClose($event: any) {
    this.showSubMenu.login = false;
    this.showSubMenu.foursight = false;
    this.showSubMenu.investor = false;
    this.showSubMenu.properties = false;
    this.showSubMenu.about = false;
    this.showSubMenu.sustainability = false;
    this.showSubMenuMobile.about = false;
    this.showSubMenuMobile.properties = false;
    this.showSubMenuMobile.investor = false;
    this.showSubMenuMobile.foursight = false;
    this.burgerMenu = false;
    this.showSubMenu.showGradient = false
  }
}
