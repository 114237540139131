import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-selected-provinces',
  templateUrl: './selected-provinces.component.html',
  styleUrls: ['./selected-provinces.component.scss']
})
export class SelectedProvincesComponent implements OnInit {
  @Input() selected: string = "";
  gauteng:boolean=false;
  kzn:boolean=false;
  mpumalanga:boolean=false;
  freestate:boolean=false;
  limpopo:boolean=false;
  wc:boolean=false;
  ec:boolean=false;
  nc:boolean=false;
  nw:boolean=false;
  constructor() {
  }

  ngOnInit(): void {
    //console.log(this.selected);
    switch (this.selected.toLowerCase()){
      case 'gauteng':
                      this.gauteng = true;
                      break;
      case 'kzn':
                      this.kzn = true;
                      break;
      case 'mpumalanga':
                        this.mpumalanga = true;
                        break;
      case 'freestate':
                      this.freestate = true;
                      break;
      case 'limpopo':
                      this.limpopo = true;
                      break;
      case 'wc':
                      this.wc = true;
                      break;
      case 'ec':
                      this.ec = true;
                      break;
      case 'nc':
                      this.nc = true;
                      break;
      case 'nw':
                      this.nw = true;
                      break;

    }
  }

}
