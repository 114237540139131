import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-investor-banner',
  templateUrl: './investor-banner.component.html',
  styleUrls: ['./investor-banner.component.scss']
})
export class InvestorBannerComponent implements OnInit {
  constructor() { }
  @Output() onClose = new EventEmitter();
  ngOnInit(): void {
  }

  onclicked(){
    this.onClose.emit();
  }


}
