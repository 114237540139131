<div class="container">

  <div class="left-content">
    <h4>Featured properties</h4>
    <p>Our diversified real estate portfolio primarily focuses on logistics and the convenience and commuter-oriented retail sectors in South Africa. We are actively looking for new retail and prime logistics development opportunities. Fortress&apos; top 10 properties, by market value, represent 33,4% of the total portfolio.</p>
    <a class="primary-btn"
    [routerLink]="['/properties/new-developments']">Explore our new developments</a>

    <!-- <a [routerLink]="['/properties/property-portfolio']">Browse all properties<img
            src="../../../assets/icons/icon-arrow-green.svg" alt=""></a> -->
  </div>
    <!-- <div class="top">
        
    </div> -->

    <div class="slider-container">

      <ngx-slick-carousel class="carousel featured-properties-carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
      
        <div ngxSlickItem class="slide" *ngFor="let property of properties; let i=index;">
          <div class="content">
            <div class="img-container">
              <img [src]="property.img" alt="Property Image" />
            </div>
            <div class="content-container" [ngClass]="{'visible': togglePanel[i]}">
              <a href="javascript:;" class="show-less-link" (click)="toggleDetails(i)">Show less 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M13.3337 10.0007H6.66699" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>
              <h2 class="title">{{ property.title }}</h2>
              <a href="javascript:;" class="show-more-link" (click)="toggleDetails(i)">Learn more 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M9.99967 6.66602V13.3327" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.3337 10.0007H6.66699" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </a>
              <!-- Hidden information that slides up when "Learn more" is clicked -->
              <div class="hidden-info">
                <p>{{ property.desc }}</p>
                <a class="enquire-btn" [href]="property.link">Explore this property</a>
              </div>
            </div>

          </div>
        </div>

      </ngx-slick-carousel>



    </div><!--slider-container ends-->


    <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
        (afterChange)="afterChange($event)">
        <div ngxSlickItem class="slide">
            <div class="content">
                <div class="block-content">
                    <h2>FORTRESS EASTPORT LOGISTICS PARK</h2>
                    <p>EastPort Logistics Park has access and exposure off the R21 and R25 main arterials near OR Tambo
                        airport. The state-of-the-art Logistic Park has 24-hour security, central fire tanks and pumps
                        offering fire safety and all the utilities that big operations require. Four main tenants
                        include Pick n Pay, Takealot, Clippa and Teraco.</p>
                    <div class="row">
                        <a class="primary-btn-arrow"
                            href="mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS EASTPORT LOGISTICS PARK">Enquire
                            Today</a>
                        <a class="clearLink" [routerLink]="['/properties/property-portfolio']">Browse our properties<img
                                src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                    </div>
                </div>
                <div class="block-img"
                    style="width: 50%; max-width: 550px; box-shadow: 6px 6px 10px hsl(206.5, 0%, 75%); margin: 2rem;">
                    <div style="position: relative; padding-bottom: 56.15%; height: 100%; overflow: hidden;">
                        <iframe style="position: absolute; top: 0; left:0; width: 100%; height: 100%; border: 0;"
                            loading="lazy" srcdoc="<style>
                      * {
                      padding: 0;
                      margin: 0;
                      overflow: hidden;
                      }
                      
                      body, html {
                        height: 100%;
                      }
                      
                      img, svg {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                      }
                      
                      svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                        transition: all 250ms ease-in-out;
                      }
                      
                      body:hover svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                        transform: scale(1.2);
                      }
                    </style>
                    <a href='https://www.youtube.com/embed/gwEZQ8R4Tl8?autoplay=1'>
                      <img src='https://img.youtube.com/vi/gwEZQ8R4Tl8/hqdefault.jpg' alt='Eastport'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                    </a>
                    " src="https://www.youtube.com/embed/gwEZQ8R4Tl8" title="Eastport" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>

            </div>
        </div>
        <div ngxSlickItem class="slide">
            <div class="content">
                <div class="block-content">
                    <h2>FORTRESS CLAIRWOOD LOGISTICS PARK</h2>
                    <p>Clairwood Logistics Park sets a new standard in prime A-grade logistics facilities in KZN.
                        Developed in phases, it has 24-hour security as well as large yards and office space with direct
                        access to the Durban ports by rail. Main tenants include African Sugar Logistics, Super Group,
                        Kings Rest Container and SAMMAR Investments.</p>
                    <div class="row">
                        <a class="primary-btn-arrow"
                            href="mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS CLAIRWOOD LOGISTICS PARK">Enquire
                            Today</a>
                        <a class="clearLink" [routerLink]="['/properties/property-portfolio']">Browse our properties<img
                                src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                    </div>
                </div>
                <div class="block-img"
                    style="width: 50%; max-width: 550px; box-shadow: 6px 6px 10px hsl(206.5, 0%, 75%); margin: 2rem;">
                    <div style="position: relative; padding-bottom: 56.15%; height: 100%; overflow: hidden;">
                        <iframe style="position: absolute; top: 0; left:0; width: 100%; height: 100%; border: 0;"
                            loading="lazy" srcdoc="<style>
                      * {
                      padding: 0;
                      margin: 0;
                      overflow: hidden;
                      }
                      
                      body, html {
                        height: 100%;
                      }
                      
                      img, svg {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                      }
                      
                      svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                        transition: all 250ms ease-in-out;
                      }
                      
                      body:hover svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                        transform: scale(1.2);
                      }
                    </style>
                    <a href='https://www.youtube.com/embed/GLJ3YpFmj9g?autoplay=1'>
                      <img src='https://img.youtube.com/vi/GLJ3YpFmj9g/hqdefault.jpg' alt='Eastport'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                    </a>
                    " src="https://www.youtube.com/embed/GLJ3YpFmj9g" title="Eastport" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide">
            <div class="content">
                <div class="block-content">
                    <h2>FORTRESS LONGLAKE LOGISTICS PARK</h2>
                    <p>Longlake Logistics Park consists of two strategically situated portions of land and is located
                        close to the N3 Marlboro Interchange. With access via the new k113 to the east and N3 to the
                        north and south, the premium A-grade park is located within 15km of Sandton CBD and ORT
                        International Airport. Main tenants include ZEST and Cargo Carriers. The state-of-the-art
                        Logistic Park has 24-hour security, central fire tanks and pumps offering fire safety and all
                        the utilities that big operations require.</p>
                    <div class="row">
                        <a class="primary-btn-arrow"
                            href="mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS LONGLAKE LOGISTICS PARK">Enquire
                            Today</a>
                        <a class="clearLink" [routerLink]="['/properties/property-portfolio']">Browse our properties<img
                                src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                    </div>
                </div>
                <div class="block-img"
                    style="width: 50%; max-width: 550px; box-shadow: 6px 6px 10px hsl(206.5, 0%, 75%); margin: 2rem;">
                    <div style="position: relative; padding-bottom: 56.15%; height: 100%; overflow: hidden;">
                        <iframe style="position: absolute; top: 0; left:0; width: 100%; height: 100%; border: 0;"
                            loading="lazy" srcdoc="<style>
                      * {
                      padding: 0;
                      margin: 0;
                      overflow: hidden;
                      }
                      
                      body, html {
                        height: 100%;
                      }
                      
                      img, svg {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                      }
                      
                      svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                        transition: all 250ms ease-in-out;
                      }
                      
                      body:hover svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                        transform: scale(1.2);
                      }
                    </style>
                    <a href='https://www.youtube.com/embed/N1ZFVL2r2lQ?autoplay=1'>
                      <img src='https://img.youtube.com/vi/N1ZFVL2r2lQ/hqdefault.jpg' alt='Eastport'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                    </a>
                    " src="https://www.youtube.com/embed/N1ZFVL2r2lQ" title="Eastport" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide">
            <div class="content">
                <div class="block-content">
                    <h2>FORTRESS LOUWLARDIA LOGISTICS PARK</h2>
                    <p>Louwlardia Logistics Park is strategically located along the N1 motorway with easy access to all
                        arterial routes. The park offers excellent visibility from one of the busiest roads linking
                        Pretoria to Johannesburg. It is also located in a secure business park with perimeter with
                        24-hour security, making it a particularly desirable location for corporates with large scale
                        warehousing requirements. Main tenants include USN.</p>
                    <div class="row">
                        <a class="primary-btn-arrow"
                            href="mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS LOUWLARDIA LOGISTICS PARK">Enquire
                            Today</a>
                        <a class="clearLink" [routerLink]="['/properties/property-portfolio']">Browse our properties<img
                                src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                    </div>
                </div>
                <div class="block-img"
                    style="width: 50%; max-width: 550px; box-shadow: 6px 6px 10px hsl(206.5, 0%, 75%); margin: 2rem;">
                    <div style="position: relative; padding-bottom: 56.15%; height: 100%; overflow: hidden;">
                        <iframe style="position: absolute; top: 0; left:0; width: 100%; height: 100%; border: 0;"
                            loading="lazy" srcdoc="<style>
                      * {
                      padding: 0;
                      margin: 0;
                      overflow: hidden;
                      }
                      
                      body, html {
                        height: 100%;
                      }
                      
                      img, svg {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                      }
                      
                      svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                        transition: all 250ms ease-in-out;
                      }
                      
                      body:hover svg {
                        filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                        transform: scale(1.2);
                      }
                    </style>
                    <a href='https://www.youtube.com/embed/VkFK95o3Ndc?autoplay=1'>
                      <img src='https://img.youtube.com/vi/VkFK95o3Ndc/hqdefault.jpg' alt='Eastport'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                    </a>
                    " src="https://www.youtube.com/embed/VkFK95o3Ndc" title="Eastport" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
                
            </div>
        </div>
    </ngx-slick-carousel> -->

</div>