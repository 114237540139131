<div class="container">
    <div class="col-gallery">
       <div class="small-gallery">
            <img *ngFor="let img of images; let i = index"  [src]="getUrl(img.fileName, img.imageSectionString)" (click)="changeURL(img.fileName, img.imageSectionString)" alt=""/>
           
        </div>
        <!--<div class="large-img" [style.background-image]="currentImage">
            
        </div>-->
        <div class="large-img">
            <p>{{photoTag}}</p>
            <img class="largeImg"  [src]="bigImage" alt=""/>
        </div>
    </div>
</div>

