import { Component, Input, OnInit } from '@angular/core';

export interface props {
  askingPrice: any
  buildingCode: string
  city: string
  country: string
  description: any
  erfNo: any
  latitude:number
  buildingContactableList:any
  buildingFeatures: any
  latitudeString: string
  legalEntity: any
  lessorRegNo: any
  location: any
  longitude: number
  longitudeString: string
  marketProperty: boolean
  modified: boolean
  buildingImages:any
  postalCode: any
  priceOnRequest: boolean
  propertyCode: string
  totalUnits:number
  id:number
  buildingName: string
  province: string
  saleStatus: any
  saleStatusInt: any
  saleStatusString: any
  salesMarketingDescription: any
  sector: number
  sectorInt: number
  status: number
  statusInt: number
  streetAddress: string
  streetNumber: any
  subCategory: number
  subCategoryInt: number
  suburb: string
  buildingGla: number
  updatePropertyName: boolean
  vatNo: any
  videoUrl: string
  virtualTourUrl: string
  webUrl: string
  youTubeUrl: any
  _originalPropertyName: string
  frontPageImage:string
  imageBucketUrl:string
  units:any
  buildingId:any
}

@Component({
  selector: 'app-similar-properties',
  templateUrl: './similar-properties.component.html',
  styleUrls: ['./similar-properties.component.scss']
})
export class SimilarPropertiesComponent implements OnInit {
  @Input() props:any;
  @Input() sector:any;
  @Input() currentID:any;
  

  allProp: props[] =[];
  countProp = 0;
  constructor() { }

  ngOnInit(): void {
   // console.log(this.props);

    for (var i=0; i < this.props.length; i++){
      //console.log(this.props[i].sector);
      if ((this.sector === this.props[i].sector) && (this.countProp <4) && (this.currentID !== this.props[i].id)){
        this.allProp[this.countProp] = this.props[i];
        this.countProp++;
      }
    }
  }

 
  getUrl(bucket:string, image:string){
    if ((image === null)||(bucket === null)){
      return "url(../../../../../assets/images/no-prop-image.webp)";
    }else{
      image = image.replace(/\s/g, "%20");
      return "url("+bucket+"/"+image+")";
    }
  }

}
