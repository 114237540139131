import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-southafrica',
  templateUrl: './southafrica.component.html',
  styleUrls: ['./southafrica.component.scss']
})
export class SouthafricaComponent implements OnInit {

  isImageActive1 = false;
  isImageActive2 = false;
  isImageActive3 = false;
  isImageActive4 = false;
  isImageActive5 = false;
  isImageActive6 = false;
  isImageActive7 = false;
  isImageActive8 = false;
  isImageActive9 = false;
  toggleImageClass(pin: number): void {
    if (pin === 1) {
      this.isImageActive1 = !this.isImageActive1;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    } else if (pin === 2) {
      this.isImageActive2 = !this.isImageActive2;
      this.isImageActive1 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    } else if (pin === 3) {
      this.isImageActive3 = !this.isImageActive3;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    } else if (pin === 4) {
      this.isImageActive4 = !this.isImageActive4;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    } else if (pin === 5) {
      this.isImageActive5 = !this.isImageActive5;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    } else if (pin === 6) {
      this.isImageActive6 = !this.isImageActive6;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    }
    else if (pin === 7) {
      this.isImageActive7 = !this.isImageActive7;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive8 = false;
      this.isImageActive9 = false;
    }
    else if (pin === 8) {
      this.isImageActive8 = !this.isImageActive8;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive9 = false;
    }
    else if (pin === 9) {
      this.isImageActive9 = !this.isImageActive9;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
      this.isImageActive7 = false;
      this.isImageActive8 = false;
    }
    
  }

  hideImage: boolean = false;

  setAllInactive() {
    console.log('image clicked');
  }

  

  constructor() { }

  ngOnInit(): void {
  }

}
