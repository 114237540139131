<div class="container" [ngStyle]="{'background-image': backgroundSVG ? 'url(../../../../../assets/icons/circle-accent.svg)' : 'none'}">
    <div class="col">
        <div class="white-block">
            <h3>{{title}}</h3>
            <p class="intro">{{intro}}</p>
            <p class="description">{{desc}}</p>
            <a class="primary-btn"
            [routerLink]="['/investor-relations/overview']">Discover more</a>
        </div>
    </div>
    <div class="col right">
        <div class="callout-block">
            <img class="logo" [src]="box1icon" alt=""/>
            <div>
                <h4>{{box1Title}} </h4>
                <p>{{box1desc}}</p>
                <a class="clearLink" [routerLink]="box1link">{{box1btnText}} <img src="../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
            </div>
        </div>
        <div class="callout-block">
            <img class="logo" [src]="box2icon" alt=""/>
            <div>
                <h4>{{box2Title}} </h4>
                <p>{{box2desc}}</p>
                <a *ngIf="!routerQuery2" class="clearLink" [routerLink]="box2link">{{box2btnText}} <img src="../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                <a *ngIf="routerQuery2" class="clearLink" [routerLink]="box2link" [queryParams]="{ document: 'sens' }">{{box2btnText}} <img src="../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
            </div>
        </div>
    </div>
</div>