<div class="banner-container">
  <div class="banner-inner">
    <div class="content-text">
      <h3>FOURSight</h3>
      <p>
        Subscribe to our regular insights, analysis, articles and webinars on
        all issues affecting our growth-powering real estate
      </p>
    </div>
    <div class="content">
      <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <input type="text" placeholder="Your name" formControlName="firstName">
                <input class="emailInput" type="text" placeholder="Your email" formControlName="email">
                <button type="submit" [disabled]="!form.valid">Subscribe <img src="../../../assets/icons/icon-menu-arrow.svg" alt=""/></button>
            </form>-->
      <iframe
        src="//property.fortressfund.co.za/form/23"
        allowtransparency="true"
        width="100%"
        height="400"
        frameBorder="0"
        scrolling="no"
        ><p>Your browser does not support iframes.</p></iframe
      >
    </div>
  </div>
</div>
