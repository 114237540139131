<div class="footer-container">
  <div class="row">
    <div class="nav-elements">
        <div class="logo">
            <img
              class="footer-logo"
              src="../../../assets/logo/logo-green.svg"
              alt=""
            />
            <div class="social-container">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCCkLnosxubxYV-rgV8Fge4A"
                ><img src="../../../assets/icons/icon-twitter.svg" alt=""
              /></a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/fortressreitlimited/"
                ><img src="../../../assets/icons/icon-linkedin-green.svg" alt=""
              /></a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCCkLnosxubxYV-rgV8Fge4A"
                ><img src="../../../assets/icons/icon-youtube.svg" alt=""
              /></a>
            </div>
          </div>
      <div class="col">
        <a [routerLink]="['/about/about-us']"
          >About Fortress<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['sustainability/our-impact']"
          >Sustainability<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['/properties/property-portfolio']"
          >Our Properties<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['/investor-relations/overview']"
          >Investor Relations<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['/foursight']"
          >Foursight News<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
      </div>
      <div class="col">
        <p>
          Block C, Cullinan Place, <br />Cullinan Close, <br />Morningside
          <br />2196 <br />South Africa
        </p>
      </div>
      <div class="col">
        <p class="address">
            Fortress Real Estate Investments Limited <br />Registration Number:
            2009/016487/06 <br />Share codes: FFA and FFB <br />ISIN:
            ZAE000248498 and ZAE000248506
          </p>
      </div>
    </div>
  </div>
</div>
