import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shareholder-analysis',
  templateUrl: './shareholder-analysis.component.html',
  styleUrls: ['./shareholder-analysis.component.scss']
})
export class ShareholderAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
