import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { ContentfulService } from "../../../services/contentful.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from "@angular/common";
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit {
  searchText:string='';
  document: string = '';
 categories=[
    {
      name: "All",
    },
    {
      name: "SENS",
    },
    {
      name: "Annual Reports",
    },
    {
      name: "Interim Reports",
    },
    {
      name: "Presentations",
    },
    {
      name: "Webcast",
    },
    {
      name: "DMTN Programme",
    },
    {
      name: "BBBEE Certification",
    },
    {
      name: "Circulars",
    },
    {
      name: "Rights Offers",
    },
    {
      name: "MOI & Capital Conversion",
    },
    {
      name: "Prospectus & Listing Particulars",
    },
];


lessons$!: Observable<any>;




  constructor(private scroller: ViewportScroller,private contentful: ContentfulService, private route: ActivatedRoute,private router: Router, private authenticationService: AuthenticationService) { 
  
  }

  ngOnInit(): void {
    this.contentful.getContents().subscribe((result) => {
      for (let i = 0; i < result.length; i++) {
        var holdPublished = (result[i].fields as { published: string })
          .published;
        if (!holdPublished){
          var holdtest = (result[i].fields as { media: any }).media.fields;
          console.log(holdtest);
        }
        
      }
    });
    this.lessons$ = this.contentful.getContents();
    //console.log(this.lessons$);
   
  }

  


  getName(name:string){
    return "View";
  }

  getURL(url:string){
    let newURL = "https:"+url;
    return newURL;
  }

  viewPDF(url:string){
    let newURL = "https:"+url;
    localStorage.setItem('pdf',newURL);
    this.router.navigate(['financials/view-pdf']);
  }

  getImage(cat:string){
    let img = "";
    switch(cat) {
      case "SENS": 
          img = "../../../../../assets/icons/document/SENS.svg";
          break;
      case "Annual Reports": 
          img = " ../../../../../assets/icons/document/annual.svg";
          break;
      case "Interim Reports": 
          img = " ../../../../../assets/icons/document/interim.svg";
          break;
      case "Presentations":   
          img = " ../../../../../assets/icons/document/presentation.svg";
          break;
      case "Webcast":         
          img = " ../../../../../assets/icons/document/webcast.svg";
          break;
      case "DMTN Programme":  
          img = " ../../../../../assets/icons/document/dmtn.svg";
          break;
      case "BBBEE Certification": 
          img = " ../../../../../assets/icons/document/bbbee.svg";
          break;
      case "Circulars": 
          img = " ../../../../../assets/icons/document/circulars.svg";
          break;
      case "Right Offers": 
          img = " ../../../../../assets/icons/document/offers.svg";
          break;
      case "MOI & Capital Conversion": 
          img = " ../../../../../assets/icons/document/moi.svg";
          break;
      case "Prospectus & Listing Particulars": 
          img = " ../../../../../assets/icons/document/prospectus.svg";
          break;
    }
    return img;
  }

  clearFilter(){
    this.searchText='';
  }


  logout() {  
    this.authenticationService.logout();  
    this.router.navigate(['']);  
  } 
}
