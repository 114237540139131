<div class="container">
    <app-white-icon-title-text-button-block
    icon="../../../assets/icons/icon-bespoke-solution.svg"
    title="Bespoke solutions"
    text=""
    linkName=""
    url=""
    >
    </app-white-icon-title-text-button-block>
    <app-white-icon-title-text-button-block
    icon="../../../assets/icons/icon-properties.svg"
    title="Speculative builds"
    text=""
    linkName=""
    url=""
    >
    </app-white-icon-title-text-button-block>
   <div class="title-block">
       <h3>Tailored to you</h3>
       <p>Key to Fortress’s success is our ability to partner with clients through either bespoke solutions or speculative builds. We power growth by understanding your challenges and supporting your evolution. We act in response to change with state-of-the art logistics boxes tailored to meet your business and service ambitions.</p>
   </div>
</div>