<div class="container-footprint">
    <div class="content">
        
            <div class="col">
                <iframe *ngIf="lat && long" class="iframeMap" width="300" height="450" frameborder="0" scrolling="no"
                    marginheight="0" marginwidth="0" [src]="getLocation()">
                </iframe>
            </div>
            <div class="col">
                <p class="small-heading">address</p>
                <p>{{address}}, {{province}}</p>
                <div class="latLong">
                    <p *ngIf="lat">Latitude: {{lat}}</p>
                    <p *ngIf="long">Longitude: {{long}}</p>
                </div>
                <a class="primary-btn" [href]="getLocationURL()" target="_blank">Open on Google Maps</a>
                <div class="link-hold" *ngIf="web || google">
                    <a class="clearLink" *ngIf="web" [href]="web" target="_blank">Visit Website<img
                            src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                    <a class="clearLink" *ngIf="google" [href]="google" target="_blank">Google virtual tour<img
                            src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
                <p class="small-heading" *ngIf="!stat1 || !stat2 || !stat3">key properties stats</p>
                <div class="stats-container">
                    <div class="stat" *ngIf="stat1">
                        <p class="large-number">{{stat1}}</p>
                        <div class="hr"></div>
                        <p class="text">{{statText1}}</p>
                    </div>
                    <div class="stat margin-top" *ngIf="stat2">
                        <p class="large-number">{{stat2}}</p>
                        <div class="hr"></div>
                        <p class="text">{{statText2}}</p>
                    </div>
                    <div class="stat" *ngIf="stat3">
                        <p class="large-number">{{stat3}}</p>
                        <div class="hr"></div>
                        <p class="text">{{statText3}}</p>
                    </div>
                    <p class="small-heading" *ngIf="access||internet||yard||generator">property features</p>
                    <div class="row" *ngIf="access||internet">
                        <div class="col-small" *ngIf="access">
                            <img src="../../../../../assets/icons/property-features/small-security.svg" alt=""
                                style="width: 24px;">
                            <p *ngIf="access">Access Type: {{access}}</p>
                            <p *ngIf="!access">Access Type: Not listed</p>
                        </div>
                        <div class="col-small" *ngIf="internet">
                            <img src="../../../../../assets/icons/property-features/small-internet.svg" alt="">
                            <p *ngIf="internet">Internet Description: {{internet}}</p>
                            <p *ngIf="!internet">Internet Description: Not listed</p>
                        </div>
                    </div>
                    <div class="row" *ngIf="yard||generator">
                        <div class="col-small" *ngIf="generator">
                            <img src="../../../../../assets/icons/property-features/small-generator.svg" alt="">
                            <p *ngIf="generator">Generators: {{generator}}</p>
                            <p *ngIf="!generator">Generators:Not listed</p>
                        </div>
                        <div class="col-small" *ngIf="yard">
                            <img src="../../../../../assets/icons/property-features/small-green.svg" alt="">
                            <p *ngIf="yard">Yard Description: {{yard}}</p>
                            <p *ngIf="!yard">Yard Description:Not listed</p>
                        </div>
                    </div>
                    <!-- <a class="primary-btn-arrow">Open on Google Maps</a>-->
                </div>
            </div>

       
    </div>
</div>

    <div class="container-footprint-long">
        <div class="content">

            <div class="col">
                <iframe *ngIf="lat && long" class="iframeMap" width="300" height="450" frameborder="0" scrolling="no"
                    marginheight="0" marginwidth="0" [src]="getLocationStreetView()">
                </iframe>
            </div>
        </div>
    </div>