import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-banner-foursight',
  templateUrl: './banner-foursight.component.html',
  styleUrls: ['./banner-foursight.component.scss']
})
export class BannerFoursightComponent implements OnInit {
  @Input() title: string = "";
  @Input() link: string = "";
  @Input() linkText: string = "";
  @Input() text: string = "";
  @Input() image: string = "";
  @Input() imageAlone: string = "";
  @Input() subTitle: string = "";
  @Input() goHome: boolean = false;
  showRouter:boolean = true;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if ((this.link.includes("www"))||(this.link.includes("http"))){
      this.showRouter = true;
    }else{
      this.showRouter = false;
    }
  }

  parseText(): string {
    // Regular expression to find email addresses
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
    
    // Replace email addresses with clickable links
    const parsedText = this.text.replace(emailRegex, '<a href="mailto:$1" style="color: #00C5CB !important">$1</a>');
  
    // Sanitize the HTML content
    return this.sanitizer.bypassSecurityTrustHtml(parsedText) as string;
  }

}
