import { Pipe, PipeTransform } from '@angular/core';
import { retry } from 'rxjs/operators';

@Pipe({
  name: 'searchFilterProperties'
})
export class SearchFilterPropertiesPipe implements PipeTransform {

  transform(value: any, args?:any, category?:any, country?:any, province?:any  ): any {
    if (!value) return null;
    if ((!args)&&(!category)&&(!country)&&(!province)) return value;

   
    args = args.toLowerCase();
    
    if (category){
      category = category.toLowerCase();
    }
    if (country){
      country = country.toLowerCase();
    }
    if (province){
      province = province.toLowerCase();
    }

    if (category){
      switch (category){
        case "industrial": category = 'sector":"industrial"'
                            break;
        case "logistics": category = 'sector":"logistics"'
                            break;     
        case "office": category = 'sector":"office"'
                            break;         
        case "retail": category = 'sector":"retail"'
                            break; 
        case "other": category = 'sector":"other"'
                            break;     
      }
    }
   
    return value.filter(function(item:any){
      if (args){
        if (category){
          if (country){
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(category) && JSON.stringify(item).toLowerCase().includes(country)&& JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(category) && JSON.stringify(item).toLowerCase().includes(country)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }else{
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(category) && JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(category)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }
        }else{
          if (country){
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(args)  && JSON.stringify(item).toLowerCase().includes(country)&& JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(country)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }else{
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(args)&& JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(args)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }
        }
      }else{
        if (category){
          if (country){
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(category)&& JSON.stringify(item).toLowerCase().includes(country)&& JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(category)&& JSON.stringify(item).toLowerCase().includes(country)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }else{
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(category)&& JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(category)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }
        }else{
          if (country){
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(country)&& JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              if (JSON.stringify(item).toLowerCase().includes(country)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }
          }else{
            if (province){
              if (JSON.stringify(item).toLowerCase().includes(province)){
                return (JSON.stringify(item).toLowerCase());
              }else{
                return null;
              }
            }else{
              return null;
            }
          }
        }
      }

    


       /* if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(category)&& JSON.stringify(item).toLowerCase().includes(country)&& JSON.stringify(item).toLowerCase().includes(province)){
          return (JSON.stringify(item).toLowerCase());
        }else{
          return null;
        }*/
    });
  }
    

}