<div class="container"  id="target">
    <div class="form-group">
        <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
        <h2>Schedule Of Properties</h2>
        <div class="input">
            <img class="first" src="../../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
            <input type="text" class="form-control search-field" placeholder="Search by keyword" [(ngModel)]="searchText"/>
            <img (click)="clearFilter()" class="last" src="../../../../../assets/icons/icon-close-black.svg" alt=""/>
        </div>
    </div>
    <div class="data-table">
        <div class="filter">
            <p class="filterTitle">Filter your results</p>
            <div class="hr"></div>
            <div class="filterbox">
                <div class="selectedOption" (click)="toggleFilter(1)">
                    <p>{{propertyTypeFilter}}</p>
                    <img [ngClass]="{'open' : openPropertyType}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
                </div>
                <div class="options" *ngIf="openPropertyType">
                    <p (click)="selectProperty('All')">All</p>
                    <p (click)="selectProperty('Logistics')">Logistics</p>
                    <p (click)="selectProperty('Office')">Office</p>
                    <p (click)="selectProperty('Retail')">Retail</p>
                    <p (click)="selectProperty('Industrial')">Industrial</p>
                </div>
            </div>
            <div class="filterbox">
                <div class="selectedOption" (click)="toggleFilter(5)">
                    <p>{{propertyCountryFilter}}</p>
                    <img [ngClass]="{'open' : openPropertyCountry}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
                </div>
                <div class="options" *ngIf="openPropertyCountry">
                    <p (click)="selectCountry('All')">All</p>
                    <p (click)="selectCountry('South Africa')">South Africa</p>
                    <p (click)="selectCountry('Poland')">Poland</p>
                    <p (click)="selectCountry('Romania')">Romania</p>
                </div>
            </div>
            <div class="filterbox" *ngIf="showProvinceFilter">
                <div class="selectedOption" (click)="toggleFilter(2)">
                    <p>{{provinceTypeFilter}}</p>
                    <img [ngClass]="{'open' : openProvinceType}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
                </div>
                <div class="options" *ngIf="openProvinceType">
                    <p (click)="selectProvince('All')">All</p>
                    <p (click)="selectProvince('Gauteng')">Gauteng</p>
                    <p (click)="selectProvince('Limpopo')">Limpopo</p>
                    <p (click)="selectProvince('North West')">North West</p>
                    <p (click)="selectProvince('Kwazulu Natal')">Kwazulu Natal</p>
                    <p (click)="selectProvince('Northern Cape')">Northern Cape</p>
                    <p (click)="selectProvince('Eastern Cape')">Eastern Cape</p>
                    <p (click)="selectProvince('Western Cape')">Western Cape</p>
                    <p (click)="selectProvince('Freestate')">Freestate</p>
                    <p (click)="selectProvince('Mpumalanga')">Mpumalanga</p>
                </div>
            </div>
          
         

            <div class="hr"></div>
        </div>
        <div class="tableWrapper">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Property Name</th>
                        <th scope="col">GLA (m<sup>2</sup>)</th>
                        <th scope="col">Address</th>
                        <th scope="col">Province</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of allProp | searchFilter: searchText| paginate : {itemsPerPage: pageSize,  currentPage: page,totalItems: count}">
                        <td>{{item.buildingName}}</td>
                        <td>{{item.buildingGla}}</td>
                        <td>{{item.address}}</td>
                        <td>{{item.province}}</td>
                    </tr>
                </tbody>
                <pagination-controls
                previousLabel="Prev"
                nextLabel="Next"
                [responsive]="true"
                (pageChange)="handlePageChange($event)"
            ></pagination-controls>
            </table>
        </div>
        
    </div>
    
</div>