<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Investor Relations" childTitle="Upcoming Events"></app-breadcrumbs>

    <app-banner-foursight
        title="2023 Fortress Running Series"
        link="https://www.entrytime.com/Home.aspx"
        linkText="Enter now"
        image=""
        imageAlone="../../../assets/images/1200x1200-square-race.webp"
        text="Get ready to take part in the Fortress Running Series, an event that celebrates the passion South Africans have for running. Since its inception in 2018, the Fortress Running Series has been dedicated to bringing the thrill of running events to rural communities, providing a platform for local residents to participate and showcase their athletic talent. Fortress Retail is proud to host these extraordinary races in partnership with the esteemed Israel Morake Foundation (IM Foundation), led by renowned athletes Israel Morake and Hezekiel Sepeng, alongside Athletics Administrator Dorah Mngwevu. Enter this Race Worth Running NOW!"
        subTitle =""
    ></app-banner-foursight>

 

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div> 