import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-whistleblower',
  templateUrl: './whistleblower.component.html',
  styleUrls: ['./whistleblower.component.scss']
})
export class WhistleblowerComponent implements OnInit {
  profileForm = new FormGroup({
    firstName: new FormControl(''),
    email: new FormControl(''),
    tip: new FormControl(''),
  });
  constructor() { }

  ngOnInit(): void {
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }
}
