<div class="bg">
  <div class="container">
    <app-breadcrumbs
      grandparentTitle="Home"
      grandparentLink="/"
      parentTitle="About Fortress"
      childTitle="Meet the Team"
      [isLarge]="false"
    ></app-breadcrumbs>

    <div class="hero">
      <div class="col">
        <h1>Meet the team</h1>
        <p>
          Our employees are a fundamental part of our business. Their
          specialised knowledge, skills and experience are essential for the
          business' long-term success. Therefore, we aim to attract and retain
          motivated, high-calibre executives and employees. We leverage our
          employees' skills to ensure sound management of our properties.
          Investing in our people, challenging them and developing their
          capabilities will ensure our business' sustainability.
        </p>
        <a class="secondary-btn" [routerLink]="['/get-in-touch']"
          >Get in touch</a
        >
      </div>
    </div>
  </div>

  <div class="container mobile-hero-img">
    <img src="../../../../assets/images/team/meet-the-team-hero.png" />
  </div>

  <app-team-blocks></app-team-blocks>

  <app-reit-with-a-difference-blocks
    title="Where We Invest"
    intro="Our diversified real estate portfolio primarily focuses on the logistics and convenience and commuter-based retail sectors in South Africa. We are actively looking for new retail and prime logistics development opportunities."
    desc="Our investment in NEPI Rockcastle provides us with exposure to the high-growth retail sector in Central and Eastern European (CEE) markets. We are selective and intend growing our high-quality logistics portfolio across the CEE region in the coming years. The market is in its development phase with a lot of opportunities for real estate investment for Fortress. As retail competition in the advanced economies of Western Europe is increasingly determined by the ability to successfully support e-commerce via omnichannel, growth in the logistics real estate sector in CEE will continue for decades to come."
    box1icon="../../../assets/icons/about-us-icon.svg"
    box1Title="About us"
    box1desc="We are Fortress with a difference. We connect businesses with growth-powering real estate opportunities in state-of-the art logistics parks and convenience and commuter-based retail."
    box1btnText="Learn more"
    box1link="/about/about-us"
    box2icon="../../../assets/icons/our-impact-icon.svg"
    box2Title="Our Impact"
    box2desc="We are committed to using innovation to reduce our impact on the environments in which we operate. Sustainable and ethical practices are a priority in the way we do business."
    box2btnText="Learn more"
    box2link="/sustainability/our-impact"
  ></app-reit-with-a-difference-blocks>

  <app-subscribe-banner></app-subscribe-banner>

  <app-foursight-news></app-foursight-news>

  <!-- <app-get-in-touch-banner></app-get-in-touch-banner> -->
</div>
