import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staggered-four-blocks',
  templateUrl: './staggered-four-blocks.component.html',
  styleUrls: ['./staggered-four-blocks.component.scss']
})
export class StaggeredFourBlocksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
