<div class="container">
  <div class="callout-block">
    <img class="logo" src="../../../assets/logo/logo-logistics.svg" alt="" />
    <p>
      We are South Africa’s largest owner and developer of state-of-the-art
      logistics real estate. We are on track to have two-thirds of our portfolio
      directly held in high-end logistics in South African and Central and
      Eastern Europe.
    </p>
  </div>
  <div class="callout-block">
    <img class="logo" src="../../../assets/logo/logo-retail.svg" alt="" />
    <p>
      We own a portfolio of convenience and commuteroriented shopping centres in
      South Africa. We are also the largest shareholder in NEPI Rockcastle,
      which is active in high-growth retail real estate in Central and Eastern
      Europe.
    </p>
  </div>
  <!-- <div class="about-block">
        <h3>About Fortress</h3>
        <p>Explore what it is that makes us different, or take a look through our property portfolio.</p>
        <a class="primary-btn" [routerLink]="['/about/about-us']">Button text</a>
    </div>-->
</div>
