import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-power-of-collab',
  templateUrl: './power-of-collab.component.html',
  styleUrls: ['./power-of-collab.component.scss']
})
export class PowerOfCollabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
