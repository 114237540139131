<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="About us"
        childTitle="Company PAIA"></app-breadcrumbs>

    <app-banner-foursight title="The Company PAIA" link="" linkText="" image="url(../../../assets/images/whiteriver.webp)"
    imageAlone="../../../assets/images/whiteriver.webp"
        text="PAIA Manual in terms of Section 51 of the Promotion of Access to Information Act 2 of 2000 (“PAIA”)"
        subTitle=""></app-banner-foursight>

    <div class="container">
        <div class="row">
            <h5>Name of Company:</h5>
            <p>Fortress Real Estate Investments  (including any of its subsidiaries more fully described in annexure “A” hereto)
                (the "Company")</p>
        </div>
        <div class="row">
            <h5>Registration Number:</h5>
            <p>2009/016487/06</p>
        </div>
        <div class="row">
            <h5>Introduction:</h5>
            <p>Fortress Real Estate Investments  ("Fortress") is a leading South African Real Estate Investment Trust which
                specialises in developing and acquiring state-of-the-art logistics real estate, and convenient,
                commuter-based retail centres. Fortress was listed in October 2009 on the main board of the Johannesburg
                Stock Exchange Limited as a property loan stock company. Its initial market capitalisation was R1.8
                billion, making it the largest initial listing in the listed property sector.</p>
        </div>
        <table>
            <tbody>
                <tr>
                    <td>
                        <h5>Street Address:</h5>
                    </td>
                    <td>
                        <p>Block C, Cullinan Place, Cullinan Close, Morningside, 2196</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5>Postal Address:</h5>
                    </td>
                    <td>
                        <p>PO Box 138, Rivonia, 2128</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5>Phone Number:</h5>
                    </td>
                    <td>
                        <p>+27 (11) 282 2800</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5>Fax Number:</h5>
                    </td>
                    <td>
                        <p>+27 (86) 231 7314</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5>Auditors:</h5>
                    </td>
                    <td>
                        <p>Deloitte & Touche</p>
                    </td>
                </tr>
            </tbody>
        </table>

        <h4>Name and contact details of Information Officer and Deputy Information Officer</h4>

        <div class="plainRow">
            <h5>1. Information Officer: Wiko Serfontein</h5>
            <p>Block C, Cullinan Place, Cullinan Close, Morningside, 2196<br />
                +27 (11) 282 2800<br />
                <a href="mailto:info@fortressfund.co.za">info@fortressfund.co.za</a>
            </p>
        </div>

        <div class="plainRow">
            <h5>2. Deputy Information Officer: Sarel Coetzer</h5>
            <p>Block C, Cullinan Place, Cullinan Close, Morningside, 2196<br />
                +27 (11) 282 2800<br />
                <a href="mailto:info@fortressfund.co.za">info@fortressfund.co.za</a>
            </p>
        </div>

        <h4>INTRODUCTION</h4>

        <p>This manual is published in terms of S51 of PAIA, which gives effect to section 32 of the Constitution –
            providing for the right of access to information held by the State and another person (or private body) when
            such privately held information is required to exercise or protect a right.<br /><br />
            This manual sets out the types and categories of records held by the Company and the manner in which access
            to these records may be requested.</p>

        <h4>GUIDE ON HOW TO USE THIS MANUAL IN COMPLIANCE WITH PAIA</h4>

        <p>In terms of Section 10 of PAIA, the South African Human Rights Commission must compile and publish a guide to
            assist persons wishing to exercise any right contemplated in PAIA ("the Guide").<br /><br />
            The Guide is available from the offices of the Human Rights Commission, and on the website at
            <a href="https://www.sahrc.org.za/" target="_blank">www.sahrc.org.za</a>.<br /><br />
            Please direct your enquiries to:<br />
            <b>The South African Human Rights Commission</b><br /><br />
        </p>

        <h5>Postal address:</h5>
        <p>South African Human Rights Commission<br />
            Promotion of Access to Information Act Unit<br />
            Research and Documentation Department<br />
            Private Bag 2700, Houghton, 2041<br /><br />

            Telephone: +27 (11) 877 3600<br />
            Fax: +27 (11) 403 0668<br />
            Website: <a href="https://www.sahrc.org.za/" target="_blank">www.sahrc.org.za</a> or <a
                href="mailto:section51.paia@sahrc.org.za">section51.paia@sahrc.org.za</a><br /><br />

            Email <a href="mailto:paia@sahrc.org.za">paia@sahrc.org.za</a><br /><br />

            The Guide will describe, in each official language:</p>
        <ul>
            <li>the objects of PAIA</li>
            <li>the details of each private body (where possible)</li>
            <li>the process that needs to be followed in order to make a request</li>
            <li>how to get copies of the Guide at no charge</li>
            <li>how to get access to the manual of a private body</li>
        </ul>
        <p>all the remedies available in law to a requester.</p>
        <h4>AUTOMATIC AVAILABILITY OF RECORDS</h4>
        <p>
            The Company has not made any submission to the Minister (being the cabinet member responsible for the
            administration of Justice) in terms of section 52(1) of PAIA, and the Minister has not published any notices
            in respect of the Company in terms of section 52(2) of PAIA..
            Categories of records of the Company that are already publicly available without you having to request
            access in terms of POPIA:
        </p>
        <ul>
            <li>Company's constitutional and other statutory documents</li>
            <li>Annual Reports, Interim Reports and Results and Investment Presentations, where applicable</li>
            <li>All other information available on the Fortress website at www.fortressfund.co.za</li>
        </ul>
        <p>Request forms for these categories of information are also available from the Company's Information Officer,
            whose contact details appear in the introduction to this Manual.</p>

        <h4>RECORDS OF THE COMPANY THAT ARE AVAILABLE IN ACCORDANCE WITH LEGISLATION</h4>
        <p>All records kept and made available in terms of legislation applicable to any of the entities listed in this
            manual and the Financial Services Industry and / or Real Estate in general, as it applies to the specific
            environment in which the entities operate, are available in accordance with the said legislation.
            <br /><br />
            Legislation may include, but is not limited to:
        </p>
        <ul>
            <li>Arbitration Act 42 of 1965</li>
            <li>Basic Conditions of Employment Act 75 of 1997</li>
            <li>Broad-Based Black Economic Empowerment Act 53 of 2003</li>
            <li>Companies Act 71 of 2008</li>
            <li>Compensation for Occupational Injuries and Diseases Act 130 1993</li>
            <li>Competition Act 89 of 1998</li>
            <li>Consumer Protection Act 68 of 2008</li>
            <li>Debt Collectors Act 114 of 1998</li>
            <li>Electronic Communications and Transactions Act 25 of 2002</li>
            <li>Employment Equity Act 55 of 1998</li>
            <li>Financial Intelligence Centre Act 38 of 2001</li>
            <li>Harmful Business Practices Act 23 of 1999</li>
            <li>Income Tax Act 58 of 1962</li>
            <li>Insolvency Act 24 of 1936</li>
            <li>Insurance Act 63 of 2001</li>
            <li>Labour Relations Act, 66 of 1995</li>
            <li>Medical Schemes Act, 131 of 1998</li>
            <li>National Building Regulations and Building Standards Act 103 of 1977</li>
            <li>National Credit Act 34 of 2005</li>
            <li>National Environmental Management Act 107 of 1998</li>
            <li>National Health Act 61 of 2003</li>
            <li>Occupational Health and Safety Act 85 of 1993</li>
            <li>Pension Funds Act 24 of 1956</li>
            <li>Prevention of Organised Crime Act 121 of 1998</li>
            <li>Promotion of Equality and Prevention of Unfair Discrimination Act 4 of 200</li>
            <li>Protected Disclosures Act 26 of 2000</li>
            <li>Protection of Personal Information Act 4 of 2013</li>
            <li>Regulation of Interception of Communications and Provision of Communication-Related Information Act 70
                of 2002</li>
            <li>Short-Term Insurance Act 53 of 1998</li>
            <li>Skills Development Act, 97 of 1998</li>
            <li>Skills Development Levies Act 9 of 1999</li>
            <li>Stock Exchanges Control Act 1 of 1985 and the rules and listings requirements of the JSE Securities
                Exchange authorised in terms thereof</li>
            <li>Unemployment Insurance Act, 63 of 2001</li>
            <li>Unemployment Insurance Contributions Act 4 of 2002</li>
            <li>Value Added Tax Act 89 of 1991</li>
        </ul>

        <h4>AVAILABILITY OF THIS MANUAL</h4>

        <p>Copies of this manual are available on the Company's website (www.fortressfund.co.za), or by sending a
            request for a copy to the Company's Information Officer. Copies are also available for inspection at the
            offices of the Company and copies can be made at a charge of R1,10 (one rand ten cents) per A4 page.</p>

        <h4>REQUEST PROCEDURE AND RECORDS THAT MAY BE REQUESTED</h4>

        <p>Every request for a record of the Company must contain the information set out in paragraphs 1 to 7 below in
            terms of section 53 of PAIA and must substantially correspond with the prescribed form set out in Annexure B
            hereto.<br /><br />
            Please click this link to access the online form:<br /><br />
            or<br /><br />
            The form is also available from The Company's Information Officer (whose contact details are in the
            introduction to this Manual), the SAHRC website (<a href="https://www.sahrc.org.za/" target="_blank">www.sahrc.org.za</a>); and the Department of Justice and
            Constitutional Development website (<a href="https://www.justice.gov.za/" target="_blank">www.justice.gov.za/</a>).<br /><br />
            The Company's Information Officer has been delegated with the task of receiving and coordinating any
            requests for access to records in terms of PAIA and the Protection of Personal Information Act, 2013
            ("POPIA"). Each request should specify the description of the record concerned, and the location of the
            records, if known. It shall also:</p>
        <ol>
            <li>Provide sufficient particulars of the records required to identify the record/s and the identity of the
                requester.</li>
            <li>Indicate the form of access required, if the request is granted.</li>
            <li>Specify the requester's postal address or fax number in South Africa, or an e-mail address.</li>
            <li>Identify the right being exercised or protected and give an explanation of why the requested record is
                needed for this purpose. It is important to note that access is not automatic; therefore, this step is
                of particular importance.</li>
            <li>Indicate the preferred form of reply to the request, other than a written reply, with the relevant
                particulars. The requester will be notified in the manner indicated by him/her/it on the request for
                access form whether or not his/her/its request has been approved.</li>
            <li>Give proof of the capacity in which the requester is acting, if requesting access on behalf of another,
                to the reasonable satisfaction of the Information Officer.</li>
            <li>Give proof of payment of the prescribed access fee. Please note, in certain instances there is a
                prescribed fee (payable in advance where applicable) for requesting and accessing information in terms
                of PAIA. An access fee, which must be calculated by taking into account reproduction costs, search and
                preparation time and cost, as well as postal costs, may also be imposed.</li>
        </ol>
        <p>Where an institution has voluntarily provided the Minister with a list of categories of records that will
            automatically be available, the only charge levied will be a fee for the reproduction of the record in
            question.<br /><br />
            In terms of 54(3)(b) of PAIA a requester may lodge a complaint with the Information Regulator or make an
            application with a court against the tender or payment of the request fee or the tender or payment of a
            deposit, as the case may be.<br /><br />
            If an individual is unable to complete the prescribed form because of illiteracy or disability, such a
            person may make the request orally.<br /><br />
            The Company will process the request for access within 30 days of receipt of the request for access, unless
            the request for access is of such a nature that an extension of the prescribed time limit is necessitated in
            accordance with section 57 of PAIA. In the case of an extension of the time limit, the requester has the
            right to lodge a complaint with the Information Regulator in accordance with section 57(3)(c) of PAIA by
            following the process and completing the form prescribed by POPIA. The requester may also make an
            application with a court against the refusal of the request.<br /><br />
            If, in addition to a written reply from the Information Officer, the requester wishes to be informed of the
            decision on the request for access in any other manner, the requester must state the manner and the
            particulars so required.<br /><br />
            The Company will voluntarily provide the requested records to a personal requester (as defined in section 1
            of PAIA). The prescribed fee for reproduction of the record requested by a personal requester will be
            charged in accordance with section 54(6) of PAIA.<br /><br />
            If the search for a record in respect of which a request for access by a requester has been made; and the
            preparation of that record for disclosure would, in the opinion of the Information Officer, require more
            than the hours prescribed for this purpose, the Information Officer must by notice require the requester to
            pay as a deposit the prescribed portion (being not more than one third) of the access fee which would be
            payable should the request be granted.<br /><br />
            The requester may lodge a complaint with the Information Regulator against the tender of the request fee or
            the tender or payment of a deposit, as the case may be.<br /><br />
            <b>Once complete, the request form should be mailed to:</b><br />
        </p>

        <table>
            <thead>
                <tr>
                    <th>Information Officer: Wiko Serfontein</th>
                    <th>Deputy Information Officer: Sarel Coetzer</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Block C, Cullinan Place, Cullinan Close, Morningside, 2196</td>
                    <td>Block C, Cullinan Place, Cullinan Close, Morningside, 2196</td>
                </tr>
                <tr>
                    <td>+27 (11) 282 2800</td>
                    <td>+27 (11) 282 2800</td>
                </tr>
                <tr>
                    <td><a href="mailto:info@fortressfund.co.za">info@fortressfund.co.za</a></td>
                    <td><a href="mailto:info@fortressfund.co.za">info@fortressfund.co.za</a></td>
                </tr>
            </tbody>
        </table>

        <p>The request for access to records will be deemed to have been made once the form has been received by the
            Company's Information Officer and/or Deputy Information Officer.</p>

        <h4>RECORDS HELD BY THE COMPANY</h4>

        <p>What follows is a description of the categories of records which are held by the Company. However, please
            note that recording a category or subject matter in this manual does not imply that a request for access to
            such records would be honoured. In particular, certain grounds of refusal as set out in PAIA may be
            applicable to a request for such records. All requests for access will be evaluated on a case-by-case basis
            in accordance with the provisions of PAIA.<br/><br/></p>


        <table>
            <tbody>
                <tr>
                    <td>
                        <p>Company Records</p>
                    </td>
                    <td>
                        <ul>
                            <li>Constitutional documents of the Company;</li>
                            <li>Statutory records;</li>
                            <li>Share registers and other statutory registers;</li>
                            <li>Records of minutes of directors’ meetings, committee meetings and sub-committee meetings
                                and minutes of shareholder meetings;</li>
                            <li>Operational records;</li>
                            <li>Company policies and directives;</li>
                            <li>Intellectual property;</li>
                            <li>Marketing records;</li>
                            <li>Legal records;</li>
                            <li>Internal correspondence; and</li>
                            <li>Internal policies and procedures.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Financial</p>
                    </td>
                    <td>
                        <ul>
                            <li>Financial records and reports;</li>
                            <li>Accounting records and reports;</li>
                            <li>Audit records and reports;</li>
                            <li>Debtors’ records;</li>
                            <li>Tax returns and records;</li>
                            <li>Banking records;</li>
                            <li>Financial policies and procedures;</li>
                            <li>Risk management records;</li>
                            <li>Management accounts and records;</li>
                            <li>Purchase and order records; and</li>
                            <li>General correspondence.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Employee Records</p>
                    </td>
                    <td>
                        <ul>
                            <li>Personal records of employees;</li>
                            <li>Employment contracts;</li>
                            <li>Salary and wage register and other payroll records;</li>
                            <li>Employee tax information;</li>
                            <li>Disciplinary records</li>
                            <li>Training schedules;</li>
                            <li> Statutory records; and</li>
                            <li>other internal records and correspondence.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Information Technology</p>
                    </td>
                    <td>
                        <ul>
                            <li>Software;</li>
                            <li>Maintenance and support agreements;</li>
                            <li>IT policies and procedures;</li>
                            <li>Software licence agreements;</li>
                            <li>User and system manuals;</li>
                            <li>Asset register for IT material; and</li>
                            <li>General correspondence.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Other</p>
                    </td>
                    <td>
                        <ul>
                            <li>Contracts and agreements</li>
                            <li>Building and property records</li>
                            <li>Operational records;</li>
                            <li>Corporate communications;</li>
                            <li>Records pertaining to public and corporate affairs;</li>
                            <li>Records relating to the strategies and commercial activities of the Company.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Third party records</p>
                    </td>
                    <td>
                        <ul>
                            <li>Records held in respect of third parties, including but not limited to agreements,
                                financial information and general correspondence.</li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>

        <p>Other information as may be prescribed Section 51(1)(f): N/A</p>

        <h4>GROUNDS FOR REFUSAL</h4>
        <p>There are various grounds upon which the Company may or must refuse a request for access to a record in
            accordance with Chapter 4 of PAIA. They are the protection of:</p>

        <ul>
            <li>personal information of a third party who is a natural person;</li>
            <li>commercial information of a third party or the Company;</li>
            <li>the confidential information of a third party;</li>
            <li>the safety of an individual and protection of property;</li>
            <li>records privileged from production in legal proceedings;</li>
            <li>records containing trade secrets, financial or sensitive information or any information that would put
                the Company at a disadvantage in negotiations or prejudice it in commercial competition;</li>
            <li>records containing information about research being carried out or about to be carried out on behalf of
                a third party.</li>
        </ul>
        <p>Section 70 of PAIA contains an overriding provision. Disclosure of a record that has been requested is
            compulsory if it would reveal a substantial contravention of, or failure to comply with the law, or imminent
            and serious public safety or environmental risk and the public interest in the disclosure of the record
            clearly outweighs the harm contemplated by its disclosure.</p>

        <h4>INFORMATION OR RECORDS NOT FOUND</h4>

        <p>If all reasonable steps have been taken to find a record, and such record cannot be found or if the record
            does not exist, the Company will notify the requestor by way of an affidavit or affirmation, that it is not
            possible to give access to the requested record. <br />

            The affidavit or affirmation will provide a full account of all the steps taken to find the record or to
            determine the existence thereof, including details of all communications by the Company with every person
            who conducted the search. <br />

            If the record in question should later be found, the requester shall be given access to the record in the
            manner stipulated by the requester unless access is refused by the company as permitted by PAIA. </p><br/><br/>

        <p><strong>INFORMATION REQUESTED ABOUT A THIRD PARTY</strong></p>

        <p>If the requester's interest affects a third party then the Company will first need to inform the third party
            within 21 days of receiving the request. The third party has 21 days to make representations and/or
            submissions regarding the granting of access to the record. If the Information Officer does decide to grant
            access to the record, the third party that has been affected has 30 days in which to appeal the decision in
            the High Court or to lodge a complaint with the Information Regulator in accordance with section 74 of POPIA
            in the prescribed form. If no appeal or complaint is lodged within 30 days, the requester must be granted
            access to the Record. </p><br/><br/>

        <p><strong>INFORMATION OFFICER&rsquo;S DECISION AND REQUESTER RECOURSE</strong></p>

        <p>Once the Information Officer has heard all the submissions, he or she will make a decision as to whether or
            not access to the record will be granted. If access is granted the requester must then be granted access to
            the record within 30 days of being informed of the decision.</p>

        <p>If the Information Officer does not grant the requester access to the record the requester is entitled, in
            accordance with sections 56(3) (c) and 78 of PAIA., to apply to a court for relief within 180 days of
            notification of the decision. Such relief may include any order compelling the record/s requested to be made
            available to the requester or for another appropriate order. The court will determine whether the records
            should be made available or not. The requester may also lodge a complaint with the Information Regulator
            against the refusal of the request.</p>

        <p>The requester may also approach the Information Regulator and lodge a complaint in accordance with section 74
            of POPIA in the prescribed form against the access fee to be paid or the form of access granted. The details
            of the Information Regulator are as follows:</p>

        <p>The Information Regulator (South Africa)<br />
            JD House<br />
            27 Stiemens Street<br />
            Braamfonteir<br />
            Johannesburg
            <br />
            2001<br />
            email: <a href="mailto:inforeg@justice.gov.za">inforeg@justice.gov.za</a>
        </p>

        <p>The Company does not have any internal appeal procedures. As such, the decision made by the Information
            Officer is final and requesters will have to exercise the external remedies at their disposal in the event
            that a request for access is refused. The requester is entitled to receive proper reasons as to why the
            request was refused.</p>

        <h4>PROTECTION OF PERSONAL INFORMATION THAT IS PROCESSED BY THE COMPANY</h4>

        <p><strong>Conditions for Lawful Processing</strong><br />
            Chapter 3 of POPIA sets out the conditions for lawful processing of personal information which must be
            complied with when a responsible party processes personal information. Below is a description of the eight
            conditions for lawful processing as contained in POPIA:</p><br /><br />

        <p><strong>a) Accountability</strong> <br />
            POPIA provides that the responsible party is obliged to ensure that the conditions for lawful processing and
            all other measures required in terms of POPIA are complied with.</p><br /><br />

        <p><strong>b) Processing limitation</strong><br />
            The processing must be done lawfully and in a manner that does not infringe the right to privacy of a data
            subject. Personal information may only be processed if, given the purpose for which it is processed, it is
            adequate, relevant and not excessive. There must furthermore be a justification for processing personal
            information. Consent is one of the justifications but personal information may be processed in the absence
            of consent if it is necessary for pursuing the legitimate interests of the responsible party or the third
            party to whom it is disclosed or for the protection of the legitimate interests of the data subject. It may
            also be processed if it complies with an obligation imposed by law or where it is necessary for the
            performance of a contract. The processing of special personal information or personal information of
            children generally requires consent, subject to certain limited exceptions. </p><br /><br />

        <p><strong>c) Purpose specification</strong><br />
            POPIA provides that personal information must be collected for a specific, explicitly defined and lawful
            purpose related to a function or activity of the responsible party. Subject to certain exceptions, records
            of personal information must not be retained for longer than is necessary to achieve the purpose for which
            it was collected or subsequently processed, and must be destroyed or deleted once the responsible party is
            no longer authorised to retain the record. Such exceptions include where (i) the retention is required or
            authorised by law, (ii) the data subject has consented to the retention, or (iii) the personal information
            is being retained for historical, statistical or research purposes.</p><br /><br />

        <p><strong>d) Further Processing Limitation</strong><br />
            POPIA provides that the further processing of personal information must be in accordance with or compatible
            with the purpose for which the personal information was collected.</p><br /><br />

        <p><strong>e) Information quality</strong><br />
            A responsible party must take reasonably practicable steps to ensure that personal information is complete,
            accurate, not misleading and updated where necessary. </p><br /><br />

        <p><strong>f) Openness</strong><br />
            A responsible party is required to maintain the documentation of all processing operations under its
            responsibility as required in terms of PAIA and must take reasonably practicable steps to ensure that the
            data subject is made aware of the personal information being collected, together with other stipulated
            information, subject to certain exceptions. </p><br /><br />

        <p><strong>g) Security safeguards</strong><br />
            POPIA provides that a responsible party must secure the integrity and confidentiality of personal
            information in its possession or under its control by implementing appropriate, reasonable technical and
            organisational measures to prevent the loss of, damage to or unauthorised destruction of personal
            information, or unlawful access to or processing of personal information. In addition, the responsible party
            should take all reasonable measures to identify all reasonably foreseeable internal and external risks,
            establish and maintain appropriate safeguards against risks identified, verify that the safeguards are
            effectively implemented and ensure that the safeguards are updated in response to new risks. </p>
        <br /><br />

        <p><strong>h) Data subject participation</strong><br />
            A data subject is entitled to request a responsible party to confirm whether or not it holds personal
            information about the data subject, and to request the record itself or a description of the record, subject
            to the requirements in PAIA. A data subject may also request a responsible party to correct or delete
            personal information that is inaccurate, irrelevant, excessive, out of date, incomplete, misleading,
            obtained unlawfully, or to destroy or delete personal information that a responsible party is no longer
            authorised to retain.</p><br /><br />

        <p><strong>Purpose of the Processing of Personal Information by the Company</strong> <br />
            The purposes for which the Company processes or will process personal information are set out in Annexure C.
        </p><br /><br />

        <p><strong>Categories of Data Subjects and Personal Information/Special Personal Information relating
                thereto</strong><br />
            As per section 1 of POPIA, a data subject may either be a natural or a juristic person. The categories of
            data subjects in relation to which the Company processes personal information are set out in Annexure D.</p>
        <br /><br />

        <p><strong>Recipients or categories of recipients of Personal Information</strong><br />The following are the
            recipients to whom the Company may provide a data subject's personal information:<br />
        </p>


        <ul>
            <li>suppliers and service providers;</li>
            <li>the Johannesburg Stock Exchange;</li>
            <li>third-party applications or platforms;</li>
            <li>companies within the Fortress group of companies;</li>
            <li>government departments;</li>
            <li>the Competition Commission;</li>
            <li>the BEE Commission;</li>
            <li>the South African Revenue Service;</li>
            <li>Financial institutions;</li>
            <li>auditors;</li>
            <li>industry bodies;</li>
            <li>property managers;</li>
            <li>attorneys, advocates and other legal advisors.</li>
        </ul><br/><br/>
        <p><strong>Cross-border flows of Personal Information</strong><br />

            Section 72 of POPIA provides that personal information may only be transferred by a responsible party to a
            third party in a foreign country outside of the Republic of South Africa in the following
            circumstances:<br /></p>

        <ul>
            <li>If the third party who is the recipient of the personal information is subject to a law, binding
                corporate rules or a binding agreement which provide an adequate level of protection that effectively
                upholds principles similar to the conditions for lawful processing under POPIA, including provisions
                relating to the further transfer of personal information from the recipient to third parties who are in
                a foreign country; or</li>
            <li>If the data subject consents to the transfer of their personal information; or</li>
            <li>If the transfer is necessary for the performance of a contractual obligation between the data subject
                and the responsible party; or</li>
            <li>If the transfer is necessary for the conclusion or performance of a contract between the responsible
                party and a third party, concluded in the interests of the data subject; or</li>
            <li>If the transfer is for the benefit of the data subject, and it is not reasonably practicable to obtain
                the consent of the data subject, and if it were, the data subject, would in all likelihood provide such
                consent.</li>
        </ul><br/><br/>
        <p><strong>Description of information security measures to the implemented by the Company</strong><br/>
            The types of security measures implemented by the Company in order to secure the integrity and
            confidentiality of the personal information and ensure that personal information is protected from loss,
            damage, unauthorized destruction or unlawful access are listed in Annexure E hereto.</p>
            <br /> <br />
        <p><strong>Objection to the Processing of Personal Information by a Data Subject</strong></p>

        <p>Section 11(3) of POPIA and Regulation 2 of the POPIA Regulations provides that a data subject may object to
            processing in the prescribed form attached as Annexure F to this Manual (Please click this <a
                href="https://property.fortressfund.co.za/access-request" target="_blank">link</a> to access the
            Objection form: where the processing is based on one of the following grounds, unless legislation provides
            for such processing:<br /></p>

        <ul>
            <li>Processing protects a legitimate interest of the data subject;</li>
            <li>Processing is necessary for pursuing the legitimate interests of the responsible party or of a third
                party to whom it is supplied;</li>
            <li>Processing is for purposes of direct marketing other than direct marketing by means of unsolicited
                electronic communications as referred to in section 69 of POPIA.</li>
        </ul>
        <br /> <br />
        <p><strong>Request for correction or deletion of Personal Information</strong></p>

        <p>Section 24 of POPIA and Regulation 3 of the POPIA Regulations provides that a data subject may request for
            their Personal Information to be corrected/deleted in the prescribed form attached as Annexure G to this
            Manual. (Please click this <a href="https://property.fortressfund.co.za/access-request"
                target="_blank">link</a> to access the Correction or Deletion form: </p>

        <h4>ANNEXURE "A" LIST OF SUBSIDIARIES</h4>
        <p>Fortress Real Estate Investments  (2009/016487/06)<br />
            Capital Propfund (Pty) Ltd (2014/013211/07)<br />
            Fortress Income 3 (2009/014323/07)<br />
            Fortress Income 10 (2017/450975/07)<br />
            Fortress Income 11 (2017/452572/07)<br />
            Fortress Income 12 (2017/451058/07)<br />
            Fortress Income 13 (2017/452279/07)<br />
            Fortress Income 1 (2007/007982/07)<br />
            Fortress Income 5 (2009/014236/07)<br />
            Fortress Income 7 (2014/182071/07)<br />
            Capital Propfund 1 (Pty) Ltd (2010/022311/07)<br />
            Capital Propfund 2 (Pty) Ltd (2013/230191/07)<br />
            Capital Propfund 3 (Pty) Ltd (2004/005348/07)<br />
            Capital Propfund 4 (Pty) Ltd (2001/016120/07)<br />
            Lodestone Investments (Pty) Ltd (2005/043144/07)<br />
            Lodestone Investments 2 (Pty) Ltd (2008/029004/07)<br />
            Mantraweb Investments (Pty) Ltd (60%) (2010/013142/07)<br />
            Friedshelf 1732 (Pty) Ltd (51%) (2016/226556/07)<br />
            Fortress Income 8 (50.1%) (2015/189862/07)<br />
            Lodestone REIT Limited (2010/017830/06)<br />
            Pangbourne Properties Ltd (1987/002352/06)<br />
            Capital Property Fund Limited (2013/226575/06)<br />
            Fortress Income 6 (2014/206080/07)<br />
            Fortress Income 4 (2008/023040/07)<br />
            Intaba Investments 6 (Pty) Ltd (2009/002860/07)<br />
            Capital Propfund 7 (Pty) Ltd (formerly known as Property Fund Managers Ltd) (1980/009531/07)<br />
            Bands Properties (Pty) Ltd (1970/000593/07)<br />
            Monyetla Property Holdings (Pty) Ltd (2004/031911/07)<br />
            Panhold (Pty) Ltd (2005/043640/07)<br />
            iFour Properties SA (Pty) Ltd (2001/016193/07)<br />
            iFour Properties Three (Pty) Ltd (2003/017839/07)<br />
            Sipan 1 (Pty) Ltd (2004/002095/07)<br />
            Siyathenga Properties Two (Pty) Ltd (2004/005351/07)<br />
            Siyathenga Properties Three (Pty) Ltd (2004/004911/07)<br />
            Realty Dynamix 73 (Pty) Ltd (2006/015923/07)<br />
            Caprohold (Pty) Ltd (1996/016133/07)<br />iFour Properties Ltd (2001/016118/06)<br />
            Tugela Mouth Sugar Estates (1980/010991/07)<br />
            Friedshelf 1820 (Pty) Ltd (2018/061364/07)<br />
            Lodestone Investments 3 (Pty) Ltd (2009/023823/07)<br />
            Fortress Income 9 (Pty) Ltd (2016/086742/07)</p>

        <h4>ANNEXURE "B" FORM C (REQUEST FOR ACCESS TO RECORD OF PRIVATE BODY)</h4>
        <p><img src="../../../../assets/documents/aPicture1.webp" alt="" /></p>

        <h4>EXPLANATORY NOTE ON FEES</h4>
        <p>An explanatory note on fees to be charged by a private body when granting a Request for Access to information
            in terms of the Promotion of Access to Information Act, 2000.<br />The fees chargeable by private bodies are
            contained in part III of Annexure "A" of the Regulations. A copy of part III is attached for your
            convenience. The present charges are as follows:</p>
            <br /><br />
        <p><strong>1. Copies of a manual</strong><br />
            Should an individual require a copy of the business/organizations manual, a fee of R1,10 is chargeable for
            every photocopy of an A4 page or part thereof.</p>
            <br /><br />
        <p><strong>2. Reproduction fees</strong><br />
            Reproduction fees apply to obtaining copies or transactions of information, which is automatically available
            from the business/organization. The fees are listed in paragraph 2 of part III of Annexure "A" to the
            Regulations.</p>
            <br /><br />
        <p><strong>3. Access fees</strong><br />
            Access fees are chargeable for copies of transcriptions of information requested under this Act. The fees
            are listed in paragraph 4 of part III of Annexure "A" to the Regulations.</p>
            <br /><br />
        <p><strong>4. Other fees</strong><br />
            4.1 A request fee of R50,00 is payable by a Requester who is seeking access to a Record containing
            information which is not personal to the Requester. See paragraph 6 of part 1 of this work.<br />
            4.2 A search fee may be charged at a rate of R30,00 per hour or part thereof for the searching and preparing
            the Record for disclosure provided such time was reasonably required for that purpose.<br />
            4.3 If the request is not limited to Records containing information which is personal to the Requester and
            if the Information Officer is of the opinion that the time taken to give effect to the request will exceed
            six hours the Requester can be called upon to pay a deposit of not more than one third of an estimate of the
            access fee which will become payable.<br />
            4.4 If a copy of a Record is posted to a Requester, the Requester is obliged to pay the actual postage
            payable.</p>

        <h4>PART III: FEES IN RESPECT OF A PRIVATE BODY</h4>
        <p> 1. The fee for a copy of the manual as contemplated in regulations 9 (2) (c)is R1,10 for every photocopy of
            an A4-size page or part thereof.<br />
            2. The fees for reproduction referred to in regulation 11(a) are as follows:<br />
            2.1 for every photocopy of an A4-size or part thereof R 1,10;<br />
            2.2 for every printed copy of an A4-size or part thereof held on a computer or in electronic R 0,75 or
            machine-readable form;<br />
            2.3 for a copy in a computer-readable form on:<br />
            (a) stiffy disc - R 7,50;<br />
            (b) compact disc - R 70,00 or other appropriate device;<br />
            2.4 for a transcription of visual images, for an A4-size page or part thereof R 40,00, for a copy of the
            visual images R 60,00;<br />
            2.5 for a transcription of an audio Record, for an A4-size page or part thereof R 20,00, for a copy of an
            audio Record R 30,00.<br />
            3. The request fee payable by a Requester, other than a personal Requester, referred to regulation 11(2) is
            R 50,00.<br />
            4. The access fees payable by a Requester referred to in regulation 11(3) are as follows:<br />
            4.1 for every photocopy of an A4-size or part thereof R 1,10;<br />
            4.2 for every printed copy of an A4-size or part thereof held on a computer or in electronic or
            machine-readable form R 0,75;<br />
            4.3 for a copy in a computer-readable form on:<br />
            (a) stiff disc R 7,50;<br />
            (b) compact disc R 70,00<br />
            4.4 for a transcription of visual images, for an A4-size page or part thereof R 40,00, for a copy of an
            audio Record R 30,00;<br />
            4.5 to search for and prepare the Record for disclosure, R 30,00 for each hour or part of an hour reasonably
            required for such search and preparation;<br />
            4.6 for purposes of section 54(2) of the Act, the following applies; and<br />
            (a) six hours as the hours to be exceeded before a deposit is payable; and<br />
            (b) one third of the access fee is payable as a deposit by the Requester;<br />
            4.7 the actual postage is payable when a copy of a Record must be posted to a Requester.</p>
        <p>


        <h4>ANNEXURE "C" DESCRIPTION OF RECORDS OF PERSONAL INFORMATION HELD AND PURPOSES OF PROCESSING SUCH PERSONAL
            INFORMATION</h4>
        <p>The company will only use and Process Personal Information for lawful purposes which may include (but shall
            not be limited to) the following:</p>

        <table>
            <thead>
                <tr>
                    <th>Description of Record of Personal Information</th>
                    <th>Purpose of Processing</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>Race, gender, pregnancy, marital status, nationality, age, physical or mental health,
                            well-being, disability, language</p>
                    </td>
                    <td>
                        <p>Processed for the purposes of:</p>
                        <ul>
                            <li>complying with legislative requirements (for example broad-based black economic
                                empowerment and employment equity);</li>
                            <li>complying with the JSE listing requirements; and</li>
                            <li>for other reporting purposes.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Data disclosing health</p>
                    </td>
                    <td>
                        <p>Processed for the purposes of reporting to relevant government departments for legislative
                            purposes</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Information relating to the education or the medical, financial, criminal or employment
                            history of the person</p>
                    </td>
                    <td>
                        <p>Processed for the purposes of:</p>
                        <ul>
                            <li>entering into and performing in relation to the employment contract with employees;</li>
                            <li>fraud prevention and detection;</li>
                            <li>credit vetting; and</li>
                            <li>complying with legislative requirements.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>The identifying number, symbol, email, physical address, telephone number, location
                            information, online identifier or other particular assignment of the person</p>
                    </td>
                    <td>
                        <p>Processed in the normal course of business, for purposes including but not limited to:</p>
                        <ul>
                            <li>the conclusion or performance of the employment contract and associated funcitons, such
                                as payroll;</li>
                            <li>concluding letting agreements;</li>
                            <li>concluding sales and acquisitions;</li>
                            <li>maintaining the Company’s share register;</li>
                            <li>concluding agreements with service providers and suppliers;</li>
                            <li>credit vetting;</li>
                            <li>payment of invoices;</li>
                            <li>complying with regulatory and other obligations; and</li>
                            <li>access control in respect of the Company’s property to prevent and detect crime.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Correspondence including emails sent by the person that is implicitly or explicitly of a
                            private or confidential nature or further correspondence that would reveal the contents of
                            the original correspondence</p>
                    </td>
                    <td>
                        <p>Processed for the purposes of conducting daily business transactions, for purposes including
                            but not limited to:</p>
                        <ul>
                            <li>the conclusion or performance of the employment contract and associated funcitons, such
                                as payroll;</li>
                            <li>concluding letting agreements;</li>
                            <li>concluding sales and acquisitions;</li>
                            <li>maintaining the Company’s share register;</li>
                            <li>concluding agreements with service providers and suppliers;</li>
                            <li>credit vetting;</li>
                            <li>payment of invoices;</li>
                            <li>complying with regulatory and other obligations; and</li>
                            <li>access control in respect of the Company’s property to prevent and detect crime.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Name, address, ID number, date of birth, contact details of children</p>
                    </td>
                    <td>
                        <p>Processed for the purpose of sponsorship of school fees for learners as part of the Company’s
                            corporate social investment.</p>
                    </td>
                </tr>
            </tbody>
        </table>

        <h4>ANNEXURE "D": DESCRIPTION OF RECORDS OF PERSONAL INFORMATION HELD AND CATEGORIES OF DATA SUBJECTS TO WHOM IT
            RELATES</h4>
        <table>
            <thead>
                <tr>
                    <th>
                       Description of Record of Personal Information
                    </th>
                    <th>
                        Data Subject or Category of Data Subject to whom Record relates
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>Race, gender, pregnancy, marital status, nationality, age, physical or mental health,
                            well-being, disability, language</p>
                    </td>
                    <td>
                        <p>Employees<br>
                            Directors<br>
                            Beneficiaries of corporate social investment<br>
                            Shareholders<br></p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Information relating to the education or the medical, financial, criminal or employment
                            history of the person</p>
                    </td>
                    <td>
                        <p>Sponsored learners<br>
                            Employees<br>
                            Directors<br></p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>The identifying number, symbol, email, physical address, tel number, location information,
                            online identifier or other particular assignment of the person</p>
                    </td>
                    <td>
                        <p>Employees<br>
                            Directors<br>
                            Shareholders<br>
                            Tenants<br>
                            Purchasers of property <br>
                            Suppliers / vendors<br>
                            Contractors, consultants, service providers<br>
                            Sureties<br>
                            Signatories<br>
                            Property brokers and managers<br></p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Correspondence including emails sent by the person that is implicitly or explicitly of a
                            private or confidential nature or further correspondence that would reveal the contents of
                            the original correspondence</p>
                    </td>
                    <td>
                        <p>Employees<br>
                            Directors<br>
                            Shareholders<br>
                            Tenants<br>
                            Purchasers of property <br>
                            Suppliers / vendors<br>
                            Contractors, consultants, service providers<br>
                            Sureties<br>
                            Signatories<br>
                            Property brokers and managers<br></p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Name, address, ID number, date of birth, contact details</p>
                    </td>
                    <td>
                        <p>Sponsored learners<br>
                            Employees<br>
                            Directors<br>
                            Shareholders<br></p>
                    </td>
                </tr>
            </tbody>
        </table>

        <h4>ANNEXURE "E": SECURITY MEASURES TO BE IMPLEMENTED BY THE COMPANY</h4>
        <p>The following is a list of the types of security measures implemented by the Company in order to ensure that Personal Information is protected from loss of, damage to or unauthorized destruction of or unlawful access to Personal Information:</p>
        <ul>
            <li>The Company implements SANS Critical Security Controls (CIS);</li>
            <li>Monthly reports are generated from service providers. Breaches are immediately notified to the Company.</li>
            <li>The fire-proof safe contains hard copies of relevant documents ;</li>
            <li>Access to the office is controlled and there is restriction of access to hard copies of documents containing Personal Information;</li>
            <li>Physical access to IT equipment is regulated by identity codes and keywords;</li>
            <li>Where the Processing concerns Special Personal Information, the keyword will be modified at least every three months;</li>
            <li>Access credentials are deactivated if the person in charge of the Processing is disqualified from accessing the Personal Information;</li>
            <li>Anti-virus systems are used and updated at least every six months;</li>
            <li>Anti-intrusion systems (i.e. firewalls) are used and updated at least annually (every six months in the case of Special Personal Information);</li>
        </ul>

        <h4>ANNEXURE "F": FORM FOR THE OBJECTION TO THE PROCESSING OF PERSONAL INFORMATION IN TERMS OF POPIA</h4>
        
        <div class="plainRow">
            <img src="../../../../assets/documents/Picture1.webp"/>
        </div>

        <h4>ANNEXURE "G": FORM FOR THE REQUEST TO DELETE OR CORRECT PERSONAL INFORMATION IN TERMS OF POPIA</h4>
        <div class="plainRow">
            <img src="../../../../assets/documents/Picture2.webp"/>
        </div>
        

        <div class="plainRow">
            <img src="../../../../assets/documents/Picture3.webp"/>
        </div>
        

    </div>

    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>