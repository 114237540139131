import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {

  options: any;
  constructor() { }

  ngOnInit(): void {
    const xAxisData = ['SA Direct Offices and Land','SA Direct Industrial',"NEPI Rockcastle","SA Direct Logistics Development", "SA Direct Logistics", "SA Direct Retail","CEE Logistics","TNAV", "LTV"];
    const data1 = [2.8, 2.9, 14.6, 2.6, 10, 10.1, 0.8, 27.1, 16.7];

    

    this.options = {
      legend: {
        data: ['bar'],
        align: 'left',
      },
      tooltip: {},
      xAxis: {
        data: xAxisData,
        silent: false,
        splitLine: {
          show: false,
        },
      },
      yAxis: {},
      series: [
        {
          name: 'bar',
          type: 'bar',
          data: data1,
          animationDelay: (idx:any) => idx * 10,
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx:any) => idx * 5,
  }
}
 

}
