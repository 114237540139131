import { Component, OnInit, Input } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FortressPropertyService } from '../../../../services/fortress-property.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
export interface props {
  askingPrice: any;
  buildingCode: string;
  city: string;
  country: string;
  description: any;
  erfNo: any;
  latitude: number;
  latitudeString: string;
  legalEntity: any;
  lessorRegNo: any;
  location: any;
  longitude: number;
  longitudeString: string;
  marketProperty: boolean;
  modified: boolean;
  postalCode: any;
  priceOnRequest: boolean;
  propertyCode: string;
  id: number;
  buildingName: string;
  province: string;
  saleStatus: any;
  saleStatusInt: any;
  saleStatusString: any;
  salesMarketingDescription: any;
  sector: string;
  sectorInt: number;
  status: number;
  statusInt: number;
  streetAddress: string;
  streetNumber: any;
  subCategory: number;
  subCategoryInt: number;
  suburb: string;
  buildingGla: number;
  updatePropertyName: boolean;
  vatNo: any;
  videoUrl: string;
  virtualTourUrl: string;
  webUrl: string;
  youTubeUrl: any;
  _originalPropertyName: string;
  frontPageImage: string;
  imageBucketUrl: string;
  compare: boolean;
  buildingFeatures: any;
  buildingContactableList: any;
  units: any;
  buildingId: any;
  unitCount: any;
}

@Component({
  selector: 'app-search-filter-all-properties',
  templateUrl: './search-filter-all-properties.component.html',
  styleUrls: ['./search-filter-all-properties.component.scss'],
})
export class SearchFilterAllPropertiesComponent implements OnInit {
  @Input() filter: boolean = false;
  @Input() vacancies: boolean = false;
  allProp: props[] = [];
  holdAll: props[] = [];
  tempProp: props[] = [];
  holdProp1: Array<number> = [];
  original: props[] = [];
  showCategory: boolean = false;
  props: Array<string> = [];
  showArea: boolean = false;
  showProvince: boolean = false;
  showGla: boolean = true;
  showFeature: boolean = false;
  featureGreen: boolean = false;

  featureGenerator: boolean = false;
  featureInternet: boolean = false;
  searchText: string = '';
  catSearch: string = '';
  countrySearch: string = '';
  provinceSearch: string = '';
  compareNow: boolean = false;
  value: number = 0;
  highValue: number = 500000;
  options: Options = {
    floor: 0,
    ceil: 500000,
  };
  type: string = '';

  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 20, 30];

  sa: boolean = false;
  eu: boolean = false;
  romania: boolean = false;
  ga: boolean = false;
  ec: boolean = false;
  lim: boolean = false;
  wc: boolean = false;
  mp: boolean = false;
  nc: boolean = false;
  kzn: boolean = false;
  fs: boolean = false;
  nw: boolean = false;
  log: boolean = false;
  CEELogisitics: boolean = false;
  off: boolean = false;
  ret: boolean = false;
  ind: boolean = false;
  all: boolean = false;
  combined: boolean = false;

  holdNum: number = 0;
  foundUnit: boolean = false;

  countrySearchList: string[] = [];

  doneSearch: boolean = false;

  compareArr: props[] = [];

  constructor(
    private property: FortressPropertyService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    var getPage = localStorage.getItem('page');
    if (getPage) {
      this.page = +getPage;
      localStorage.removeItem('page');
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.queryParams.subscribe((params) => {
      this.type = params.type;
      if (this.type && this.filter === false) {
        const str2 = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        this.search(str2);
      }
    });

    this.spinner.show();
    this.property.getProperties().subscribe(
      (result) => {
        this.holdAll = result;
        // console.log(result);
        if (this.filter === false) {
          this.allProp = result;
          this.original = result;
          this.spinner.hide();
        } else {
          var counter = 0;
          if (this.type) {
            const strTemp =
              this.type.charAt(0).toUpperCase() + this.type.slice(1);
            this.search(strTemp);
            for (var i = 0; i < result.length; i++) {
              if (
                result[i].unitCount > 0 &&
                String(result[i].sector) === strTemp
              ) {
                this.allProp[counter] = result[i];
                this.original[counter] = result[i];
                counter++;
              }
            }
          } else {
            for (var i = 0; i < result.length; i++) {
              if (result[i].unitCount > 0) {
                this.allProp[counter] = result[i];
                this.original[counter] = result[i];
                counter++;
              }
            }
          }
          console.log(this.allProp);

          this.spinner.hide();
        }
      },
      (err) => {
        //  console.log(err);
      }
    );
  }

  handlePageChange(event: number): void {
    this.page = event;
    const elementList = document.querySelectorAll('.filter-top');
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  getPropFeature(bucket: string, img: string) {
    if (img === null || bucket === null) {
      return 'url(../../../../../assets/images/no-prop-image.webp)';
    } else {
      img = img.replace(/\s/g, '%20');
      return 'url(' + bucket + '/' + img + ')';
    }
  }

  getContact(contacts: Array<any>) {
    if (contacts[0].cell != null) {
      var hold = contacts[0].cell.replace(/\s/g, '');
      return 'tel:' + hold;
    } else {
      return '';
    }
  }

  getFeatures(features: any) {
    if (features === null) {
      return null;
    } else {
      var hold: Array<string> = [];
      if (features.internet === true) {
        hold.push('internet');
      }

      if (features.generators === true) {
        hold.push('generator');
      }

      if (features.greenBuilding === true) {
        hold.push('green');
      }
      return hold;
    }
  }

  toggleFilter(type: string) {
    switch (type) {
      case 'category':
        this.showCategory = !this.showCategory;
        break;
      case 'area':
        this.showArea = !this.showArea;
        break;
      case 'province':
        this.showProvince = !this.showProvince;
        break;
      case 'gla':
        this.showGla = !this.showGla;
        break;
      case 'feature':
        this.showFeature = !this.showFeature;
        break;
    }
  }

  clearSearch() {
    this.searchText = '';
    this.catSearch = '';
    this.countrySearch = '';
    this.provinceSearch = '';
    this.log = false;
    this.CEELogisitics = false;
    this.ind = false;
    this.off = false;
    this.ret = false;
    this.sa = false;
    this.eu = false;
    this.romania = false;
    this.ga = false;
    this.ec = false;
    this.lim = false;
    this.wc = false;
    this.mp = false;
    this.nc = false;
    this.kzn = false;
    this.fs = false;
    this.nw = false;
    this.featureGreen = false;
    this.featureInternet = false;
    this.featureGenerator = false;
  }

  search(word: string) {
    switch (word) {
      case 'CEELogisitics':
        this.all = false;
        this.CEELogisitics = true;
        this.log = false;
        this.ind = false;
        this.off = false;
        this.ret = false;
        this.searchCat('CEELogistics');
        break;

      case 'Logistics':
        this.all = false;
        this.CEELogisitics = false;
        this.log = true;
        this.ind = false;
        this.off = false;
        this.ret = false;
        this.searchCat('Logistics');
        break;
      case 'Office':
        this.all = false;
        this.log = false;
        this.CEELogisitics = false;
        this.ind = false;
        this.off = true;
        this.ret = false;
        this.searchCat('Office');
        break;
      case 'Retail':
        this.all = false;
        this.CEELogisitics = false;
        this.log = false;
        this.ind = false;
        this.off = false;
        this.ret = true;
        this.searchCat('Retail');
        break;
      case 'Industrial':
        this.log = false;
        this.CEELogisitics = false;
        this.ind = true;
        this.off = false;
        this.ret = false;
        this.all = false;
        this.searchCat('Industrial');
        break;
      case 'Other':
        this.log = false;
        this.CEELogisitics = false;
        this.ind = false;
        this.off = false;
        this.ret = false;
        this.all = false;
        this.searchCat('Other');
        break;
      case 'sa':
        this.sa = true;
        this.eu = false;
        this.romania = false;
        this.searchCountry('South Africa');
        break;
      case 'eu':
        this.searchCountry('Poland');
        this.sa = false;
        this.eu = true;
        this.romania = false;
        break;
      case 'romania':
        this.searchCountry('Romania');
        this.sa = false;
        this.eu = false;
        this.romania = true;
        break;
      case 'ga':
        this.searchProvince('Gauteng');
        this.ga = true;
        this.ec = false;
        this.lim = false;
        this.wc = false;
        this.mp = false;
        this.nc = false;
        this.kzn = false;
        this.fs = false;
        this.nw = false;
        break;
      case 'ec':
        this.searchProvince('Eastern Cape');
        this.ga = false;
        this.ec = true;
        this.lim = false;
        this.wc = false;
        this.mp = false;
        this.nc = false;
        this.kzn = false;
        this.fs = false;
        this.nw = false;
        break;
      case 'lim':
        this.searchProvince('Limpopo');
        this.ga = false;
        this.ec = false;
        this.lim = true;
        this.wc = false;
        this.mp = false;
        this.nc = false;
        this.kzn = false;
        this.fs = false;
        this.nw = false;
        break;
      case 'wc':
        this.searchProvince('Western Cape');
        this.ga = false;
        this.ec = false;
        this.lim = false;
        this.wc = true;
        this.mp = false;
        this.nc = false;
        this.kzn = false;
        this.fs = false;
        this.nw = false;
        break;
      case 'mp':
        this.searchProvince('Mpumalanga');
        this.ga = false;
        this.ec = false;
        this.lim = false;
        this.wc = false;
        this.mp = true;
        this.nc = false;
        this.kzn = false;
        this.fs = false;
        this.nw = false;
        break;
      case 'nc':
        this.searchProvince('Northern Cape');
        this.ga = false;
        this.ec = false;
        this.lim = false;
        this.wc = false;
        this.mp = false;
        this.nc = true;
        this.kzn = false;
        this.fs = false;
        this.nw = false;
        break;
      case 'kzn':
        this.searchProvince('Kwazulu-Natal');
        this.ga = false;
        this.ec = false;
        this.lim = false;
        this.wc = false;
        this.mp = false;
        this.nc = false;
        this.kzn = true;
        this.fs = false;
        this.nw = false;
        break;
      case 'fs':
        this.searchProvince('Freestate');
        this.ga = false;
        this.ec = false;
        this.lim = false;
        this.wc = false;
        this.mp = false;
        this.nc = false;
        this.kzn = false;
        this.fs = true;
        this.nw = false;
        break;
      case 'nw':
        this.searchProvince('North West');
        this.ga = false;
        this.ec = false;
        this.lim = false;
        this.wc = false;
        this.mp = false;
        this.nc = false;
        this.kzn = false;
        this.fs = false;
        this.nw = true;
        break;
    }
  }

  searchCat(word: string) {
    if (this.filter === false) {
      if (this.catSearch === word) {
        this.catSearch = '';
      } else {
        this.catSearch = word;
      }
    } else {
      if (this.catSearch === word) {
        this.catSearch = '';
      } else {
        this.catSearch = word;
      }
    }
    this.filterVacancy();
  }

  filterVacancy() {
    this.allProp = [...this.holdAll];
    
    //search countries
    if (this.countrySearchList.length > 0) {
      this.allProp = this.allProp.filter(
        (property) => this.countrySearchList.includes(property.country)
      );
    }

    if (this.catSearch != '') {
      this.allProp = this.allProp.filter(
        (property) => {return property.sector === this.catSearch}
      );
    }

    this.allProp = this.allProp.filter((o) => {
      return o.buildingGla <= this.highValue && o.buildingGla >= this.value;
    });
  }

  searchProvince(word: string) {
    if (this.filter === false) {
      if (this.provinceSearch === word) {
        this.provinceSearch = '';
      } else {
        this.provinceSearch = word;
      }
    } else {
      if (this.provinceSearch === word) {
        this.provinceSearch = '';
      } else {
        this.provinceSearch = word;
      }
    }
    this.filterVacancy();
  }

  searchCountry(word: string) {
    if (this.filter === false) {
      if (this.countrySearch === word) {
        this.countrySearch = '';
      } else {
        this.countrySearch = word;
      }
    } else {
      if (this.countrySearch === word) {
        this.countrySearch = '';
      } else {
        this.countrySearch = word;
      }
      this.filterVacancy();
    }
  }

  searchFilter(word: string) {
    if (this.searchText === word) {
      this.searchText = '';
    } else {
      this.searchText = word;
    }
  }

  selectFeature(type: string) {
    switch (type) {
      case 'green':
        this.featureGreen = !this.featureGreen;
        this.featureInternet = false;
        this.featureGenerator = false;
        this.searchText = 'green';
        break;
      case 'internet':
        this.featureInternet = !this.featureInternet;
        this.featureGreen = false;
        this.featureGenerator = false;
        this.searchText = 'internet';
        break;
      case 'generators':
        this.featureGenerator = !this.featureGenerator;
        this.featureInternet = false;
        this.featureGreen = false;
        this.searchText = 'generators';
        break;
    }
  }

  compareToggle() {
    this.compareNow = !this.compareNow;
    this.compareArr = [];
  }

  viewProp(id: number) {
    // console.log(id);
    localStorage.setItem('page', this.page.toString());
  }

  getImage(type: String) {
    switch (type) {
      case 'green':
        return '../../../../../assets/icons/property-features/small-green.svg';
      case 'internet':
        return '../../../../../assets/icons/property-features/small-internet.svg';
      case 'generator':
        return '../../../../../assets/icons/property-features/small-generator.svg';
      default:
        return '';
    }
  }

  compareCheck(propId: number) {
    if (this.compareArr.length >= 3) {
      return;
    }
    const filterProp = this.allProp.find((property) => property.id === propId);
    if (!filterProp) {
      return;
    }
    const filterPropInCompareArr = this.compareArr.find(
      (property) => property.id === propId
    );
    if (filterPropInCompareArr) {
      return;
    }
    this.compareArr.push(filterProp);
  }
  removeCheckItem(propId: number) {
    const indexToRemove = this.compareArr.findIndex(
      (property) => property.id === propId
    );
    if (indexToRemove !== -1) {
      this.compareArr.splice(indexToRemove, 1);
    }
  }

  sliderEvent() {
    this.filterVacancy()
    // console.log(this.allProp);
  }

  countrySearchHandler = (country: string): void => {
    const index = this.countrySearchList.indexOf(country);
    if (index === -1) {
      this.countrySearchList.push(country); // Add the string if it's not already in the array
    } else {
      this.countrySearchList.splice(index, 1); // Remove the string if it's already in the array
    }
    console.log(this.countrySearchList);

    this.filterVacancy();
  };
}
