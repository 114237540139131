import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-about-page-banner',
  templateUrl: './about-page-banner.component.html',
  styleUrls: ['./about-page-banner.component.scss']
})
export class AboutPageBannerComponent implements OnInit {
  @Input() title: string = "";
  @Input() link: string = "";
  @Input() linkText: string = "";
  @Input() text: string = "";
  @Input() image: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
