import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';  
import { Router } from '@angular/router';  

@Component({
  selector: 'app-login-draft',
  templateUrl: './login-draft.component.html',
  styleUrls: ['./login-draft.component.scss']
})
export class LoginDraftComponent implements OnInit {
  error:boolean=false;
  password: string='';  
  title = 'auth-guard-demo';  
  constructor(private _auth: AuthenticationService, private _router: Router) {  
    if (this._auth.loggedIn) {  
      this._router.navigate(['financials/drafts']);  
    }  
  }  
  login(): void {  
    if (this.password != '') {  
      if (this._auth.loginFinancials(this.password)) {  
        this._router.navigate(["financials/drafts"]);  
        this.error = false;
      }  
      else  
      this.error = true;
    }  
  }  

  ngOnInit(): void {
  }
}
