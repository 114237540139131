<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="" childTitle="FOURSights"></app-breadcrumbs>
   
    <div class="see-also-container">
        <h3>FOURSIGHT</h3>
        <div class="content">
          <div class="block">
            <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
            <div class="inner">
              <h4>Edition 7 - July 2024</h4>
              <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 7 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>
          </div>
          <div class="block">
            <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
            <div class="inner">
              <h4>Edition 6 - October 2023</h4>
              <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 6 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>
          </div>
          <div class="block">
            <img class="mainBG" src="../../../assets/images/foursight/nodate1.webp" alt=""/>
            <div class="inner">
              <h4>The business case for long-term sustainability event</h4>
              <a class="clearLink" (click)="openModal('custom-modal-1')"  target="_blank">View Here<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>
          </div>
          <div class="block">
            <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
            <div class="inner">
              <h4>Edition 5 - November 2022</h4>
              <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 5 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>
          </div>
          <div class="block">
            <img class="mainBG" src="../../../assets/images/foursight/warehouse.webp" alt=""/>
            <div class="inner">
              <h4>Live 28 September 8.30am</h4>
              <a class="clearLink" href="https://www.youtube.com/watch?v=ZhYOIf7-RuM" target="_blank">View Here<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>
          </div>
          <div class="block">
              <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
              <div class="inner">
                <h4>Edition 4 - May 2022</h4>
                <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 4 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
              </div>
            </div>
            <div class="block">
              <img class="mainBG" src="../../../assets/images/foursight/Retail_Reinventedv1.webp" alt=""/>
              <div class="inner">
                <h4>THE POWER OF RETAIL AND ITS POTENTIAL TO UPLIFT AND EMPOWER COMMUNITIES</h4>
                <a class="clearLink" [routerLink]="['/foursight/press-release/article']" [queryParams]="{ id: 'the-power-of-retail-and-its-potential-to-uplift-and-empower-communities' }">View Webinar<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
              </div>
            </div>
            <div class="block">
                <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
                <div class="inner">
                  <h4>Edition 3 - September / October 2021 </h4>
                  <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 3 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <img class="mainBG" src="../../../assets/images/foursight/jul-2021tpf.webp" alt=""/>
                <div class="inner">
                  <h4>Live 29 July 2021 8:30am</h4>
                  <a class="clearLink" [routerLink]="['/foursight/press-release/article']" [queryParams]="{ id: 'the-property-frontier-revolution-webinar' }">View Webinar<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
                <div class="inner">
                  <h4>Edition 2 - April / May 2021</h4>
                  <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 2 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <img class="mainBG" src="../../../assets/images/foursight/october-edition.webp" alt=""/>
                <div class="inner">
                  <h4>Edition 1 - November / December 2020</h4>
                  <a class="clearLink" [routerLink]="['/foursight/edition']" [queryParams]="{ id: 1 }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <img class="mainBG" src="../../../assets/images/foursight/webinarNewSize.webp" alt=""/>
                <div class="inner">
                  <h4>Live 4 February 2021 8:30am</h4>
                  <a class="clearLink" [routerLink]="['/foursight/press-release/article']" [queryParams]="{ id: 'the-retail-evolution-webinar' }">View Webinar<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
        </div>
    </div>

    <app-subscribe-banner></app-subscribe-banner>

    <app-explore-our-properties></app-explore-our-properties>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div>

<jw-modal id="custom-modal-1">
  <div class="modalInner">
      <img src="../../../assets/icons/icon-close-black.svg" alt="" (click)="closeModal('custom-modal-1');"/>
      <iframe loading="lazy" width="1280" height="720" src="https://www.youtube.com/embed/NukZ243EvZY" title="The Power Priority: Full  event" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
 
  
</jw-modal>