<div class="audit">
  <div class="col-2 mobile-control" [ngClass]="{ expanded: showMore }">
    <div class="col-row col-row-bg">
      <h1>
        Audit <br />
        committee
      </h1>
      <p>
        The primary role of the audit committee is to ensure the integrity of
        financial reporting and the audit process. In pursuing these objectives,
        the audit committee oversees relations with the external auditors. The
        committee also assists the board in discharging its duties relating to
        the safeguarding of assets, the operation of adequate systems and
        internal control processes, overseeing the preparation of accurate
        financial reports and statements in compliance with all applicable legal
        requirements and accounting standards, ensuring compliance with good
        governance practices and nomination of external auditors. The role of
        the audit committee has been codified in the audit committee charter
        which has been approved by the board. This charter has been aligned with
        the requirements of King IV and the Companies Act.<br /><br />The audit
        committee presently comprises: Susan Ludolph (Chairperson), Jan
        Potgieter, Eddy Oblowitz and Jon Hillary, all of whom are independent
        non-executive directors. The CEO and CFO attend meetings as invitees.
        The committee members have unlimited access to all information,
        documents and explanations required in the discharge of their duties, as
        do the external auditors.
      </p>
    </div>
    <div class="col-row col-row-bg">
      <p>
        The board, in consultation with the audit committee chairperson, makes
        appointments to the committee to fill vacancies. Members of the audit
        committee are subject to re-election by shareholders at its annual
        general meeting. The board has determined that the committee members
        have the skills and experience necessary to contribute meaningfully to
        the committee’s deliberations. In addition, the chairperson has the
        requisite experience in accounting and financial management.<br /><br />In
        fulfilling its responsibility of monitoring the integrity of financial
        reports to shareholders, the audit committee has reviewed accounting
        principles, policies and practices adopted in the preparation of
        financial information and has examined documentation relating to the
        annual integrated report and interim financial report. The clarity of
        disclosures included in the financial statements was reviewed by the
        audit committee, as was the basis for significant estimates and
        judgements.<br /><br />It is the function of the committee to review and
        make recommendations to the board regarding interim financial results
        and the integrated report prior to approval by the board.<br /><br />The
        audit committee considered and satisfied itself of the overall
        appropriateness of the finance function’s resources, experience and
        expertise as well as the experience and expertise of the financial
        director, who is responsible for the finance function. The committee has
        ensured that the appropriate financial reporting procedures have been
        established and are operating.
      </p>
    </div>
  </div>
  <div (click)="readMoreHandler()" class="read-more">
    <a>{{ showMore ? 'Show less' : 'Read more' }}</a>
    <svg
      [ngClass]="{ rotate: showMore }"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16 10L12 14L8 10"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</div>
<div class="row">
  <h1>External audit</h1>
  <div class="col-2-top-align">
    <div class="col-row copy-control">
      <div>
        <p>
          A key factor that may impair auditors’ independence is a lack of
          control over non-audit services provided by the external auditors. In
          essence, the external auditors’ independence is deemed to be impaired
          if the auditors provide a service which:
        </p>
        <ul class="opacity">
          <li>results in auditing of own work by the auditors;</li>
          <li>
            results in the auditors acting as a manager or employee of the
            group;
          </li>
          <li>puts the auditors in the role of advocate for the group; or</li>
          <li>
            creates a mutuality of interest between the auditors and the group.
          </li>
        </ul>
      </div>
      <div>
        <p>
          The company addresses this issue through three primary measures,
          namely:
        </p>
        <ul class="opacity">
          <li>disclosure of the extent and nature of non-audit services;</li>
          <li>the prohibition of selected services; and</li>
          <li>prior approval by the audit committee of non-audit services.</li>
        </ul>
      </div>
      <div>
        <p>Other safeguards encapsulated in the policy include:</p>
        <ul class="opacity">
          <li>results in auditing of own work by the auditors;</li>
          <li>
            results in the auditors acting as a manager or employee of the
            group;
          </li>
          <li>puts the auditors in the role of advocate for the group; or</li>
          <li>
            the external auditors are required to assess periodically, in their
            professional judgement, whether they are independent of the group;
          </li>
          <li>
            the audit committee ensures that the scope of the auditors' work is
            sufficient and that the auditors are fairly remunerated; and
          </li>
          <li>
            the audit committee has primary responsibility for making
            recommendations to the board on the appointment, re-appointment and
            removal of the external auditors.
          </li>
        </ul>
      </div>
    </div>
    <div class="col-row">
      <p class="opacity">
        The audit committee reviews audit plans for external audits and the
        outcome of the work performed in executing these plans. They further
        ensure that items identified for action are followed up. The external
        auditors report annually to the audit committee to confirm that they are
        and have remained independent from the group during the financial
        year.<br /><br />The audit committee considered information pertaining
        to the balance between fees for audit and non-audit work for the group
        in 2023 and concluded that the nature and extent of non-audit fees do
        not present a threat to the external auditors’ independence.
        Furthermore, after reviewing a report from the external auditors on all
        their relationships with the company that might reasonably have a
        bearing on the external auditors’ independence and the audit engagement
        partner and staff’s objectivity, and the related safeguards and
        procedures, the committee has concluded that the external auditors’
        independence was not impaired.<br /><br />The audit committee approved
        the external auditors’ terms of engagement, scope of work,the annual
        audit fee and noted the applicable levels of materiality.<br /><br />Based
        on written reports submitted, the committee reviewed, with the external
        auditors, the findings of their work and confirmed that all significant
        matters had been satisfactorily resolved. The committee determined that
        the 2023 audit was completed without any restriction on its scope.<br /><br />The
        audit committee has satisfied itself as to the suitability of the
        external auditors for re-appointment for the ensuing year.
      </p>
    </div>
  </div>
</div>
