<div class="container">
    <div class="bg-col" [style.background-image]="image" *ngIf="!video">

    </div>
    <div class="video" *ngIf="video">
        <iframe loading="lazy" src="https://www.youtube.com/embed/gXzSdgfPZ44" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="textcol">
        <h4>{{title}}</h4>
        <h3>{{subTitle}}</h3>
        <p>{{text}}</p>
    </div>
</div>