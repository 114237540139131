import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-foursight-banner',
  templateUrl: './foursight-banner.component.html',
  styleUrls: ['./foursight-banner.component.scss']
})
export class FoursightBannerComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  onclicked(){
    this.onClose.emit();
  }
}
