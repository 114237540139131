<div class="slider-container">
  <ngx-slick-carousel
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (afterChange)="afterChange($event)"
    class="carousel foursight-carousel"
  >
    <div ngxSlickItem class="slide" *ngFor="let member of teamMembers; let i = index">
      <div class="team-card">
        <img [src]="member.profileImage" />
        <div class="copy-container">
          <h5>{{member.name}}</h5>
          <p>{{ member.jobTitle}}</p>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
