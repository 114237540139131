<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="About Fortress" childTitle="Our History"></app-breadcrumbs>

    <app-about-page-banner 
        title="Fortress"
        link="/get-in-touch"
        linkText="GET IN TOUCH"
        image="url(../../../assets/images/properties/Bydgoszcz.webp)"
        text="Leveraging our specialised knowledge and expertise, our progress is powered by growing an increasingly responsible, sustainable and collaborative business that creates sustainable long-term value for our economic, organisational and societal stakeholders over time. We have an unwavering commitment to the tenants,  communities, investors and industry we serve to ensure collective growth."
    ></app-about-page-banner>

    <!--<app-slider></app-slider>-->

    <app-mission-blocks></app-mission-blocks>

    <app-footprint></app-footprint>

    <app-reit-with-a-difference-blocks
        title = "Where We Invest"
        desc = "Our diversified real estate portfolio primarily focuses on the logistics and convenience and commuter-based retail sectors in South Africa. We are actively looking for new retail and prime logistics development opportunities. <br/><br/>Our investment in NEPI Rockcastle provides us with exposure to the high-growth retail sector in Central and Eastern European (CEE) markets. We are selective and intend growing our high-quality logistics portfolio across the CEE region in the coming years. The market is in its development phase with a lot of opportunities for real estate investment for Fortress. As retail competition in the advanced economies of Western Europe is increasingly determined by the ability to successfully support e-commerce via omnichannel, growth in the logistics real estate sector in CEE will continue for decades to come."
        box1icon = "../../../assets/images/impact-callout.webp"
        box1Title = "Our Impact"
        box1desc = "As a responsible corporate citizen, we are cognisant of the global and local environmental, economic and social challenges we face. We are aware of the impact our business has, and the role we must play in protecting, improving and rejuvenating the environment, the economy and the communities in which we operate. We are taking all necessary steps to minimise our impact, because we care: we care about people and we care about the planet."
        box1btnText = "Learn more"
        box1link = "/about/our-impact"
        box2icon = "../../../assets/images/whistle-callout.svg"
        box2Title = "Meet The Team"
        box2desc = "Fortress has a highly regarded and experienced board and management team with extensive property-specific knowledge. We leverage this knowledge, along with our capacity to innovate, to build our Fortress brand and create value for our stakeholders. Our collective skills, experience and resources unlock the value in our properties and investments."
        box2btnText = "Learn more"
        box2link = "/about/meet-the-team"
    ></app-reit-with-a-difference-blocks>

    <app-explore-our-properties></app-explore-our-properties>

    <app-subscribe-banner></app-subscribe-banner>

    <app-about-callout-blocks></app-about-callout-blocks>

    <app-foursight-news></app-foursight-news>

    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>

