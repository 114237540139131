import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { createClient } from "contentful";
import { from } from "rxjs";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private client = createClient(environment.contentful);
  constructor() {}

  getContents() {
    const promise = this.client.getEntries({
      limit: 1000,
      order: '-fields.date',
      content_type: 'documents'
    });
    return from(promise).pipe(map(response => response.items));
  }

  getVideos() {
    const promise = this.client.getEntries({
      limit: 500,
      order: 'sys.createdAt',
      content_type: 'videos'
    });
    return from(promise).pipe(map(response => response.items));
  }

  getArticles() {
    const promise = this.client.getEntries({
      limit: 500,
      content_type: 'pressRelease'
    });
    return from(promise).pipe(map(response => response.items));
  }

}
