import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  profileForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    contactmethod: new FormControl('', Validators.required),
    option: new FormControl('', Validators.required),
    contactEmail: new FormControl(''),
    contactPhone: new FormControl(''),
    notes: new FormControl(''),
  });
  activeDevelopments:boolean=false;
  activeBroker:boolean=false;
  activeInvesting:boolean=false;
  activeProperties:boolean=false;
  activeCareers:boolean=false;
  activeVendor:boolean=false;
  activeContractor:boolean=false;
  activeGeneral:boolean=false;
  activePhone:boolean=false;
  activeEmail:boolean=false;
  allBool:boolean = this.activeDevelopments || this.activeBroker || this.activeInvesting || this.activeProperties || this.activeCareers || this.activeGeneral || this.activeVendor || this.activeContractor;
  sentSuccess:boolean=false;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    var hold = "Reply to "+ this.profileForm.value["firstName"]+ " on " + this.profileForm.value["contactmethod"] + ": "+ this.profileForm.value["contactEmail"] + this.profileForm.value["contactPhone"] +". Message: "+this.profileForm.value["notes"];
    if (this.profileForm) {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/mbjwaygo',
        { name: this.profileForm.value["firstName"], replyto:this.profileForm.value["contactEmail"], message: hold},
        { 'headers': headers }).subscribe(
          (          response: any) => {
              if (response.ok){
                this.sentSuccess = true;
              }
          }
        );
    }
  }

  toggleOption(option:string){
    switch(option){
      case "Developments": this.activeDevelopments = !this.activeDevelopments;
                          break;
      case "Broker": this.activeBroker = !this.activeBroker;
                          break;
      case "Investing": this.activeInvesting = !this.activeInvesting;
                          break;
      case "Properties": this.activeProperties = !this.activeProperties;
                          break;
      case "Careers": this.activeCareers = !this.activeCareers;
                          break;
      case "Vendor": this.activeVendor = !this.activeVendor;
                          break;
      case "Contractor": this.activeContractor = !this.activeContractor;
                          break;
      case "General": this.activeGeneral = !this.activeGeneral;
                          break;
    }
    this.allBool = this.activeDevelopments || this.activeBroker || this.activeInvesting || this.activeProperties || this.activeCareers || this.activeGeneral || this.activeVendor || this.activeContractor;
    this.profileForm.controls['option'].setValue('check');
  }

  toggleContact(method:string){
    switch(method){
      case "email": this.activePhone = false;
                    this.activeEmail = true;
                          break;
      case "phone": this.activePhone = true;
                    this.activeEmail = false;
                          break;
    }
  }

}
