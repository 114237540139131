import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-in-touch-banner',
  templateUrl: './get-in-touch-banner.component.html',
  styleUrls: ['./get-in-touch-banner.component.scss']
})
export class GetInTouchBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
