<div class="bg">
    <div class="container">
        <div class="content">
            <h1 class="main-title">Powering Growth</h1>
            <p>We are one of the largest real estate company's in South Africa and with operations in Central and Eastern Europe. We connect businesses with growth-powering real estate opportunities in state-of-the- art logistics parks and convenient, commuter-oriented retail centres.</p>
            <a class="secondary-btn" [routerLink]="['/get-in-touch']">Speak to us today</a>
        </div>
    </div>

    <div class="link-container">
        
        <div class="col dark-bg">
            <div class="content-inner">
                <span>01</span>
                <p class="title">Explore Properties</p>
                <p class="desc">Browse our portfolio of quality properties we own in office, retail and logistics including our new developments. You can also find vacancies and properties for sale</p>
                <a [routerLink]="['/properties/property-portfolio']">Explore all properties <img src="../../../assets/icons/icon-arrow-button.svg" alt=""/></a>
            </div>
            <div class="icon-container">
                <img class="icon" src="../../../assets/icons/icon-explore.svg" alt=""/>
            </div>
        </div>
        
        <div class="col medium-bg">
            <div class="content-inner">
                <span>02</span>
                <p class="title">Investors Documents</p>
                <p class="desc">Peruse our investor documents and see how we strive to provide our shareholders with consistent returns through income and capital growth.</p>
                <a [routerLink]="['investor-relations/investor-documents']">Discover all documents <img src="../../../assets/icons/icon-arrow-button.svg" alt=""/></a>
            </div>
            <div class="icon-container">
                <img class="icon" src="../../../assets/icons/icon-docs.svg" alt=""/>
            </div>
        </div>

        <div class="col light-bg">
            <div class="content-inner">
                <span>03</span>
                <p class="title">About Fortress</p>
                <p class="desc">We specialise in developing and acquiring state-of-the-art logistics real estate, and convenient, commuter-oriented retail centres in South Africa and logistics real estate in Central and Eastern Europe. Our focus is to own a growth-powering portfolio of two-thirds logistics and one-third retail.</p>
                <a [routerLink]="['/about/about-us']">Learn more <img src="../../../assets/icons/icon-arrow-button.svg" alt=""/></a>
            </div>
            <div class="icon-container">
                <img class="icon" src="../../../assets/icons/icon-fortress-about.svg" alt=""/>
            </div>
        </div>

    </div>
    
    <app-featured-properties id="target"></app-featured-properties>

    <app-footprint></app-footprint>

    <app-explore-our-properties></app-explore-our-properties>

    <app-popular-links></app-popular-links>

<!-- 
    <app-about-callout-blocks></app-about-callout-blocks>

    <app-explore-our-properties></app-explore-our-properties>
 
    <app-video
    title="Latest Fortress Video"
    text=""
    subtitle=""
    embed="https://www.youtube.com/watch?v=ITrh5xYF3Kg"
    sideVideo="left"  
      code="UaFOZU21tKk"
    ></app-video>

    <app-staggered-four-blocks></app-staggered-four-blocks> -->

    <app-subscribe-banner id="foursight"></app-subscribe-banner>

    <app-foursight-news></app-foursight-news>

    <!-- <app-get-in-touch-banner></app-get-in-touch-banner> -->
</div>