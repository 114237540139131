import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  login(password: string) {  
    if (password == "Fortress") {  
      localStorage.setItem('currentUser', "loggedin");  
      return true;  
    }else{
      return false;
    }  
  }  

  loginFinancials(password: string) {  
    if (password == "f1n@nc3") {  
      localStorage.setItem('currentUser', "loggedin");  
      return true;  
    }else{
      return false;
    }  
  }  
  
  logout() {  
    localStorage.removeItem('currentUser');  
  }  
  public get loggedIn(): boolean {  
    return (localStorage.getItem('currentUser') !== null);  
  }  
}
