<div class="testimonial-container">
    <div class="testimonial-block" *ngIf="slide1">
        <div class="block-img">
            <img class="pp" src="../../../assets/logo/takealot.webp" alt=""/>
            <p>Fortress Easport Logistic Park</p>
            <p>July 2021</p>
        </div>
        <div class="block-text">
            <p>The online retailer took up 20,000sqm of speculative warehousing</p>
        </div>
    </div>
    <div class="testimonial-block"  *ngIf="slide2">
        <div class="block-img">
            <img class="pp" src="../../../assets/images/testimonial-pp.webp" alt=""/>
            <p>Fortress Clairwood Logistic Park</p>
            <p>April 2021</p>
        </div>
        <div class="block-text">
            <p>The Logistics giant took up 35,000 square metres of prime space at the infamous KZN Port.</p>
        </div>
    </div>
   
   
</div>
<div class="navigation-holder">
    <div class="navigation-inner">
        <img (click)="navigate(-1)" class="left"  src="../../../assets/icons/icon-arrow-with-tail.svg" alt=""/>
        <div class="counter">
            <div [ngClass]="{'current': slide1}" class="ball "></div>
            <div [ngClass]="{'current': slide2}" class="ball"></div>
            <!--<div [ngClass]="{'current': slide3}" class="ball"></div>
            <div [ngClass]="{'current': slide4}" class="ball"></div>-->
        </div>
        <img (click)="navigate(1)"class="right" src="../../../assets/icons/icon-arrow-with-tail.svg" alt=""/>
    </div>
</div>