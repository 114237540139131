import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { ContentfulService } from "../../../services/contentful.service";
import {DomSanitizer} from '@angular/platform-browser';

export interface videos {
  title: string
  media:string
}
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  lessons$!: Observable<any>;
  allArr: videos[] =[];
  
  constructor(private contentful: ContentfulService,private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    var countAll = 0;

    this.lessons$ = this.contentful.getVideos();
    this.contentful.getVideos().subscribe(result => {
      for (let i = 0; i < result.length; i++) {
        var name = (result[i].fields as {title:string}).title;
        var media = (result[i].fields as {youtubeId:string}).youtubeId;
    
        this.allArr[countAll]={ 
          title: '',
          media:''
        };
        this.allArr[countAll].title = name;
        this.allArr[countAll].media = media;
      
        countAll++;
        }
    });
  }

  getURL(video:string){
    let sanitizedUrl =this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+video);
    return sanitizedUrl;
   
  }
}
