<a  class="backLink"  href="/financials/drafts"><img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/>Back</a>
<div class="block">
   
    <div *ngIf="show" >
        <h2>Your document is published, download it below</h2>
        <a  class="clearLink"  [href]="getURL(hold.file.url)" [download]="id">Download<img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
        <!-- <iframe [src]="getURL(hold.file.url)" width="100%" height="100%"></iframe>-->
    </div>
    <div *ngIf="!show" >
        <h2>Your document is not available yet for download</h2>
    </div>
</div>
