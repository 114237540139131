<div class="container">
    <div class="filter-col">
        <div class="dropdown" (click)="toggleFilter('gla')">
            <p class="subtitle">GLA</p>
            <img [ngClass]="{'open': showGla}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
        </div>
        <div class="filterBlock rangeBlock" *ngIf="showGla">
            <ngx-slider (userChangeEnd)="sliderEvent()" [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider>
        </div>
        <div class="dropdown" (click)="toggleFilter('category')">
            <p class="subtitle">Categories</p>
            <img [ngClass]="{'open': showCategory}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
        </div>
        <div class="filterBlock" *ngIf="showCategory">

            <div class="check-group">
                <input type="checkbox" id="CEELogisitics" (click)="search('CEELogisitics')" [checked]="CEELogisitics">
                <label for="CEELogisitics">CEE Logistics</label>
            </div>

            <div class="check-group">
                <input type="checkbox" id="logistics" (click)="search('Logistics')" [checked]="log">
                <label for="logistics">Logistics</label>
            </div>

            <div class="check-group">
                <input type="checkbox" id="industrial" (click)="search('Industrial')" [checked]="ind">
                <label for="industrial">Industrial</label>
            </div>

            <div class="check-group">
                <input type="checkbox" id="office" (click)="search('Office')" [checked]="off">
                <label for="office">Office</label>
            </div>
            
            <div class="check-group">
                <input type="checkbox" id="retail" (click)="search('Retail')" [checked]="ret">
                <label for="retail">Retail</label>
            </div>

        </div>
        <div class="dropdown" (click)="toggleFilter('area')">
            <p class="subtitle">Country</p>
            <img [ngClass]="{'open': showArea}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
        </div>
        <div class="filterBlock" *ngIf="showArea">
            <div class="check-group">
                <input type="checkbox" id="sa" (click)="countrySearchHandler('South Africa')" [checked]="sa">
                <label for="sa">South Africa</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="ee" (click)="countrySearchHandler('Poland')" [checked]="eu">
                <label for="ee">Poland</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="romania" (click)="countrySearchHandler('Romania')" [checked]="romania">
                <label for="romania">Romania</label>
            </div>
        </div>
        <!-- <div class="dropdown" (click)="toggleFilter('province')">
            <p class="subtitle">Province</p>
            <img [ngClass]="{'open': showProvince}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
        </div> -->
        <!-- <div class="filterBlock" *ngIf="showProvince">
            <div class="check-group">
                <input type="checkbox" id="Gauteng" (click)="search('ga')" [checked]="ga">
                <label for="Gauteng">Gauteng</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="ec" (click)="search('ec')" [checked]="ec">
                <label for="ec">Eastern Cape</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="Limpopo" (click)="search('lim')" [checked]="lim">
                <label for="Limpopo">Limpopo</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="wc" (click)="search('wc')" [checked]="wc">
                <label for="wc">Western Cape</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="Mpumalanga" (click)="search('mp')" [checked]="mp">
                <label for="Mpumalanga">Mpumalanga</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="nc" (click)="search('nc')" [checked]="nc">
                <label for="nc">Northern Cape</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="kzn" (click)="search('kzn')" [checked]="kzn">
                <label for="kzn">Kwazulu Natal</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="Freestate" (click)="search('fs')" [checked]="fs">
                <label for="Freestate">Freestate</label>
            </div>
            <div class="check-group">
                <input type="checkbox" id="nw" (click)="search('nw')" [checked]="nw">
                <label for="nw">North West</label>
            </div>
        </div> -->
        <!-- <h4 class="refine">Refine Further</h4> -->
        <!-- <div class="dropdown" (click)="toggleFilter('gla')">
            <p class="subtitle">GLA</p>
            <img [ngClass]="{'open': showGla}" src="../../../../../assets/icons/icon-arrow-menu-black.svg" alt=""/>
        </div>
        <div class="filterBlock rangeBlock" *ngIf="showGla">
            <ngx-slider (userChangeEnd)="sliderEvent()" [(value)]="value" [(highValue)]="highValue" [options]="options"></ngx-slider> 
        </div> -->
    </div>
    <div class="col">
        <div class="filter-top">
            <h3>{{(allProp | searchFilterProperties: searchText:catSearch:countrySearch:provinceSearch).length}} Properties</h3>
            <a class="primary-btn" (click)="compareToggle()"><span *ngIf="!compareNow">Compare<img src="../../../../../assets/icons/icon-compare.svg" alt=""/></span><span *ngIf="compareNow">Compare up to three properties<img src="../../../../../assets/icons/icon-close.svg" alt=""/></span></a>
            <div class="search">
                <img class="mag" src="../../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
                <input class="searchbox" type="text" placeholder="Search by keyword" [(ngModel)]="searchText"/>
                <img class="close" (click)="clearSearch()" src="../../../../../assets/icons/icon-close-black.svg" alt=""/>
            </div>
        </div>
        <div class="searchTextHolder" *ngIf="catSearch || countrySearch || provinceSearch">
            <p><b>Search:</b> {{catSearch}}<span *ngIf="catSearch">, </span>{{countrySearch}}<span *ngIf="countrySearch">, </span>{{provinceSearch}}</p>
        </div>
        <div class="properties">
            <div class="compare-prop">
               <div class="prop" *ngFor="let prop of compareArr;let i = index">
                    <div  class="col-img" [style.background-image]="getPropFeature(prop.imageBucketUrl, prop.frontPageImage)">
                </div>
                <div class="content">
                    <h4>{{prop.buildingName}} </h4>
                    <p class="info">GLA: <span>{{prop.buildingGla}} </span></p>
                    <p class="info">Province: <span>{{prop.province}} </span></p>
                    <p class="info">Address: <span>{{prop.streetAddress}} </span></p>
                    <div class="row">
                        
                        <a *ngIf="!vacancies" [routerLink]="['/properties/indivual-property']" [queryParams]="{ property: prop.id }"  (click)="viewProp(prop.id)">View <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                        <a *ngIf="vacancies" [routerLink]="['/properties/indivual-property-vacancies']" [queryParams]="{ property: prop.id }"  (click)="viewProp(prop.id)">View <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                    </div>
                </div>
                <div class="stickers">
                    <div class="check-group" *ngIf="compareNow">
                        <div class="box" (click)="removeCheckItem(prop.id)">
                            <img *ngIf="prop.compare" src="../../../../../assets/icons/icon-check.svg" alt=""/>
                        </div>
                    </div>
                    <img *ngFor="let feature of getFeatures(prop.buildingFeatures);let i = index" [src]="getImage(feature)" alt="" [title]="feature"/>
                </div>
                </div>
                <div class="hr"></div>
            </div>
            <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
            <!--ALL PROPERTIES DISPLAY HERE-->
            <div class="prop" *ngFor="let prop of allProp | sortByGLA | searchFilterProperties: searchText:catSearch:countrySearch:provinceSearch  | paginate : {itemsPerPage: pageSize,  currentPage: page,totalItems: count};let i = index;">
                <div class="col-img" [style.background-image]="getPropFeature(prop.imageBucketUrl, prop.frontPageImage)">
                </div>
               
                <div class="content">
                    <h4>{{prop.buildingName}} </h4>
                    <p class="info">GLA: <span>{{prop.buildingGla}} </span></p>
                    <p class="info">Province: <span>{{prop.province}} </span></p>
                    <p class="info">Address: <span>{{prop.streetAddress}} </span></p>
                    <div class="row">
                        
                        <a *ngIf="!vacancies" [routerLink]="['/properties/indivual-property']" [queryParams]="{ property: prop.id }"  (click)="viewProp(prop.id)">View <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                        <a *ngIf="vacancies" [routerLink]="['/properties/indivual-property-vacancies']" [queryParams]="{ property: prop.id }"  (click)="viewProp(prop.id)">View <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                    </div>
                </div>
                <div class="stickers">
                   <div class="check-group" *ngIf="compareNow">
                        <div class="box" (click)="compareCheck(prop.id)">
                            <img *ngIf="prop.compare" src="../../../../../assets/icons/icon-check.svg" alt=""/>
                        </div>
                    </div>
                   
                    <img *ngFor="let feature of getFeatures(prop.buildingFeatures);let i = index" [src]="getImage(feature)" alt="" [title]="feature"/>
                   
                </div>
               
            </div>
            <pagination-controls
                previousLabel="Prev"
                nextLabel="Next"
                [responsive]="true"
                (pageChange)="handlePageChange($event)"
            ></pagination-controls>
        </div>
    </div>
</div>