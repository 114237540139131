import { Component, Input, OnChanges,  SimpleChanges,  ChangeDetectionStrategy, } from '@angular/core';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-webcasts',
  templateUrl: './webcasts.component.html',
  styleUrls: ['./webcasts.component.scss']
})
export class WebcastsComponent implements OnChanges {
  @Input() sort: string= '';
  
  search:string='';
  art0:boolean=true;
  art1:boolean=true;
  art2:boolean=true;
  art3:boolean=true;
  art4:boolean=true;
  art5:boolean=true;
  art6:boolean=true;
  art7:boolean=true;
  art8:boolean=true;
  art01:boolean=true;
  art02:boolean=true;
  constructor(private scroller: ViewportScroller) { }

  ngOnChanges(changes: SimpleChanges){
    this.scroller.scrollToAnchor("target");
    switch (this.sort){
      case '2019':  this.art0=false;
                    this.art1=false;
                    this.art2=false;
                    this.art3=false;
                    this.art4=false;
                    this.art5=false;
                    this.art6=false;
                    this.art7=true;
                    this.art8=false;
                    this.art01=false;
                    this.art02=false;
                    break;
      case '2020':  this.art0=false;
                    this.art1=false;
                    this.art2=true;
                    this.art3=true;
                    this.art4=true;
                    this.art5=false;
                    this.art6=true;
                    this.art7=false;
                    this.art8=false;
                    this.art01=false;
                    this.art02=false;
                    break;
      case '2021':  this.art0=false;
                    this.art1=true;
                    this.art2=false;
                    this.art3=false;
                    this.art4=false;
                    this.art5=false;
                    this.art6=false;
                    this.art7=false;
                    this.art8=false;
                    this.art01=false;
                    this.art02=false;
                    break;
      case '2022':  this.art0=true;
                    this.art1=false;
                    this.art2=false;
                    this.art3=false;
                    this.art4=false;
                    this.art5=false;
                    this.art6=false;
                    this.art7=false;
                    this.art8=false;
                    this.art01=true;
                    this.art02=true;
                    break;
      case 'All':   this.art0=true;
                    this.art1=true;
                    this.art2=true;
                    this.art3=true;
                    this.art4=true;
                    this.art5=true;
                    this.art6=true;
                    this.art7=true;
                    this.art8=true;
                    this.art01=true;
                    this.art02=true;
                    break;
      default:      this.art0=false;
                    this.art1=false;
                    this.art2=false;
                    this.art3=false;
                    this.art4=false;
                    this.art5=false;
                    this.art6=false;
                    this.art7=false;
                    this.art8=false;
                    this.art01=false;
                    this.art02=false;
    }
  }

  

}
