import { Component, OnInit,Input  } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
export interface feat {
  generators: boolean
  internetDescription: string
  securityDescription: string
  yardTypeString: string
  }
@Component({
  selector: 'app-property-address-block',
  templateUrl: './property-address-block.component.html',
  styleUrls: ['./property-address-block.component.scss']
})



export class PropertyAddressBlockComponent implements OnInit {
  @Input() address: string = '';
  @Input() province: string = '';
  @Input() lat: number = 0;
  @Input() long: number = 0;
  @Input() features:any;
  @Input() web: string = '';
  @Input() google: string = '';
  @Input() statText1: string = '';
  @Input() stat1: number = 0;
  @Input() statText2: string = '';
  @Input() stat2: number = 0;
  @Input() statText3: string = '';
  @Input() stat3: string = "0";
  
  latitude: number=0;
  longitude: number=0;
  zoom:number=15;
  
  access:string="";
  internet:string="";
  generator:string="";
  yard:string="";
  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    if (this.features){
      this.access = this.features['accessTypeString'];
      this.internet = this.features['internetDescription'];
      this.generator = this.features['generators'];
      this.yard = this.features['yardTypeString'];
    }
  }

  getLocation() {
    let sanitizedUrl =this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps/embed/v1/place?key=AIzaSyAIzWW_neiGG-6cfkr-1DMB_glXGYW4M34&q="+this.lat +","+this.long+"");
    return sanitizedUrl;
  }

  getLocationURL() {
    let sanitizedUrl =this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps/place?&q="+this.lat +","+this.long+"");
    return sanitizedUrl;
  }

  getLocationStreetView() {
    let sanitizedUrl =this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps/embed/v1/streetview?key=AIzaSyAIzWW_neiGG-6cfkr-1DMB_glXGYW4M34&location="+this.lat +","+this.long+"&heading=210&pitch=10&fov=35");
    return sanitizedUrl;
  }
  
}
