import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-about-two-block-intro',
  templateUrl: './about-two-block-intro.component.html',
  styleUrls: ['./about-two-block-intro.component.scss']
})
export class AboutTwoBlockIntroComponent implements OnInit {
  @Input() title: string = "";
  @Input() subTitle: string = "";
  @Input() text: string = "";
  @Input() image: string = "";
  @Input() video: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
