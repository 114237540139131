<div class="banner-container">
    <a routerLink="/get-in-touch">
        <div class="banner-inner">
            <div class="content">
                <h3>Get In Touch</h3>
            </div>
            <div class="content">
                <p>Find out how can we help you</p>
                <div></div>
            </div>
        </div>
    </a>
</div>