import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shares-block',
  templateUrl: './shares-block.component.html',
  styleUrls: ['./shares-block.component.scss']
})
export class SharesBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
