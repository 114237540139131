<div class="container">
    <div class="col">
        <div class="inner">
            <h4>Popular links</h4>
            <p>Get acquainted with how we connect businesses with growth-powering real estate opportunities. Our culture of collaboration ensures collective growth for our people, tenants, partners, the environment and the communities that we support, no matter what it takes.</p>
        </div>
    </div>
    <div class="col carousel">
        <ngx-slick-carousel class="popular-links-carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
            <div ngxSlickItem  class="card">
                <p class="number">01</p>
                <h4>Financial reports</h4>
                <p class="text">At Fortress, we proactively grow the balance sheet, preserve liquidity, recycle capital and focus on operating from a solid base and an active asset management approach which allows us to position the business for growth going forward.</p>
                <a href="/investor-relations/investor-documents?document=annual-reports" class="clearLink">Learn more 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8.33366 6.66602L11.667 9.99935L8.33366 13.3327" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                </a>
            </div>
            <div ngxSlickItem  class="card">
                <p class="number">02</p>
                <h4>Our impact</h4>
                <p class="text">We are committed to using innovation to reduce our impact on the environments in which we operate. Sustainable and ethical practices are a priority in the way we do business.</p>
                <a href="/sustainability/our-impact" class="clearLink">Learn more 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8.33366 6.66602L11.667 9.99935L8.33366 13.3327" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                </a>
            </div>
            <div ngxSlickItem  class="card">
                <p class="number">03</p>
                <h4>New developments</h4>
                <p class="text">Our state-of-the-art developments encompass all of the needs and modern solutions that businesses require to move forward and are focussed on providing valued-based solutions for our clients.</p>
                <a href="/properties/new-developments" class="clearLink">Learn more 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8.33366 6.66602L11.667 9.99935L8.33366 13.3327" stroke="#00C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                </a>
            </div>

        </ngx-slick-carousel>
    </div>
    
    
    
</div>

