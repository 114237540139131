import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-property-gallery-block',
  templateUrl: './property-gallery-block.component.html',
  styleUrls: ['./property-gallery-block.component.scss']
})
export class PropertyGalleryBlockComponent implements OnInit {
  
  @Input() statText1: string = '';
  @Input() stat1: number = 0;
  @Input() statText2: string = '';
  @Input() stat2: number = 0;
  @Input() statText3: string = '';
  @Input() stat3: string = "0";
  @Input() img1: string = '';
  @Input() img2: string = '';
  @Input() img3: string = '';
  @Input() img4: string = '';
  @Input() img5: string = '';
  @Input() bucket: string = '';
  @Input() images: Array<any> = [];
  bigImage:string="";
  photoTag:string="";
  
  //currentImage:string="url('"+this.img1+"')";
  constructor() { }

 
  ngOnInit(): void {
    //console.log(this.images);
    this.img1 = this.images[0].fileName;
    
    this.img1 =  this.img1.replace(/\s/g, "%20");
    this.bigImage =  this.bucket+"/"+ this.img1;
    this.photoTag = this.images[0].imageSectionString;
  
  }
  changeURL(url:string, tag:string){
    this.photoTag = tag;
    var img = url.replace(/\s/g, "%20");
    this.bigImage =  this.bucket+"/"+img;
  }

  getUrl(img:string, tag:string){
    this.photoTag = tag;
     img = img.replace(/\s/g, "%20");
      return this.bucket+"/"+img;
  }
}
