<div class="container">
  <div class="row">
    <h4>FOURsight</h4>
    <a
      class="secondary-btn browse-all-btn"
      [routerLink]="['/foursight/all-articles']"
      >View all articles</a
    >
  </div>

  <div class="slider-container">
    <ngx-slick-carousel
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (afterChange)="afterChange($event)"
      class="carousel foursight-carousel"
      [ngClass]="{ 'white-carousel-nav': whiteNav }"
    >
      <div
        ngxSlickItem
        class="slide"
        *ngFor="let event of events; let i = index"
      >
        <div class="content">
          <div class="img-container">
            <p class="title-sub">{{ event.subtitle }}</p>
            <img [src]="event.img" alt="Property Image" />
          </div>
          <div class="content-container">
            <p class="date">
              {{ event.category }}
              <span *ngIf="event.date"> / {{ event.date }}</span>
            </p>
            <h2 class="title">{{ event.title | truncate : 50 }}</h2>
            <a *ngIf="event.modalID" (click)="openModal(event.modalID!)"
              >{{ event.btnText }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.33366 6.66602L11.667 9.99935L8.33366 13.3327"
                  stroke="#00C5CB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a *ngIf="!event.modalID" href="{{ event.url }}"
              >{{ event.btnText }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M8.33366 6.66602L11.667 9.99935L8.33366 13.3327"
                  stroke="#00C5CB"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>

<jw-modal id="custom-modal-1">
  <div class="modalInner">
    <img
      src="../../../assets/icons/icon-close-black.svg"
      alt=""
      (click)="closeModal1('custom-modal-1')"
    />
    <iframe
      #youtubeVideo1
      class="iframeHold"
      loading="lazy"
      width="1280"
      height="720"
      src="https://www.youtube.com/embed/GbzKNSW0TKY"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</jw-modal>
<jw-modal id="custom-modal-2">
  <div class="modalInner">
    <img
      src="../../../assets/icons/icon-close-black.svg"
      alt=""
      (click)="closeModal2('custom-modal-2')"
    />
    <iframe
      #youtubeVideo2
      class="iframeHold"
      loading="lazy"
      width="1280"
      height="720"
      src="https://www.youtube.com/embed/KlBlvrSYEhs"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</jw-modal>

<jw-modal id="custom-modal-3">
  <div class="modalInner">
    <img
      src="../../../assets/icons/icon-close-black.svg"
      alt=""
      (click)="closeModal3('custom-modal-3')"
    />
    <iframe
      #youtubeVideo3
      class="iframeHold"
      loading="lazy"
      width="1280"
      height="720"
      src="https://www.youtube.com/embed/EYaQkOebIyE"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</jw-modal>
