<div class="container" id="target">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 583 892"
    fill="none"
    class="ascent-svg"
  >
    <path
      d="M975 445.422C975 598.138 939.378 725.401 838.833 804.651C749.204 873.489 633.721 892 487.787 892C339.555 892 225.221 873.489 135.592 802.916C34.4726 725.401 0 598.138 0 445.422C0 293.863 34.4726 163.707 136.167 86.192C225.796 17.3541 341.279 0 487.213 0C635.445 0 749.779 19.0895 839.408 86.192C939.378 165.442 975 293.863 975 445.422Z"
      fill="#002E36"
      fill-opacity="0.05"
    />
  </svg>
  <div class="row">
    <div class="filter-col">
      <h2>Portfolio Statistics</h2>
      <p>
        With a wealth of expertise and experience, and an unwavering commitment
        to powering growth, we aim to create long-term, sustainable value for
        shareholders.
      </p>
    </div>
    <div class="results-col">
      <div class="stat">
        <img
          class="statBig"
          src="../../../../../assets/images/stats/statLarge.svg"
          alt=""
        />
        <div class="contentBlock">
          <h5>Total real estate assets of R32.5 billion</h5>
          <p><span class="col1"></span>Debt | R18 billion</p>
          <p><span class="col2"></span>TNAV | R32,1 billion</p>
          <p><span class="col3"></span>NEPI Holding | R16,8 billion</p>
          <p><span class="col4"></span>SA Retail | R9,9 billion</p>
          <p><span class="col6"></span>SA Offices and Land | R1,3 billion</p>
          <p><span class="col7"></span>SA Industrial | R2,6 billion</p>
          <p><span class="col9"></span>SA Development | R2,2 billion</p>
          <p><span class="col10"></span>SA Logistics | R13,2 billion</p>
          <p><span class="col5"></span>SA Other | R1,95 billion</p>
          <p><span class="col8"></span>CEE Logistics | R2,8 billion</p>
          <p><span class="col8"></span>CEE Development | R663 million</p>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div
      class="block"
      *ngFor="
        let stat of stats
          | searchFilter : searchText
          | paginate
            : { itemsPerPage: pageSize, currentPage: page, totalItems: count };
        let i = index
      "
    >
      <div class="innerBlock" [ngClass]="{ circleBG: stat.bg }">
        <img class="stat" [src]="stat.img" alt="" />
        <p class="name">{{ stat.name }}</p>
      </div>
    </div>
  </div>
  
</div>
