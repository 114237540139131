<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Our properties" parentLink="/properties/property-portfolio" [childTitle]="currentProp.buildingName"></app-breadcrumbs>
    <app-property-title-block  *ngIf="doneLoading"
        [title]="currentProp.buildingName"
        link=""
        linkText="Contact"
        [image]="getImage(currentProp.imageBucketUrl, currentProp.frontPageImage)"
        [text]="currentProp.description"
        [subTitle] ="currentProp.province"
        [contacts] ="currentProp.buildingContactableList"
    ></app-property-title-block>

    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>

    <div class="container">
        <div class="col">
            <app-form-enquiry *ngIf="doneLoading"
            [id] ="id"
            [title]="currentProp.buildingName"
            [contacts] ="currentProp.buildingContactableList"
            ></app-form-enquiry>
        </div>
        <div class="col">
            <app-property-gallery-block 
                *ngIf="doneLoading"
                    statText1= 'GLA'
                    [stat1] = 'currentProp.buildingGla'
                    statText2= 'Total Units'
                    [stat2] = 'currentProp.totalUnits'
                    statText3= 'Sector'
                    [stat3]= 'currentProp.sector'
                    [img1] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img2] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img3] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img4] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img5] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [images]="currentProp.buildingImages"
                    [bucket]="currentProp.imageBucketUrl"
                ></app-property-gallery-block>
        </div>
    </div>

    

    
    <app-video
    *ngIf="doneLoading  && currentProp.videoUrl !=='' && currentProp.videoUrl !== null"
    title="Explore this property virtually"
    text=""
    subtitle="discover the property"
    [embed]="currentProp.videoUrl"
    sideVideo="right"    
    ></app-video>
   <!-- <app-video-vr
    *ngIf="doneLoading && currentProp.virtualTourUrl !=='' && currentProp.virtualTourUrl !== null"
    title="VR Footage"
    text="Explore what it is that makes us different, or take a look through our property portfolio."
    subtitle="discover the property"
    [embed]='currentProp.virtualTourUrl'
    sideVideo="right"    
    ></app-video-vr>

    <div class="containerUnits" *ngIf="showUnits">
        <h3>Available Units Breakdown</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Unit Name</th>
                    <th>Category</th>
                    <th>GLA</th>
                    <th>Available lease date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let unit of units; let i = index">
                    <td>{{unit.unitName}}</td>
                    <td>{{unit.unitCategoryString}}</td>
                    <td>{{unit.unitGla}}</td>
                    <td>{{unit.availableDate | date: 'd MMMM YYYY'}}</td>
                </tr>
            </tbody>
        </table>
    </div>-->


    <app-property-address-block
    *ngIf="doneLoading"
    [address]="currentProp.streetAddress"
    [province]="currentProp.province"
    [features]="currentProp.buildingFeatures"
    [lat]="currentProp.latitude"
    [long]="currentProp.longitude"
    [web]="currentProp.webUrl"
    [google]="currentProp.virtualTourUrl"
    statText1= 'GLA'
    [stat1] = 'currentProp.buildingGla'
    statText2= 'Total Units'
    [stat2] = 'currentProp.totalUnits'
    statText3= 'Sector'
    [stat3]= 'currentProp.sector'
    ></app-property-address-block>



    <app-get-in-touch-banner></app-get-in-touch-banner>

    <app-similar-properties [props]="allProp" [sector]= 'currentProp.sector' [currentID]= 'currentProp.id' *ngIf="doneLoading"></app-similar-properties>

    <app-subscribe-banner></app-subscribe-banner>

</div>