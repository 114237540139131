<div class="sub-menu">
    <div class="col">
        <!-- <h5>About fortress</h5> -->
        <a routerLinkActive="active-link" (click)="onclicked()" [routerLink]="['/about/about-us']" [routerLinkActiveOptions]="{exact:true}">About Us</a>
        <!-- <a routerLinkActive="active-link" (click)="onclicked()"  [routerLink]="['/about/our-impact']" [routerLinkActiveOptions]="{exact:true}">Our Impact</a> -->
        <a routerLinkActive="active-link"(click)="onclicked()" [routerLink]="['/about/meet-the-team']" [routerLinkActiveOptions]="{exact:true}">Meet The Team</a>
        <a routerLinkActive="active-link"(click)="onclicked()" [routerLink]="['/about/corporate-governance']" [routerLinkActiveOptions]="{exact:true}">Corporate Governance</a>
        <a routerLinkActive="active-link"(click)="onclicked()" [routerLink]="['/about/paia']" [routerLinkActiveOptions]="{exact:true}">PAIA</a>
    </div>
    <div class="col padding-top">
        <a class="icon-link" (click)="onclicked()" href="https://www.linkedin.com/company/fortressreitlimited/" target="_blank"><img src="../../../assets/icons/icon-linkedin.svg" alt=""/>Linkedin</a>
        <a class="icon-link" (click)="onclicked()" [routerLink]="['/whistleblower']"><img src="../../../assets/icons/icon-whistle.svg" alt=""/>Whistleblower</a>
        <a class="icon-link" (click)="onclicked()" href="mailto:info@fortressfund.co.za"><img src="../../../assets/icons/icon-email-white.svg" alt=""/>info@fortressfund.co.za</a>
    </div>
    <div class="col padding-top">
        <a href="tel:+27112822800" class="icon-link"><img src="../../../assets/icons/icon-phone.svg" alt=""/>Johannesburg: +27 (0) 11 282 2800</a>
        <a href="tel:+27219452960" class="icon-link"><img src="../../../assets/icons/icon-phone.svg" alt=""/>Cape Town: +27 (0) 21 945 2960</a>
        <a href="tel:+27836267161" class="icon-link"><img src="../../../assets/icons/icon-phone.svg" alt=""/>Durban: +27 (0) 83 626 7161</a>
    </div>
</div>