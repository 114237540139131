<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Our properties" childTitle="Our Vacancies"  [isLarge]=false></app-breadcrumbs>
    
    <div class="btn-holder">
        <button (click)="logout()" class="btn btn-danger">Logout</button>    
    </div>
    

    <app-search-filter-all-properties [filter]=true [vacancies]="true"></app-search-filter-all-properties>

    <app-subscribe-banner></app-subscribe-banner>

    <app-about-callout-blocks></app-about-callout-blocks>

    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>