<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Investor Relations" childTitle="Shareholder Analysis"></app-breadcrumbs>

    <app-banner-foursight
        title="Shareholder Analysis"
        link=""
        linkText=""
        image="url(../../../assets/images/shareholderAnalysis.webp)"
        imageAlone="../../../assets/images/shareholderAnalysis.webp"
        text="as at 30 June 2023"
        subTitle ="Analysis of ordinary shareholders"
    ></app-banner-foursight>

   <div class="container">
       <h2>Fortress Real Estate Investments  - A Shares</h2>
       <table>
           <thead>
                <th>Shareholder type</th>
                <th>Number of shareholdings</th>
                <th>% of total shareholdings</th>
                <th>Number of shares</th>
                <th>% of issued capital</th>
           </thead>
           <tbody>
               <tr class="bold bgColor">
                   <td>Non public shareholders</td>
                   <td>52</td>
                   <td>1.04</td>
                   <td>31 775 789</td>
                   <td>2.67</td>
               </tr>
               <tr>
                    <td>Directors, employees and their associates</td>
                    <td>51</td>
                    <td>1.02</td>
                    <td>4 913 793</td>
                    <td>0,41</td>
                </tr>
                <tr>
                    <td>Treasury</td>
                    <td>1</td>
                    <td>0,02</td>
                    <td>26 861 996</td>
                    <td>2.26</td>
                </tr>
                <tr class="bold bgColor">
                    <td>Public shareholders</td>
                    <td>4 955</td>
                    <td>98.96</td>
                    <td>1 159 819 383</td>
                    <td>97.33</td>
                </tr>
                <tr class="bold">
                    <td>Total</td>
                    <td>5 007</td>
                    <td>100,00</td>
                    <td>1 191 595 172</td>
                    <td>100,00</td>
                </tr>
           </tbody>
       </table>

       <table>
        <thead>
             <th>Shareholder spread</th>
             <th>Number of shareholdings</th>
             <th>% of total shareholdings</th>
             <th>Number of shares</th>
             <th>% of issued capital</th>
        </thead>
        <tbody>
            <tr>
                <td>1 - 1 000</td>
                <td>2 335 </td>
                <td>46.63</td>
                <td>439 901</td>
                <td>0,04</td>
            </tr>
            <tr>
                <td>1 001 - 10 000</td>
                <td>1 431</td>
                <td>28.58</td>
                <td>5 510 689</td>
                <td>0.46</td>
             </tr>
             <tr>
                <td>10 001 - 100 000</td>
                <td>655</td>
                <td>13.08</td>
                <td>21 552 485</td>
                <td>1.81</td>
             </tr>
             <tr>
                <td>100 001 - 1 000 000</td>
                <td>410</td>
                <td>8.19</td>
                <td>136 884 478</td>
                <td>11.49</td>
             </tr>
             <tr>
                <td>Over 1 000 000</td>
                <td>176</td>
                <td>3.52</td>
                <td>1 027 207 619</td>
                <td>86.20</td>
             </tr>
             <tr class="bold bgColor">
                <td>Total</td>
                <td>5007</td>
                <td>100,00</td>
                <td>1 191 595 172</td>
                <td>100,00</td>
             </tr>
        </tbody>
    </table>

    <table class="smallTable">
        <thead>
             <th style="width: 60%;">Registered shareholders owning 5% or more of the issued shares</th>
             <th>Number of shares</th>
             <th>% of issued capital</th>
        </thead>
        <tbody>
            <tr>
                <td>Government Employees Pension Fund</td>
                <td>169 521 464</td>
                <td>14.23</td>
            </tr>
            <tr>
                <td>Coronation Balanced Plus Fund</td>
                <td>116 363 447</td>
                <td>9,77</td>
             </tr>
             <tr class="bold bgColor">
                <td>Total</td>
                <td>285 884 911</td>
                <td>24.00</td>
             </tr>
        </tbody>
    </table>

    <table class="smallTable">
        <thead>
             <th style="width: 60%;">Beneficial shareholders with a holding of 5% or more of the issued share</th>
             <th>Number of shares</th>
             <th>% of issued capital</th>
        </thead>
        <tbody>
            <tr>
                <td>Government Employees Pension Fund</td>
                <td>187 612 563</td>
                <td>15.74</td>
             </tr>
            <tr>
                <td>Coronation Fund Managers</td>
                <td>146 171 917</td>
                <td>12.27</td>
            </tr>
             <tr class="bold bgColor">
                <td>Total</td>
                <td>333 784 480</td>
                <td>28.01</td>
             </tr>
        </tbody>
    </table>

    <table class="smallTable">
        <thead>
             <th style="width: 60%;">Control of more than 5% of issued shares</th>
             <th>Number of shares</th>
             <th>% of issued capital</th>
        </thead>
        <tbody>
            <tr>
                <td>Coronation Fund Managers</td>
                <td>190 409 390</td>
                <td>15.98</td>
            </tr>
            <tr>
                <td>Public Investment Corporation</td>
                <td>189 947 685</td>
                <td>15.94</td>
             </tr>
             <tr>
                <td>Sesfikile Capital</td>
                <td>81 806 272</td>
                <td>6.87</td>
             </tr>
             <tr>
                <td>Allan Gray</td>
                <td>62 040 726</td>
                <td>5.21</td>
             </tr>
             <tr>
                <td>Catalyst Fund Managers</td>
                <td>59 921 521</td>
                <td>5.03</td>
             </tr>
            
             <tr class="bold bgColor">
                <td>Total</td>
                <td>584 125 594</td>
                <td>49.03</td>
             </tr>
        </tbody>
    </table>
   </div>






   <div class="container">
    <h2>Fortress Real Estate Investments  - B Shares</h2>
    <table>
        <thead>
             <th>Shareholder type</th>
             <th>Number of shareholdings</th>
             <th>% of total shareholdings</th>
             <th>Number of shares</th>
             <th>% of issued capital</th>
        </thead>
        <tbody>
            <tr class="bold bgColor">
                <td>Non public shareholders</td>
                <td>68</td>
                <td>0.95</td>
                <td>115 272 817</td>
                <td>10.54</td>
            </tr>
            <tr>
                 <td>Directors, employees and their associates</td>
                 <td>60</td>
                 <td>0,84</td>
                 <td>27 736 464</td>
                 <td>2.54</td>
             </tr>
             <tr>
                <td>Treasury</td>
                 <td>8</td>
                 <td>0,11</td>
                 <td>87 536 353</td>
                 <td>8.00</td>
             </tr>
             <tr class="bold bgColor">
                 <td>Public shareholders</td>
                 <td>7 097</td>
                 <td>99.05</td>
                 <td>977 940 211</td>
                 <td>89.46</td>
             </tr>
             <tr class="bold">
                 <td>Total</td>
                 <td>7165</td>
                 <td>100,00</td>
                 <td>1 093 213 028</td>
                 <td>100,00</td>
             </tr>
        </tbody>
    </table>

    <table>
     <thead>
          <th>Shareholder Spread</th>
          <th>Number of shareholdings</th>
          <th>% of total shareholdings</th>
          <th>Number of shares</th>
          <th>% of issued capital</th>
     </thead>
     <tbody>
         <tr>
             <td>1 - 1 000</td>
             <td>4 195</td>
             <td>58.55</td>
             <td>693 203</td>
             <td>0,06</td>
         </tr>
         <tr>
             <td>1 001 - 10 000</td>
             <td>1 517</td>
             <td>21.17</td>
             <td>6 236 478</td>
             <td>0,57</td>
          </tr>
          <tr>
             <td>10 001 - 100 000</td>
             <td>923</td>
             <td>12.88</td>
             <td>31 272 097</td>
             <td>2.86</td>
          </tr>
          <tr>
             <td>100 001 - 1 000 000</td>
             <td>398</td>
             <td>5.56</td>
             <td>123 712 687</td>
             <td>11.32</td>
          </tr>
          <tr>
             <td>Over 1 000 000</td>
             <td>132</td>
             <td>1.84</td>
             <td>931 298 563</td>
             <td>85.19</td>
          </tr>
          <tr class="bold bgColor">
             <td>Total</td>
             <td>7 165</td>
             <td>100,00</td>
             <td>1 093 213 028</td>
             <td>100,00</td>
          </tr>
     </tbody>
 </table>

 <table class="smallTable">
     <thead>
          <th style="width: 60%;">Registered shareholders owning 5% or more of the issued shares</th>
          <th>Number of shares</th>
          <th>% of issued capital</th>
     </thead>
     <tbody>
         <tr>
             <td>Government Employees Pension Fund</td>
             <td>119 777 258</td>
             <td>10.96</td>
         </tr>
         <tr>
            <td>10X</td>
            <td>55 000 000</td>
            <td>5.03</td>
        </tr>
          <tr class="bold bgColor">
             <td>Total</td>
             <td>174 777 258</td>
             <td>15.99</td>
          </tr>
     </tbody>
 </table>

 <table class="smallTable">
     <thead>
          <th style="width: 60%;">Beneficial shareholders with a holding of 5% or more of the issued share</th>
          <th>Number of shares</th>
          <th>% of issued capital</th>
     </thead>
     <tbody>
        <tr>
            <td>Government Employees Pension Fund</td>
            <td>130 592 489</td>
            <td>11.95</td>
         </tr>
         <tr>
             <td>Capital Propfund Proprietary Limitied*</td>
             <td>87 536 353</td>
             <td>8.01</td>
         </tr>
          <tr>
            <td>Collectively: Fortress Empowerment 3 and Fortress Empowerment 4</td>
            <td>86 628 959</td>
            <td>7.92</td>
          </tr>
          <tr>
            <td>Collectively: Fortress Empowerment 1 and Fortress Empowerment 2</td>
            <td>86 620 485</td>
            <td>7.92</td>
        </tr>
     
        <tr>
            <td>10X</td>
            <td>55 000 000</td>
            <td>5.03</td>
        </tr>
          <tr class="bold bgColor">
            <td>Total</td>
            <td>446 378 286</td>
            <td>40.83</td>
          </tr>
     </tbody>
 </table>

 <table class="smallTable">
     <thead>
          <th style="width: 60%;">Control of more than 5% of issued shares</th>
          <th>Number of shares</th>
          <th>% of issued capital</th>
     </thead>
     <tbody>
        <tr>
            <td>Public Investment Corporation</td>
            <td>134 505 183</td>
            <td>12.30</td>
          </tr>
          <tr>
            <td>Capital Propfund Proprietary Limitied*</td>
            <td>87 536 353</td>
            <td>8.01</td>
          </tr>
          <tr>
            <td>Collectively: Fortress Empowerment 3 and Fortress Empowerment 4</td>
            <td>86 628 959</td>
            <td>7.92</td>
          </tr>
          <tr>
            <td>Collectively: Fortress Empowerment 1 and Fortress Empowerment 2</td>
            <td>86 620 485</td>
            <td>7.92</td>
          </tr>
          <tr>
            <td>Visio Capital Management</td>
            <td>55 925 252</td>
            <td>5.12</td>
        </tr>
          <tr class="bold bgColor">
             <td>Total</td>
             <td>451 216 232</td>
             <td>41.27</td>
          </tr>
     </tbody>
 </table>
 <p>* A subsidiary of Fortress Real Estate Investments </p>
</div>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div>