<div class="banner-container">
   
    <div class="banner-inner">
        <div class="overlay"></div>
        <div class="col-content">
            <div class="col-content-inner">
                <h2>{{title}}</h2>
                <p [innerHTML]="text" ></p>
                <a [routerLink]="[link]" class="primary-btn-arrow">{{linkText}}</a>
            </div>
            
        </div>
        <div class="col-image" [style.background-image]="image">

        </div>
    </div>
</div>
