<div class="container" id="target">
    <div class="filter-col">
        <h2>Previous FOURSights</h2>
        <div class="filter">
            <p class="title">sorted by year</p>
            <p (click)="toggleDate()" class="selectedOption">{{selectedDate}} <img [ngClass]="{'open': !showDates}" src="../../../../../assets/icons/icon-arrow-black.svg" alt=""/></p>
            <div class="categoryHolderDate" *ngIf="showDates">
                <p class="categoryName" *ngFor="let date of dateRange" (click)="selectDate(date.name)">{{date.name}}</p>
            </div>
        </div>
    </div>
    <div class="results-col">
        <div class="search-bar">
            <div class="col">
                <img src="../../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
                <input class="searchbox" type="text" placeholder="Search by keyword" [(ngModel)]="searchText"/>
            </div>
            <div class="col">
                <img src="../../../../../assets/icons/icon-filter.svg" alt=""/>
                <img (click)="clearFilter()" class="borderLeft" src="../../../../../assets/icons/icon-close-black.svg" alt=""/>
            </div>
        </div>
        <div class="content" >
            <div class="block">
                <div class="top" style="background-image: url('../../../assets/images/latest-article.webp');">
                    <p class="topic">Latest webinar</p>
                </div>
                <div class="bottom">
                    <div>
                        <p class="tag">webinar / July 2021</p>
                        <h4>The Property Frontier Revolution Webinar</h4>
                    </div>
                    <a class="clearLink">Watch Here<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <div class="top" style="background-image: url('../../../assets/images/latest-article.webp');">
                    <p class="topic">Latest webinar</p>
                </div>
                <div class="bottom">
                    <div>
                        <p class="tag">webinar / July 2021</p>
                        <h4>The Property Frontier Revolution Webinar</h4>
                    </div>
                    <a class="clearLink">Watch Here<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
        </div>
        <pagination-controls
            previousLabel="Prev"
            nextLabel="Next"
            [responsive]="true"
            (pageChange)="handlePageChange($event)"
        ></pagination-controls>
    </div>
</div>