import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audit-committee',
  templateUrl: './audit-committee.component.html',
  styleUrls: ['./audit-committee.component.scss']
})
export class AuditCommitteeComponent implements OnInit {
  showMore = false;
  constructor() { }

  ngOnInit(): void {
  }

  readMoreHandler() {
    this.showMore = !this.showMore;
  }
}
