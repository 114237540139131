<div class="breadcrumbs-container" [ngClass]="{'large' : isLarge}">
    <a [routerLink]="[grandparentLink]">{{grandparentTitle}}</a>
    <img *ngIf="parentTitle" src="../../../assets/icons/icon-menu-arrow.svg" alt=''/>
    <a *ngIf="!parentLink">{{parentTitle}}</a>
    <a  *ngIf="parentLink" [routerLink]="[parentLink]">{{parentTitle}}</a>
    <img src="../../../assets/icons/icon-menu-arrow.svg" alt=''/>
    <a  *ngIf="anotherLink" [routerLink]="[anotherLink]">{{anotherTitle}}</a>
    <img *ngIf="anotherLink" src="../../../assets/icons/icon-menu-arrow.svg" alt=''/>
    <a class="active">{{childTitle}}</a>
</div>
