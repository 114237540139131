import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-about-banner',
  templateUrl: './about-banner.component.html',
  styleUrls: ['./about-banner.component.scss']
})
export class AboutBannerComponent implements OnInit {
  constructor() { }
  @Output() onClose = new EventEmitter();
  ngOnInit(): void {
  }

  onclicked(){
    this.onClose.emit();
  }

}
