<div class="container">
    <div class="row">
        <h4>Explore similar properties</h4>
        <a class="clearLink" [routerLink]="['/properties/property-portfolio']">See more<img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
    </div>
    
    <div class="content">
        <div class="block" *ngFor="let prop of allProp;let i = index">
            <div class="top" [style.background-image]="getUrl(prop.imageBucketUrl,prop.frontPageImage)">
                <p class="topic">{{prop.province}}</p>
            </div>
            <div class="bottom">
                <div>
                    <p class="tag">{{prop.sector}}</p>
                    <h4>{{prop.buildingName}}</h4>
                    <p class="tag">GLA: {{prop.buildingGla}}</p>
                    <p class="tagSmall">Address:</p>
                    <p class="tagSmall">{{prop.streetAddress}}</p>
                </div>
                <a class="clearLink" [routerLink]="['/properties/indivual-property']" [queryParams]="{ property: prop.id }"  target="_blank">Explore<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>
        </div>
    </div>
</div>