import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-governance',
  templateUrl: './corporate-governance.component.html',
  styleUrls: ['./corporate-governance.component.scss'],
})
export class CorporateGovernanceComponent implements OnInit {
  items = [
    'Corporate governance review',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
  ];
  appBreadcrumbs = {
    grandparentTitle: 'Home',
    grandparentLink: '/',
    parentTitle: 'About us',
    childTitle: 'Corporate governance',
    isLarge: false,
  };
  heroImage: string;
  expandedIndex = 0;

  circleAccent: string;
  constructor() {
    this.heroImage =
      './assets/images/coporate-governance/pineslops-coporate-governance.png';
    this.circleAccent = './assets/icons/circle-accent-full.svg';
  }

  ngOnInit(): void {}
}
