import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  content =[
    {
      year: "2009",
      date: "2009",
      title: "Expanding into new territories.",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis sem sollicitudin suspendisse. In vel suspendisse ut enim, risus urna. Pharetra lacus ac amet, non nam.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"

    },
    {
      year: "2011",
      date: "2011",
      title: "Expanding into new territories.",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis sem sollicitudin suspendisse. In vel suspendisse ut enim, risus urna. Pharetra lacus ac amet, non nam.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"
    },
    {
      year: "2015",
      date: "2015",
      title: "Expanding into new territories.",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis sem sollicitudin suspendisse. In vel suspendisse ut enim, risus urna. Pharetra lacus ac amet, non nam.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"
    },
    {
      year: "2018",
      date: "2018",
      title: "Expanding into new territories.",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis sem sollicitudin suspendisse. In vel suspendisse ut enim, risus urna. Pharetra lacus ac amet, non nam.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"
    },
    {
      year: "2019",
      date: "2019",
      title: "Expanding into new territories.",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis sem sollicitudin suspendisse. In vel suspendisse ut enim, risus urna. Pharetra lacus ac amet, non nam.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"
    },
    {
      year: "2020",
      date: "2020",
      title: "Expanding into new territories.",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed turpis sem sollicitudin suspendisse. In vel suspendisse ut enim, risus urna. Pharetra lacus ac amet, non nam.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"
    },
    {
      year: "2021",
      date: "2021 - TODAY",
      title: "Powering Growth in New Territories",
      text: "Central and Eastern Europe is one of the most promising long-term logistics and warehousing investment propositions globally and Fortress is tapping into this potential. We have bought our first offshore logistic properties in Poland and Romania, evidence of our confidence in the European logistics market’s future prospects. We are also the largest shareholder in NEPI Rockcastle Plc, active in high-growth retail real estate in Central and Eastern Europe.",
      bg: "url('../../../../../assets/images/about-us-history-slider.webp')"
    },
  ];
  count:number =this.content.length-1;
  currentContent =this.content[this.count];


  navSlider(nav:string){
    switch (nav){
      case "+1":  if (this.count === this.content.length-1){
                    this.count = 0;
                  }else{
                    this.count++;
                  }
                  break;
      case "-1": if (this.count === 0){
                    this.count = this.content.length-1;
                  }else{
                    this.count--;
                  }
                  break;                  
    }
    this.currentContent =this.content[this.count];
  }

  navSliderIndex(nav:number){
    this.count = nav;
    this.currentContent =this.content[nav];
  }

}
