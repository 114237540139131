import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Input() title: string = "";
  @Input() text: string = "";
  @Input() subtitle: string = "";
  @Input() embed: string = "";
  @Input() sideVideo: string = "";
  url:string='';
  constructor() { }

  ngOnInit(): void {
    var id = this.getId(this.embed);
    this.url = "https://www.youtube.com/embed/" + id;
  }

  getId(url:string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
}

}
