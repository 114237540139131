import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';  
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss']
})
export class VacanciesComponent implements OnInit {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout() {  
    this.authenticationService.logout();  
    this.router.navigate(['']);  
  }  

}
