import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-easterneurope',
  templateUrl: './easterneurope.component.html',
  styleUrls: ['./easterneurope.component.scss']
})
export class EasterneuropeComponent implements OnInit {


  isImageActive1 = false;
  isImageActive2 = false;
  isImageActive3 = false;
  isImageActive4 = false;
  isImageActive5 = false;
  isImageActive6 = false;

  

  toggleImageClass(pin: number): void {
    if (pin === 1) {
      this.isImageActive1 = !this.isImageActive1;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
    } else if (pin === 2) {
      this.isImageActive2 = !this.isImageActive2;
      this.isImageActive1 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
    } else if (pin === 3) {
      this.isImageActive3 = !this.isImageActive3;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
    } else if (pin === 4) {
      this.isImageActive4 = !this.isImageActive4;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive5 = false;
      this.isImageActive6 = false;
    } else if (pin === 5) {
      this.isImageActive5 = !this.isImageActive5;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive6 = false;
    } else if (pin === 6) {
      this.isImageActive6 = !this.isImageActive6;
      this.isImageActive1 = false;
      this.isImageActive2 = false;
      this.isImageActive3 = false;
      this.isImageActive4 = false;
      this.isImageActive5 = false;
    }
    
  }

  hideImage: boolean = false;

  setAllInactive() {
    this.hideImage = !this.hideImage;
    console.log('image clicked');
  }

  constructor() { }

  ngOnInit(): void {
  }

}
