<div class="filter-btn-container">
  <a
    (click)="filterByCategory('management')"
    [ngClass]="{ active: isCategoryActive('management') }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5 8V16"
        stroke="#00C5CB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 12H8.5"
        stroke="#00C5CB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    Management team
  </a>
  <a
    (click)="filterByCategory('directors')"
    [ngClass]="{ active: isCategoryActive('directors') }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5 8V16"
        stroke="#00C5CB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5 12H8.5"
        stroke="#00C5CB"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    Board of Directors
  </a>
</div>

<div class="container">
  <div class="row desktop">
    <div
      class="block"
      *ngFor="let member of filteredBoard; let i = index"
      [ngClass]="{
        'quote-block': member.isQuote,
        'has-desc': member.desc,
        'no-photo': member.noPhoto
      }"
      (click)="member.desc && openModal(i)"
    >
      <img [src]="member.img" *ngIf="member.img" alt="" />
      <div class="content-container" *ngIf="!member.isQuote">
        <p class="name">{{ member.name }}</p>
        <p class="position">{{ member.position }}</p>
        <svg
          class="arrow-icon"
          *ngIf="member.desc"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10 8L14 12L10 16"
            stroke="#0A2431"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div *ngIf="member.isQuote">
        <div class="quote">
          <svg
            width="19"
            height="13"
            viewBox="0 0 19 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 12.84C3.52667 12.84 2.68 12.48 1.96 11.76C1.25333 11.0267 0.9 10.0533 0.9 8.84C0.9 7.74667 1.14667 6.68667 1.64 5.66C2.14667 4.62 2.8 3.66 3.6 2.78C4.41333 1.9 5.28667 1.14667 6.22 0.52L8.9 2.64C8.67333 2.82667 8.38 3.13333 8.02 3.56C7.66 3.97333 7.30667 4.40667 6.96 4.86C6.61333 5.31333 6.35333 5.68 6.18 5.96C6.76667 6.29333 7.24 6.72667 7.6 7.26C7.96 7.79333 8.14 8.48 8.14 9.32C8.14 10.3733 7.76667 11.2267 7.02 11.88C6.28667 12.52 5.44667 12.84 4.5 12.84ZM14.54 12.84C13.5267 12.84 12.6733 12.48 11.98 11.76C11.2867 11.0267 10.94 10.0533 10.94 8.84C10.94 7.77333 11.1933 6.72 11.7 5.68C12.2067 4.64 12.8667 3.67333 13.68 2.78C14.4933 1.88667 15.3533 1.13333 16.26 0.52L18.94 2.64C18.7 2.85333 18.4 3.16667 18.04 3.58C17.6933 3.98 17.3467 4.4 17 4.84C16.6667 5.28 16.4067 5.65333 16.22 5.96C16.8067 6.29333 17.28 6.72667 17.64 7.26C18 7.79333 18.18 8.48 18.18 9.32C18.18 10.3733 17.8067 11.2267 17.06 11.88C16.3267 12.52 15.4867 12.84 14.54 12.84Z"
              fill="#335D65"
            />
          </svg>
          <p>
            {{ member.quote }}<i>&rdquo;</i>
            <span>{{ member.author }}</span>
            <!-- <svg class="last-quote" width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0.660001C15.9733 0.660001 16.82 1.02 17.54 1.74C18.2467 2.47333 18.6 3.44667 18.6 4.66C18.6 5.75333 18.3533 6.81333 17.86 7.84C17.3533 8.88 16.7 9.84 15.9 10.72C15.0867 11.6 14.2133 12.3533 13.28 12.98L10.6 10.86C10.8267 10.6733 11.12 10.3667 11.48 9.94C11.84 9.52667 12.1933 9.09333 12.54 8.64C12.8867 8.18667 13.1467 7.82 13.32 7.54C12.7333 7.20667 12.26 6.77334 11.9 6.24C11.54 5.70667 11.36 5.02 11.36 4.18C11.36 3.12667 11.7333 2.27333 12.48 1.62C13.2133 0.980001 14.0533 0.660001 15 0.660001ZM4.96 0.660002C5.97333 0.660002 6.82666 1.02 7.52 1.74C8.21333 2.47334 8.56 3.44667 8.56 4.66C8.56 5.72667 8.30667 6.78 7.8 7.82C7.29333 8.86 6.63333 9.82667 5.82 10.72C5.00667 11.6133 4.14667 12.3667 3.24 12.98L0.559998 10.86C0.799999 10.6467 1.1 10.3333 1.46 9.92C1.80667 9.52 2.15333 9.1 2.5 8.66C2.83333 8.22 3.09333 7.84667 3.28 7.54C2.69333 7.20667 2.22 6.77334 1.86 6.24C1.5 5.70667 1.32 5.02 1.32 4.18C1.32 3.12667 1.69333 2.27334 2.44 1.62C3.17333 0.980002 4.01333 0.660002 4.96 0.660002Z" fill="#335D65"/>
                        </svg> -->
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row mobile">
    <div class="slider-container">
      <ngx-slick-carousel
        class="team-carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (afterChange)="afterChange($event)"
      >
        <div
          class="block"
          [ngClass]="{ 'quote-block': member.isQuote }"
          ngxSlickItem
          *ngFor="let member of filteredBoard; let i = index"
          (click)="member.desc && openModal(i)"
        >
          <img [src]="member.img" alt="" />
          <div class="content-container" *ngIf="!member.isQuote">
            <p class="name">{{ member.name }}</p>
            <p class="position">{{ member.position }}</p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>

    <div class="mobile-quote">
      <div class="quote">
        <svg
          width="19"
          height="13"
          viewBox="0 0 19 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.5 12.84C3.52667 12.84 2.68 12.48 1.96 11.76C1.25333 11.0267 0.9 10.0533 0.9 8.84C0.9 7.74667 1.14667 6.68667 1.64 5.66C2.14667 4.62 2.8 3.66 3.6 2.78C4.41333 1.9 5.28667 1.14667 6.22 0.52L8.9 2.64C8.67333 2.82667 8.38 3.13333 8.02 3.56C7.66 3.97333 7.30667 4.40667 6.96 4.86C6.61333 5.31333 6.35333 5.68 6.18 5.96C6.76667 6.29333 7.24 6.72667 7.6 7.26C7.96 7.79333 8.14 8.48 8.14 9.32C8.14 10.3733 7.76667 11.2267 7.02 11.88C6.28667 12.52 5.44667 12.84 4.5 12.84ZM14.54 12.84C13.5267 12.84 12.6733 12.48 11.98 11.76C11.2867 11.0267 10.94 10.0533 10.94 8.84C10.94 7.77333 11.1933 6.72 11.7 5.68C12.2067 4.64 12.8667 3.67333 13.68 2.78C14.4933 1.88667 15.3533 1.13333 16.26 0.52L18.94 2.64C18.7 2.85333 18.4 3.16667 18.04 3.58C17.6933 3.98 17.3467 4.4 17 4.84C16.6667 5.28 16.4067 5.65333 16.22 5.96C16.8067 6.29333 17.28 6.72667 17.64 7.26C18 7.79333 18.18 8.48 18.18 9.32C18.18 10.3733 17.8067 11.2267 17.06 11.88C16.3267 12.52 15.4867 12.84 14.54 12.84Z"
            fill="#335D65"
          />
        </svg>
        <p>
          Our greatest asset is the relationships that we build with our tenants
          and the communities in which we operate to ensure we grow together. By
          empowering our people, we are powering growth in South Africa.<i
            >&rdquo;</i
          >
          <span>Steven Brown, CEO</span>
        </p>
      </div>
    </div>
  </div>

  <div id="myModal" class="modal" *ngIf="modalOpen">
    <div class="modal-desktop">
      <div class="close" (click)="closeModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20 4L4 20"
            stroke="#00C5CB"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4 4L20 20"
            stroke="#00C5CB"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="content">
        <div class="col">
          <img [src]="currentMember.img" alt="" />
        </div>
        <div class="col">
          <p class="title">{{ currentMember.name }}</p>
          <p class="title position">{{ currentMember.position }}</p>
          <p class="title-small">{{ currentMember.quali }}</p>
          <p class="title-small date">{{ currentMember.date }}</p>
          <p class="text">{{ currentMember.desc }}</p>
        </div>
      </div>
    </div>
    <!--modal-desktop ends-->
    <div class="modal-mobile">
      <div class="close" (click)="closeModal()">
        <img src="../../../../../assets/icons/modal-close.svg" />
      </div>
      <div class="content">
        <div class="col">
          <img [src]="currentMember.img" alt="" />
        </div>
        <div class="col">
          <p class="title">{{ currentMember.name }}</p>
          <p class="title position">{{ currentMember.position }}</p>
          <p class="title-small">{{ currentMember.quali }}</p>
          <p class="title-small">{{ currentMember.date }}</p>
          <p class="text">{{ currentMember.desc }}</p>
        </div>
      </div>
    </div>
    <!--modal-mobile ends-->
  </div>
</div>
