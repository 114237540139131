import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footprint',
  templateUrl: './footprint.component.html',
  styleUrls: ['./footprint.component.scss']
})
export class FootprintComponent implements OnInit {
  saActive:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  toggleCountry(country:string){
    switch (country){
      case 'SA' : this.saActive = true;
                  break;
      case 'EE' : this.saActive = false;
                  break;
    }
  }
}
