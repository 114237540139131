import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  searchText:string='';
  document: string = '';
 categories=[
    {
      name: "All",
    },
    {
      name: "Logistics",
    },
    {
      name: "Office",
    },
    {
      name: "Retail",
    },
    {
      name: "Industrial",
    },
    {
      name: "Others",
    }
];

stats=[
  {
    name:"Number of completed buildings (28 buildings sold)",
    bg:false,
    img:"../../../../../assets/images/stats/stat1.svg",
    category:"Office"
  },
  {
    name:"Value of new logistics developments",
    bg:false,
    img:"../../../../../assets/images/stats/stat2.svg",
    category:"Office"
  },
  {
    name:"Gross lettable area (GLA)",
    bg:false,
    img:"../../../../../assets/images/stats/stat3.svg",
    category:"Office"
  },
  {
    name:"Total weighted average in-force escalations (as at 30 June 2023)",
    bg:false,
    img:"../../../../../assets/images/stats/stat4.svg",
    category:"Office"
  },
 
  {
    name:"Total vacancy by GLA",
    bg:false,
    img:"../../../../../assets/images/stats/stat5.svg",
    category:"Office"
  },
  {
    name:"Total reversions",
    bg:false,
    img:"../../../../../assets/images/stats/stat6.svg",
    category:"Office"
  },
  {
    name:"LTV",
    bg:false,
    img:"../../../../../assets/images/stats/stat7.svg",
    category:"Office"
  },
  {
    name:"Pipeline of development projects",
    bg:false,
    img:"../../../../../assets/images/stats/stat8.svg",
    category:"Office"
  },
  {
    name:"Total weighted average lease expiry (WALE)",
    bg:false,
    img:"../../../../../assets/images/stats/stat9.svg",
    category:"Office"
  }
]


toggleClass: boolean = false;
showCategories:boolean=true;
activeCat:number= 0;

currentIndex = -1;
title = '';
page = 1;
count = 0;
pageSize = 24;
pageSizes = [24, 24, 24];

  constructor() { 

  }

  ngOnInit(): void {
  }

  handlePageChange(event: number): void {
    this.page = event;
  }

  toggleCategory(){
    this.showCategories =!this.showCategories;
  }


  selectCategory(index:number){
    this.activeCat = index;
    switch (index){
      case 0:
              this.searchText="";
              break;
      case 1:
              this.searchText="Logistics";
              break;
      case 2:
              this.searchText="Office";
              break;
      case 3:
              this.searchText="Retail";
              break;
      case 4:
              this.searchText="Industrial";
              break;
      case 5:
              this.searchText="Others";
              break;
    }
  }

  
}
