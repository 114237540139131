<div class="bg">
  <div class="container">
    <!-- <img src="../../../../assets/icons/icon-arrow-down.webp" alt=""/> -->
    <app-breadcrumbs
      grandparentTitle="HOME"
      grandparentLink="/"
      parentTitle="SUSTAINABILITY"
      childTitle="SOCIAL"
      [isLarge]="false"
    ></app-breadcrumbs>

    <div class="hero">
      <div class="col">
        <p class="overline">Our impact</p>
        <h1>Supporting Social</h1>
        <p>
          Fortress is committed to purposeful and active corporate citizenship.
          We have an established programme of supporting several community-based
          and charitable organisations in the areas in which our properties are
          located to uplift those communities with solution initiatives that
          result in long-term benefits.
        </p>
        <a
          class="secondary-btn"
          href="https://assets.ctfassets.net/7unabnt9bnbr/3vQLgDCs1FCeWX1wR5oSzt/167b7671717799a085dd81c0f394007c/Integrated_report_30_June_2023__website_.pdf#page=70"
          target="_blank"
          >View 2023 Report</a
        >
      </div>
    </div>

    <div class="mobile-hero-img">
      <img src="../../../../assets/images/sus-social-hero.png" />
    </div>

    <div class="our-approach-container">
      <div class="left-content">
        <h4>Our approach</h4>
        <p>
          We are committed to operating a sustainable business aligned with the
          UN Sustainable Development Goals (“SDGs”) driven by the our
          environmental goals and themes; Powering Growth and Acting on Climate.
        </p>

        <div class="tab-content">
          <ngx-slick-carousel
            class="social-our-approach-carousel"
            [config]="slideConfig"
            (afterChange)="afterChange1($event)"
          >
            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>No poverty</h3>
                <p class="overline">Our impact</p>
                <p>End poverty in all its forms everywhere.</p>
                <p class="overline">Fortress' contribution</p>
                <p>
                  Our supplier and enterprise development programmes help
                  sustain existing jobs and create new employment opportunities.
                </p>
                <p class="overline">Partners</p>
                <p>Property Point and Alex Works.</p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>Zero hunger</h3>
                <p class="overline">Our impact</p>
                <p>
                  End hunger, achieve food security and improved nutrition and
                  promote sustainable agriculture.
                </p>
                <p class="overline">Fortress' contribution</p>
                <p>
                  Through our partnership with the FTFA, we help schools and
                  communities with skills transfers and empowerment, as well as
                  immediate short-term nutritional needs.
                </p>
                <p class="overline">Partners</p>
                <p>FTFA, Rays of Hope, Food Forward SA and Food for Life.</p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>Quality education</h3>
                <p class="overline">Our impact</p>
                <p>
                  Ensure inclusive and equitable quality education and promote
                  lifelong learning opportunities for all.
                </p>
                <p class="overline">Fortress' contribution</p>
                <p>
                  We fund various educational initiatives, from early childhood
                  development to bursaries for tertiary education. We are also
                  the lead sponsor of the Anchor Stockbrokers internship
                  programme, with interns on rotation at Broll.
                </p>
                <p class="overline">Partners</p>
                <p>
                  SAIBPP, Anchor Stockbrokers, SAPOA Bursary, Sparrow FET
                  Enterprises, Sandton Primary, Rays of Hope, Shine, Kids Haven,
                  The President's Award and Growing Champions.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>Gender equality</h3>
                <p class="overline">Our impact</p>
                <p>Achieve gender equality and empower all women and girls.</p>
                <p class="overline">Fortress' contribution</p>
                <p>
                  We have a number of women-owned businesses in our supplier and
                  enterprise development programmes. We also provide space for
                  two charities that support and assist abused women and their
                  children. We also fund a human capital support initiative.
                </p>
                <p class="overline">Partners</p>
                <p>
                  Property Point, Anchor Stockbrokers, Growing Champions,
                  Lifeline Vaal Triangle, Property Sector Charter Council and
                  SOZO.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>Decent work and economic growth</h3>
                <p class="overline">Our impact</p>
                <p>
                  Promote inclusive and sustainable economic growth, full and
                  productive employment and decent work for all.
                </p>
                <p class="overline">Fortress' contribution</p>
                <p>
                  Our supplier and enterprise development programmes contribute
                  to wider changes including contribution to the GDP,
                  employment, taxes and household income.
                </p>
                <p class="overline">Partners</p>
                <p>
                  Property Point, Property Sector Charter Council and Alex
                  Works.
                </p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>Responsible consumption and production</h3>
                <p class="overline">Our impact</p>
                <p>Ensure sustainable consumption and production patterns.</p>
                <p class="overline">Fortress' contribution</p>
                <p>
                  To reduce food waste, we are working with an independent
                  company that sources, collects and redistributes edible
                  surplus food. We partner on initiatives that facilitate
                  recycling and clean-up operations.
                </p>
                <p class="overline">Partners</p>
                <p>Hennops Revival NPO, Value Waste and Food Forward SA.</p>
              </div>
            </div>

            <div class="block" ngxSlickItem>
              <div class="content-container">
                <h3>Climate action</h3>
                <p class="overline">Our impact</p>
                <p>
                  Take urgent action to combat climate change and its impacts.
                </p>
                <p class="overline">Fortress' contribution</p>
                <p>All our initiatives foster awareness of the environment.</p>
                <p class="overline">Partners</p>
                <p>FTFA and Hennops Revival NPO.</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>

      <div class="right-content">
        <img [src]="slide1IconImages[activeSlide1Index]" class="sus-icon" />
        <img src="../../../../assets/images/sus-social-approach-img.png" />
      </div>
    </div>

    <div class="measurable-impacts-container">
      <h4>Measurable impacts</h4>

      <div class="row">
        <div class="col">
          <img
            src="../../../../assets/images/measurable-impacts-graph.svg"
            class="graph-img"
          />
          <div class="legend">
            <p class="legend-title">Contribution percentage</p>
            <p>
              <span>1%</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="9.99805" cy="10" r="10" fill="#086E94" />
              </svg>
              Charitable donations
            </p>
            <p>
              <span>69%</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="9.99805" cy="10" r="10" fill="#00C5CB" />
              </svg>
              Community investment
            </p>
            <p>
              <span>30%</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="9.99805" cy="10" r="10" fill="#009EA2" />
              </svg>
              Commercial initiatives
            </p>
          </div>
        </div>
        <div class="col">
          <div class="stat">
            <p class="large-number">R18,4 m</p>
            <p class="text">Contribution spend</p>
          </div>
          <div class="stat">
            <p class="large-number">R5,6 m</p>
            <p class="text">External corporate development impact</p>
          </div>
          <div class="stat">
            <p class="large-number">R11,0 m</p>
            <p class="text">On all skills initiatives</p>
          </div>
        </div>
      </div>
    </div>

    <div class="collaboration-container">
      <div class="col">
        <h4>Power of collaboration</h4>
        <p>
          Our goal is to drive real social change by partnering with leading
          organisations that deliver tailored solutions to our communities.
          Special thanks to all our partners who have helped us with
          long-lasting initiatives.
        </p>
        <p>
          We followed a multipronged approach towards skills development. We
          sponsored educational initiatives that assisted 657 children and young
          adults. Our enterprise development partnerships support owners of
          small businesses, some of whom have subsequently assisted on our
          community projects.
        </p>
      </div>
      <div class="col">
        <img src="../../../../assets/images/social-collaboration-img.png" />
      </div>
    </div>

    <div class="our-partner-container">
      <div class="inner">
        <h4>Our partners</h4>
        <div class="partner-logos">
          <img src="../../../../assets/images/partner-logos/logo-1.png" />
          <img src="../../../../assets/images/partner-logos/logo-2.png" />
          <img src="../../../../assets/images/partner-logos/logo-3.png" />
          <img src="../../../../assets/images/partner-logos/logo-4.png" />
          <img src="../../../../assets/images/partner-logos/logo-5.png" />
          <img src="../../../../assets/images/partner-logos/logo-6.png" />
          <img src="../../../../assets/images/partner-logos/logo-7.png" />
          <img src="../../../../assets/images/partner-logos/logo-8.png" />
          <img src="../../../../assets/images/partner-logos/logo-9.png" />
          <img src="../../../../assets/images/partner-logos/logo-10.png" />
          <img src="../../../../assets/images/partner-logos/logo-11.png" />
          <img src="../../../../assets/images/partner-logos/logo-12.png" />
          <img src="../../../../assets/images/partner-logos/logo-13.png" />
          <img src="../../../../assets/images/partner-logos/logo-14.png" />
        </div>

        <div class="partner-logos-mobile">
          <ngx-slick-carousel
            class="sus-social-partners-carousel"
            [config]="slideConfigMobile"
            (afterChange)="afterChange($event)"
          >
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-1.png" />
              <img src="../../../../assets/images/partner-logos/logo-2.png" />
            </div>
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-3.png" />
              <img src="../../../../assets/images/partner-logos/logo-4.png" />
            </div>
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-5.png" />
              <img src="../../../../assets/images/partner-logos/logo-6.png" />
            </div>
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-7.png" />
              <img src="../../../../assets/images/partner-logos/logo-8.png" />
            </div>
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-9.png" />
              <img src="../../../../assets/images/partner-logos/logo-10.png" />
            </div>
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-11.png" />
              <img src="../../../../assets/images/partner-logos/logo-12.png" />
            </div>
            <div class="partner-logo-slide" ngxSlickItem>
              <img src="../../../../assets/images/partner-logos/logo-13.png" />
              <img src="../../../../assets/images/partner-logos/logo-14.png" />
            </div>
          </ngx-slick-carousel>
        </div>

        <div class="content">
          <div class="left-content">
            <p>
             We value our partnership with the FTFA, a leading non-profit company that helped implement sustainable and scalable community programmes.
            </p>
            <p class="small-text">
              FTFA programmes use environmentally sustainable food security and planting techniques, such as permaculture and high-density orchard culture, to improve nutrition in under-resourced communities while fostering environmental awareness and systems thinking. The programmes supply resources such as seeds, herbs, trees, gardening tools, educational material and mentorship to help communities become self-sufficient and food-secure.
            </p>
            <div class="btn-container">
              <a
              class="secondary-btn"
              [routerLink]="['/investor-relations/investor-documents']"
              [queryParams]="{ document: 'annual-reports' }"
              >View More</a
            >
            </div>
            
          </div>

          <div class="right-content">
            <div class="row-headings full">
              <p>Since partnership began in February 2019:</p>
            </div>

            <div class="card-container">
              <div class="card">
                <h5>19 137</h5>
                <p>Total trees</p>
              </div>

              <div class="card">
                <h5>26</h5>
                <p>Long-term food gardens</p>
              </div>

              <div class="card">
                <h5>3</h5>
                <p>Enterprise orchards</p>
              </div>

              <div class="card">
                <h5>7 062</h5>
                <p>Carbon offset</p>
              </div>

              <div class="card">
                <h5>321</h5>
                <p>Total workshops</p>
              </div>

              <div class="card">
                <h5>493</h5>
                <p>Total trainees</p>
              </div>

              <div class="card">
                <h5>7 050</h5>
                <p>House reached</p>
              </div>

              <div class="card">
                <h5>20</h5>
                <p>Schools reached</p>
              </div>

              <div class="card">
                <h5>137</h5>
                <p>Beneficiary organisation</p>
              </div>

              <div class="card">
                <h5>39 962</h5>
                <p>Direct beneficiaries</p>
              </div>
            </div>

            <ngx-slick-carousel
              class="sus-social-partners-carousel"
              [config]="slideConfigMobile2"
              (afterChange)="afterChange($event)"
            >
              <div class="slide" ngxSlickItem>
                <div class="card-container-mobile">
                  <div class="card-mobile">
                    <h5>19 137</h5>
                    <p>Total trees</p>
                  </div>
                  <div class="card-mobile">
                    <h5>26</h5>
                    <p>Long-term food gardens</p>
                  </div>
                  <div class="card-mobile">
                    <h5>3</h5>
                    <p>Enterprise orchards</p>
                  </div>
                  <div class="card-mobile">
                    <h5>7 062</h5>
                    <p>Carbon offset</p>
                  </div>
                </div>
              </div>

              <div class="slide" ngxSlickItem>
                <div class="card-container-mobile">
                  <div class="card-mobile">
                    <h5>321</h5>
                    <p>Total workshops</p>
                  </div>
                  <div class="card-mobile">
                    <h5>493</h5>
                    <p>Total trainees</p>
                  </div>
                  <div class="card-mobile">
                    <h5>7 050</h5>
                    <p>House reached</p>
                  </div>
                  <div class="card-mobile">
                    <h5>20</h5>
                    <p>Schools reached</p>
                  </div>
                </div>
              </div>

              <div class="slide" ngxSlickItem>
                <div class="card-container-mobile">
                  <div class="card-mobile">
                    <h5>137</h5>
                    <p>Beneficiary organisation</p>
                  </div>
                  <div class="card-mobile">
                    <h5>39 962</h5>
                    <p>Direct beneficiaries</p>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-foursight-news [whiteNav]="true"></app-foursight-news>

  <div class="impact-report">
    <div class="col">
      <p class="overline">2023</p>
      <h4>Our Impact report</h4>
      <p>
        We strive to better understand what matters - not only with regard to
        our creation and protection of enterprise value - but also with respect
        to how we enhance and protect our societal and environmental impact.
      </p>
    </div>
    <div class="col">
      <a
        class="primary-btn"
        [routerLink]="['/investor-relations/investor-documents']"
        [queryParams]="{ document: 'annual-reports' }"
        >View Report</a
      >
    </div>
  </div>

  <!-- <app-get-in-touch-banner></app-get-in-touch-banner> -->
</div>
