<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="About us" childTitle="Privacy Notice">
    </app-breadcrumbs>

    <app-banner-foursight title="Privacy Policy" link="" linkText="" image="url(../../../assets/images/whiteriver.webp)"
    imageAlone="../../../assets/images/whiteriver.webp"
        text="" subTitle=""></app-banner-foursight>

    <div class="container">
        <div class="row">
            <div id="pn-content">
                
                <h3>Privacy Policy</h3>
               
                <h4> A. DEFINITIONS</h4>
                <p><strong>"Data"</strong>means any data (including Personal Information) provided to Fortress by a Data Subject; </p>
                <p><strong>"Data Subject"</strong> has the meaning given in POPIA and includes a natural person and juristic person (companies);</p>
                <p><strong>"Fortress"</strong>,<strong>"us"</strong> or <strong>"we"</strong> means Fortress Real Estate Investments , a public company incorporated and registered in South Africa with registration number 2009/016487/06and its related companies, subsidiaries and/or parent companies, sponsors, affiliates and creators;</p>
                <p><strong>"Information Regulator"</strong> means the supervisory authority in South Africa, established in terms of POPIA; </p>
                <p><strong>"Operator"</strong> means a person who Processes Personal Information for a Responsible Party in terms of a contract or mandate, without coming under the direct authority of that party; </p>
                <p><strong>"Process"</strong>,<strong>"Processing"</strong> or <strong>"Processed"</strong> means any operation or activity or any set of operations, whether or not by automatic means, concerning Personal Information, including- 
                    <ul>
                        <li>the collection, receipt, recording, organisation, collation, storage, updating or modification, retrieval, alteration, consultation or use;</li>
                        <li>dissemination by means of transmission, distribution or making available in any other form; or</li>
                        <li>merging, linking, as well as restriction, degradation, erasure or destruction of information;</li>
                    </ul>
                <p><strong>"Personal Information"</strong> means information relating to an identifiable, living, natural person, and where it is applicable, an identifiable, existing juristic person, including, but not limited to- </p>
                <ul>
                    <li>information relating to the race, gender, sex, pregnancy, marital status, national, ethnic or social origin, colour, sexual orientation, age, physical or mental health, well-being, disability, religion, conscience, belief, culture, language and birth of the person;</li>
                    <li>information relating to the education or the medical, financial, criminal or employment history of the person;</li>
                    <li>any identifying number, symbol, e-mail address, physical address, telephone number, location information, online identifier or other particular assignment to the person;</li>
                    <li>the biometric information of the person;</li>
                    <li>the personal opinions, views or preferences of the person;</li>
                    <li>correspondence sent by the person that is implicitly or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence;</li>
                    <li>·the views or opinions of another individual about the person; and</li>
                    <li>the name of the person if it appears with other personal information relating to the person or if the disclosure of the name itself would reveal information about the person;</li>
                </ul>
                <p><strong>"POPIA"</strong> means the Protection of Personal Information Act, 2013, as amended, consolidated or replaced from time to time; </p>
                <p><strong>"Responsible Party"</strong> means a public or private body or any other person which, alone or in conjunction with others, determines the purpose of and means for Processing Personal Information; </p>
                <p><strong>"Special Personal information"</strong> means Personal information concerning-</p>
                <ul>
                    <li>the religious or philosophical beliefs, race or ethnic origin, trade union membership, political persuasion, health or sex life or biometric information of a Data Subject; and</li>
                    <li>the criminal behavior of a Data Subject to the extent that such information relates to-</li>
                    <li>the alleged commission by a Data Subject of any offence; or</li>
                    <li>any proceedings in respect of any offence allegedly committed by a Data Subject or the disposal of such proceedings; and</li>
                </ul>
                
                
                <p> <strong>"User"</strong> means a person who uses and/or accesses computer software and/or material via the Fortress website.</p>
               
                <h4>B. PURPOSE</h4>
                
                <p>The purpose of this Policy is, <em>inter alia</em>, to:</p>
                <ul>
                    <li>regulate the manner in which Personal Information is dealt with by all persons and entities which Process Personal Information for or on behalf of Fortress;</li>
                    <li>prescribe rules for the Processing of Personal Information;</li>
                    <li>ensure that Fortress, as the responsible party determining the purpose of and means for Processing Personal Information, must comply with the rules for lawful Processing of Personal Information as set out in this Policy;</li>
                    <li>enable Fortress to monitor user activities for compliance with privacy legislation ensuring that Personal Information is processed in line with all applicable data privacy laws and regulations; and</li>
                    <li>to ensure that the Personal Information of the data subjects is Processed by Fortress in accordance with POPIA. </li>
                </ul>
                
                <h4>C. SCOPE</h4>
                <p>This Policy applies to any person Processing Personal Information on behalf of Fortress and includes <em>inter alia</em>:</p>
                <ul>
                    <li>Employees;</li>
                    <li>Contractors; and</li>
                    <li>representatives of Fortress. </li>
                </ul>
                
                
                    <h4>D. COLLECTION OF PERSONAL INFORMATION</h4>
                
                <h5>a. Users</h5>
                <p>Fortress processes Personal Information that a User provides to it directly or that it learns about a User from the User's use of Fortress systems and Fortress communications and through other dealings with Users.</p>
                <p>If a User registers with Fortress by completing a submission form, including but not limited to a newsletter registration form, an information enquiry form or a registration to gain access to a password protected zone, a User is not anonymous to Fortress.</p>
                <p>The types of Personal information that Fortress collects and processes about Users may include the following:</p>
                <ul>
                    <li>User's name</li>
                    <li>date of birth</li>
                    <li>nationality</li>
                    <li>title</li>
                    <li>contact details</li>
                    <li>other information required for Know Your Customer (KYC), anti- money laundering and/or sanctions checking purposes</li>
                    <li>information collected from international sanctions lists, and</li>
                    <li>information that is publicly available on websites, databases and from other public data sources.</li>
                </ul>
                
                <p>Fortress may obtain a User's Personal Information when the User provides it to Fortress or in circumstances where the User has made the information public, including via social media. Fortress may collect or obtain a User's Personal Information when the User visits or uses Fortress’s website or via social media.</p>
                <p>Information collected by Fortress is stored in a secure operating environment that is not available to the public.</p>
                <p>Users provide the information requested by Fortress voluntarily, therefore if a User does not wish to submit any personal identifying information, the User should not submit any information within ‘submission form’ fields on the Fortress website. However, should a User fail to provide the information Fortress reasonably requires, Fortress may be unable to offer its services to the User and/or Fortress may terminate the supply of any of its services provided with immediate effect.</p>
                <p>Fortress does not have any motive or intention to gather contact Data of visitors to its website or physical premises who are under the age of 18 and would prefer that the consent of a parent or another competent person be supplied on behalf of a child under the age of 18.</p>
                <p>It is the nature of the Internet, websites and social media that Fortress, by default, records User information on Fortress's server traffic reports from the browser which the User is using to view Fortress, including an IP address and which web pages were visited.</p>
                <p>Fortress uses cookie technology. Cookie technology consists of trace elements of data that is stored on the User's computer's hard drive, and which contains information about the User, so that next time the User visits the Fortress website, the cookie recognises the User's prior visit.</p>
                <h5>b.Employees</h5>
                <p>The types of Personal information that Fortress collects and processes about employees may include the following:</p>
                <ul>
                    <li>employee names;</li>
                    <li>identity numbers;</li>
                    <li>date of birth;</li>
                    <li>contact details including telephone numbers, physical addresses, email addresses;</li>
                    <li>employee records;</li>
                    <li>photographs and/ or video images of employees;</li>
                    <li>race/ ethnic origin;</li>
                    <li>bank account details;</li>
                    <li>employee share scheme details; and</li>
                    <li>data disclosing health, medical information and/ or disabilities in respect of employees.</li>
                </ul>
                
                <h5>c. Directors, shareholders, sureties, tenants, brokers,
                        contractors and service providers and other Data
                        Subjects</h5>
                <p>The types of Personal information that Fortress collects and
                    processes about directors, shareholders, sureties, tenants,
                    brokers, contractors and service providers and other Data Subjects
                    may include the following:</p>
                    <ul>
                        <li>Names and surnames;</li>
                        <li>Identification numbers;</li>
                        <li>·Date of birth;</li>
                        <li>·Company names;</li>
                        <li>Registration numbers;</li>
                        <li>Contact details including telephone numbers, physical addresses and email addresses;</li>
                        <li>·Domicilia;</li>
                        <li>·VAT registration details;</li>
                        <li>Criminal records;</li>
                        <li>CVs, educational history and qualifications;</li>
                        <li>Credit information;</li>
                        <li>Information relating to the shareholder register;</li>
                        <li>Race</li>
                        <li>Gender; and</li>
                        <li>Bank account details.</li>
                    </ul>
                <p>Save for Personal Information that Fortress is required or
                    permitted by law to collect, Personal Information is generally
                    collected from the Data Subjects and the Data Subjects provide the
                    information requested by Fortress voluntarily. However, should a
                    Data Subject fail to provide the information Fortress reasonably
                    requires or withdraw consent previously given, Fortress may not be
                    able to effectively manage its relationship with the Data
                    Subject.</p>
               
                <h4> USE OF PERSONAL INFORMATION</h4>
                <p>Fortress processes Personal Information on the basis of lawful
                    justification, for example, the Personal Information being publicly
                    available, on the basis of the consent of the Data Subject, in
                    pursuit of its, or its Data Subject’s legitimate interests,
                    for the purposes of concluding or performing a contract, or on the
                    basis of a legal obligation or public law duty.</p>
               <br/><br/>
                <p>
                    <strong>Fortress processes Personal Information of Users for
                        purposes including:</strong>
                </p>

                <ul>
                    <li>to manage, administer and improve our business and customer and service provider engagements and relationships, for corporate marketing, business development and analysis purposes and to operate control and management information systems;</li>
                    <li>to monitor and analyze the use of our services and for system administration, operation, testing and support purposes;</li>
                    <li>to manage our information technology and to ensure the security of our systems;</li>
                    <li>to establish, exercise and/or defend legal claims or rights and to protect, exercise and enforce our rights, to protect property or safety, or to assist our clients or others to do this;</li>
                    <li>to investigate, respond to and deal with complaints or incidents relating to us or our business, to maintain service quality and to train employees;</li>
                    <li>to cooperate with, respond to requests from, and to report transactions and/or other activity to, government, tax or regulatory bodies, or other intermediaries or counterparties, courts or other third parties;</li>
                    <li>to monitor and analyze the use of our products and services for risk assessment and control purposes (including detection, prevention and investigation of fraud);</li>
                    <li>to conduct compliance activities such as audit and reporting, assessing and managing risk, maintenance of accounting and tax records, fraud and anti-money</li>
                    
                </ul>
                
                <p>Users of Fortress’s website understand and agree
                    that in order to offer certain services or functions, Fortress
                    must collect and use personal information about users.</p>
                <p>Fortress may send to a User messages by email and/or SMS and/or
                    WhatsApp regarding their account.</p>
                <p>It is an optional condition of a User submitting Data
                    to Fortress that Users agree to receive future promotional
                    messages from Fortress by email and/or SMS and/or WhatsApp or
                    to opt-out from future communications. A User can choose to opt-out
                    of promotional messages at any time by contacting Fortress or
                    following the unsubscribe link or instructions on the future
                    communication.</p>
                <p>Fortress uses cookie technology and generates and saves User and
                    session IDs to the User's Internet browser and computer to track
                    and analyse website usage of the User. Fortress does this to
                    be able to identify any technical problems with the workings
                    of Fortress and to correct these to ensure a better online
                    experience for the User.</p>
                    <br/><br/>
                <p>
                    <strong>Fortress processes Personal Information of employees for
                        purposes including:</strong>
                </p>
                <ul>
                    <li>to manage and administer the employment relationship with Fortress's employees;</li>
                    <li>to execute monitoring and training;</li>
                    <li>for employment equity purposes;</li>
                    <li>to manage employee health and wellness;</li>
                    <li>to enable Fortress to conduct SANS controls for security purposes including for theft prevention, for the prevention of security breaches, to prevent unauthorized access and to investigate suspicious activity;</li>
                    <li>to provide employee benefits and pension/provident fund administration; and</li>
                    <li>to comply with Fortress's legal and regulatory obligations.</li>
                </ul>
                <br/>
                <p>
                    <strong>Fortress processes Personal information of directors,
                        shareholders, sureties, tenants, brokers, contractors and service
                        providers for purposes including:</strong>
                </p>
                <ul>
                    <li>to comply with legislative requirements (for example broad-based black economic empowerment and employment equity);</li>
                    <li>to comply with the JSE listing requirements</li>
                    <li>reporting to relevant government departments for legislative purposes;</li>
                    <li>fraud prevention and detection;</li>
                    <li>·credit vetting;</li>
                    <li>to carry out communication, service, billing and administration;</li>
                    <li>to market Fortress's services by means of newsletters, know-how, promotional material and other marketing communications;</li>
                    <li>to contact third parties in relation to current, future and proposed engagements including to invite them to events (and arrange and administer those events);</li>
                    <li>to conduct analyses in respect of transactional viability;</li>
                    <li>for bank valuation purposes in respect of tenants;</li>
                    <li>for billing purposes and payment of invoices;</li>
                    <li>to obtain and update credit information with appropriate third parties, such as credit reporting agencies; and</li>
                    <li>to conduct processing necessary to fulfill other contractual obligations.</li>
                </ul>
                
                <h4>F. AUTHORISED ACCESS TO DATA</h4>
                <p>Fortress may disclose information to any authorized official if
                    required to do so by law or if it is required to protect the safety
                    of other Fortress Users or the public, or if Fortress
                    believes that such action is necessary to protect and
                    defend Fortress rights or properties, or for any other purpose
                    recognized in law.</p>
                <p>Fortress will not sell, rent, share, or distribute Personal
                    Information to any entity other than third parties which are vital
                    in the administration of Fortress and the supply of goods or
                    services that have been requested by the User, including but not
                    limited to: hosting service providers, web administrators and
                    online purchase and delivery service providers.</p>
                <p>Fortress may need to share Data with employees and employees of
                    third parties that require access to the Data to perform their
                    day-to-day functions for Fortress.</p>
                <p>Fortress may disclose anonymous demographic and biometric
                    statistics about the Data within the database for business
                    purposes.</p>
                <p>In the event of Fortress changing ownership to a new
                    entity, Fortress may transfer its rights to the Data to the
                    new owner. Subject to applicable laws, the new owner
                    of Fortress is required to notify each Data Subject of the
                    transfer and the Data Subject may choose to modify its Personal
                    Information at that time or choose to remove it.</p>
               
                <h4>G. RECIPIENTS OF PERSONAL INFORMATION</h4>
                <p>From time to time Fortress may need to share Data
                    Subjects’ Personal Information with the following
                    recipients:</p>
                    <ul>
                        <li>suppliers and service providers;</li>
                        <li>the Johannesburg Stock Exchange;</li>
                        <li>third-party applications or platforms;</li>
                        <li>companies within the Fortress group of companies;</li>
                        <li>government departments;</li>
                        <li>the Competition Commission;</li>
                        <li>the BEE Commission;</li>
                        <li>the South African Revenue Service;</li>
                        <li>Financial institutions;</li>
                        <li>auditors;</li>
                        <li>industry bodies;</li>
                        <li>property managers;</li>
                        <li>attorneys, advocates and other legal advisors.</li>
                    </ul>
                
                <h4>H. TRANSFER OF PERSONAL INFORMATION</h4>
                <p>From time to time Fortress may need to share Data
                    Subjects’ Personal Information with members of the Fortress
                    group that may be based outside South Africa. Fortress may also
                    allow its third party service providers, that may be located
                    outside South Africa, to access Data Subjects' Personal
                    Information. Fortress may also make other disclosures of Data
                    Subjects' Personal Information to countries outside South Africa,
                    for example in the event of a legal or regulatory request from a
                    foreign law enforcement body.</p>
                <p>Fortress will take steps to ensure that any international
                    transfer of Personal Information is carefully managed to protect
                    Data Subjects' rights and interests. In this regard, Fortress will
                    only transfer Personal Information to countries which are
                    recognised as providing an adequate level of data protection
                    similar to that of POPIA or where Fortress is satisfied that there
                    is a justification under POPIA for the transfer.</p>
                <p>To the extent that Fortress transfers any Special Personal
                    Information or Personal Information relating to children to third
                    parties in foreign countries that do not provide an adequate level
                    of data protection, Fortress shall obtain the requisite prior
                    authorisation of the Information Regulator, in circumstances where
                    required.</p>
               
                <h4>I. SECURE STORAGE OF DATA</h4>
                <p>Information collected by Fortress is stored in a secure
                    operating environment that is not available to the public.</p>
                <p>All Data is available only to those authorized employees to
                    whom Fortress has granted permissions by issuing a username
                    and keywords.</p>
                <p>It is the responsibility of each employee to maintain the
                    confidentiality and security of their password and to
                    alert Fortress or reset their password if compromise of
                    security is suspected.</p>
                <p>Fortress may hold Personal Information in either electronic or
                    hard copy form. In both cases Fortress will take reasonable and
                    appropriate steps to ensure that the Personal Information is
                    protected from misuse and loss and from unauthorised access,
                    modification or disclosure.</p>
              
                <h4>J. DATA SUBJECT RIGHTS</h4>
                <p>In terms of POPIA Data Subjects have the following rights:</p>
                <ul>
                    <li>to request access to, and information regarding the nature of Personal Information held and to whom it has been disclosed. Such request for access may be refused on the basis of the grounds of refusal in the Promotion of Access to Information Act, 2000, as amended (“PAIA”);</li>
                    <li>to request the restriction of Processing of Personal Information if:</li>
                    <li>its accuracy is contested by a Data Subject,</li>
                    <li>for a period enabling Fortress to verify the accuracy of the information; </li>
                    <li>if Fortress no longer needs the Personal Information for achieving the purpose for which the information was collected or subsequently Processed, but it has to be maintained for purposes of proof;</li>
                    <li>if the Processing is unlawful and the Data Subject opposes its destruction or deletion and requests the restriction of its use instead; or</li>
                    <li>if the Data Subject requests to transmit the Personal Information into another automated processing system;</li>
                    <li>to not have Personal Information Processed for direct marketing purposes by means of unsolicited electronic communications, save for in certain circumstances;</li>
                    <li>to not to be subjected, under certain circumstances, to a decision which is based solely on the automated Processing of Personal Information intended to provide a profile of such person;</li>
                    <li>to request to rectify or correct Personal Information that is inaccurate, irrelevant, excessive, out of date, incomplete, misleading or obtained unlawfully;</li>
                    <li>to request deletion of Personal Information that is inaccurate, irrelevant, excessive, out of date, incomplete, misleading or obtained unlawfully or where Fortress is no longer authorized to retain it. Fortress will consider whether the information requires correction, deletion or destruction and if Fortress does not agree that there are grounds for action, the Data Subject may request that Fortress adds a note to the Personal Information stating that the Data Subject disagrees with it;</li>
                    <li>to object to Processing of Personal Information where the Processing is on the basis that it protects a Data Subject’s legitimate interests; where the Processing is on the basis that it is necessary for pursuing Fortress’s legitimate interests or a third party to whom it is disclosed; or where the Processing is for the purposes of direct marketing other than by unsolicited electronic communications;</li>
                    <li>to lodge a complaint with the Information Regulator using the details below and request that the Information Regulator assess whether Fortress has violated any provision of applicable data protection regulations;</li>
                </ul>
               <br/><br/>
                <p>The Information Regulator (South Africa)</p><br/>
                <p>JD House</p><br/>
                <p>27 Stiemens Street</p><br/>
                <p>Braamfontein</p><br/>
                <p>Johannesburg</p><br/>
                <p>2001</p><br/>
                <p>email:
                    <a href="mailto:inforeg@justice.gov.za" target="_blank">inforeg@justice.gov.za</a>
                    /  <a href="mailto:complaints.IR@justice.gov.za" target="_blank">complaints.IR@justice.gov.za</a>
                </p>
                <ul>
                    <li>to institute civil proceedings regarding the alleged
                        interference with the protection of the Data Subject's Personal
                        Information.</li>
                </ul>
                
                <p>A Data Subject has the right to request to update or remove the
                    Personal Information they have submitted to Fortress, by
                    submitting a request on the electronic form provided. User updates
                    or removal will reflect in Fortress's active database,
                    but Fortress may retain Personal Information in backup files
                    and archives to prevent a User from being accidentally re-added to
                    the database after they have expressly unsubscribed. Fortress will
                    destroy Personal Information it no longer processes, in line with
                    its Data Retention Policies.</p>
                <p>To exercise these rights or if Data Subjects have questions
                    about how Fortress processes Data Subjects' Personal Information,
                    please contact Fortress using the following contact details:</p>
                <p>Data Access Request Link and Form:</p>
                <p>
                    <a href="https://property.fortressfund.co.za/access-request"
                        target="_blank">https://property.fortressfund.co.za/access-request</a>
                </p>
                <br/><br/>
                <p>The request will be processed by:</p>
                <p>Information Officer</p>
                <p>Block C, Cullinan Place, Cullinan Close, Morningside, 2196</p>
                <p>PO Box, 138, Rivonia, 2128</p>
                <p>+27 (11) 282 2800</p>
                
                <h4>K. LIMITATION</h4>
                <p>Fortress has no control over information or privacy policies of
                    third party websites hyperlinked to and from Fortress. These
                    third party links are being provided for the convenience of the
                    Users of Fortress and Fortress does not endorse and is
                    not responsible or liable for the content, nature or reliability of
                    any linked website or any link contained in a linked
                    website. Fortress takes no responsibility for monitoring,
                    updating, supplementing or correcting any information on any linked
                    website and makes no representation or warranties regarding such
                    information. Please be aware that in linking to these outside
                    websites, Users are leaving Fortress and that Fortress is
                    not responsible for the content of any other site.</p>
                
                <h4>L. ENQUIRIES</h4>
                <p>Any questions or concerns related to this Privacy Policy should
                    be directed to the Fortress Information Officer and/or Deputy
                    Information Officer:</p>
                <p>
                    <br>
                </p>
                <h5>1. Information Officer: Wiko Serfontein</h5>
                <p>Block C, Cullinan Place, Cullinan Close, Morningside, 2196</p>
                <p>+27 (11) 282 2800</p>
                <p>info@fortressfund.co.za</p>
                
                    <br/>
                    <br/>
                
                <h5>2.  Deputy Information Officer: Sarel Coetzer</h5>
                <p>Block C, Cullinan Place, Cullinan Close, Morningside, 2196</p>
                <p>+27 (11) 282 2800</p>
                <p>info@fortressfund.co.za</p>
            </div>
        </div>



    </div>

    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>

    