import { Component, OnInit } from '@angular/core';
import { FortressPropertyService } from "../../../../services/fortress-property.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ViewportScroller } from "@angular/common";
export interface props {
  askingPrice: any
  buildingCode: string
  city: string
  country: string
  description: any
  erfNo: any
  latitude:number
  buildingContactableList:any
  buildingFeatures: any
  latitudeString: string
  legalEntity: any
  lessorRegNo: any
  location: any
  longitude: number
  longitudeString: string
  marketProperty: boolean
  modified: boolean
  buildingImages:any
  postalCode: any
  priceOnRequest: boolean
  propertyCode: string
  totalUnits:number
  id:number
  buildingName: string
  province: string
  saleStatus: any
  saleStatusInt: any
  saleStatusString: any
  salesMarketingDescription: any
  sector: string
  sectorInt: number
  status: number
  statusInt: number
  streetAddress: string
  streetNumber: any
  subCategory: number
  subCategoryInt: number
  suburb: string
  buildingGla: number
  updatePropertyName: boolean
  vatNo: any
  videoUrl: string
  virtualTourUrl: string
  webUrl: string
  youTubeUrl: any
  _originalPropertyName: string
  frontPageImage:string
  imageBucketUrl:string
  buildingId:any
}


@Component({
  selector: 'app-schedule-table',
  templateUrl: './schedule-table.component.html',
  styleUrls: ['./schedule-table.component.scss']
})
export class ScheduleTableComponent implements OnInit {
  allProp: props[] =[];
  searchText:string='';
  p: number = 1;
  openPropertyType:boolean=false;
  openProvinceType:boolean=false;
  openPropertyCountry:boolean=false;
  showProvinceFilter:boolean=true;
  openGLAType:boolean=false;
  openFeatureType:boolean=false;
  propertyTypeFilter:string="Property Type";
  provinceTypeFilter:string="Province";
  propertyCountryFilter:string="Country";
  glaTypeFilter:string="GLA";
  featureTypeFilter:string="Standout Feature";

  currentIndex = -1;
title = '';
page = 1;
count = 0;
pageSize = 50;
pageSizes = [50, 100, 150];
 
  constructor(private scroller: ViewportScroller,private property: FortressPropertyService, private spinner: NgxSpinnerService) { 
    

  }
  ngOnInit(): void {
    this.spinner.show();

    this.property.getProperties().subscribe(result => {
      //console.log(result);
      this.allProp = result;
      this.spinner.hide();
    }, 
    err => {
        //console.log(err);
    })
  }

  handlePageChange(event: number): void {
      this.page = event;
    this.scroller.scrollToAnchor("target");
    }

  toggleFilter(num:number){
    switch(num){
      case 1:   
                this.openGLAType  =false;
                this.openFeatureType = false;
                this.openProvinceType =false;
                this.openPropertyType = !this.openPropertyType;
                this.openPropertyCountry = false;
                break;
      case 2:   this.openPropertyType =false;
                this.openGLAType  =false;
                this.openFeatureType = false;
                this.openProvinceType = !this.openProvinceType;
                this.openPropertyCountry = false;
                break;
      case 3:
                this.openGLAType = !this.openGLAType;
                this.openPropertyType =false;
                this.openFeatureType = false;
                this.openProvinceType =false;
                this.openPropertyCountry = false;
                break;
      case 4:
                this.openFeatureType = !this.openFeatureType;
                this.openPropertyType =false;
                this.openGLAType  =false;
                this.openProvinceType = false;
                this.openPropertyCountry = false;
                break;
      case 5:
                this.openPropertyCountry = !this.openPropertyCountry;
                this.openFeatureType = false;
                this.openPropertyType =false;
                this.openGLAType  =false;
                this.openProvinceType = false;
                break;
    }
  }

  selectProperty(name:string){
    this.page = 1;
    this.provinceTypeFilter="Province";  
    this.searchText="";
    if (name==="All"){
      this.propertyTypeFilter="Property Type";  
      this.searchText="";
    }else{
      this.searchText=name;
      this.propertyTypeFilter=name;
    }
    this.openPropertyType=false;
  }

  selectProvince(name:string){
    this.page = 1;
    this.propertyTypeFilter="Property Type";  
    this.searchText="";
    if (name==="All"){
      this.provinceTypeFilter="Province";  
      this.searchText="";
    }else{
      this.provinceTypeFilter=name;
      this.searchText = name;
    }
    this.openProvinceType=false;
  }

  selectCountry(name:string){
    this.page = 1;
    this.propertyCountryFilter="Country";  
    this.searchText="";
    if (name==="All"){
      this.provinceTypeFilter="Country";  
      this.searchText="";
    }else{
      this.provinceTypeFilter=name;
      this.searchText = name;
    }

    if ((name==="Poland") || (name==="Romania")){
      this.showProvinceFilter = false;
    }else{
      this.showProvinceFilter = true;
    }
    this.openPropertyCountry=false;
  }

  

  clearFilter(){
    this.searchText="";
  }

}
