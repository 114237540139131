import { Pipe, PipeTransform } from '@angular/core';
import { retry } from 'rxjs/operators';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?:any, date?:any): any {
    if (!value) return null;
    if ((!args)&&(!date)) return value;

    args = args.toLowerCase();
    if (date){
      date = date.toLowerCase();
    }
    
    if (args){
      switch (args){
        case "industrial": args = 'sector":"industrial"'
                            break;
        case "logistics": args = 'sector":"logistics"'
                            break;     
        case "office": args = 'sector":"office"'
                            break;         
        case "retail": args = 'sector":"retail"'
                            break; 
        case "other": args = 'sector":"other"'
                            break;     
      }
    }
   

    return value.filter(function(item:any){
     
      if (!args) {
        if (JSON.stringify(item.date).toLowerCase().includes(date)){
          return (JSON.stringify(item).toLowerCase().includes(date));
        }
        
      }
      if(!date) {
        return (JSON.stringify(item).toLowerCase().includes(args));
      }
      if(args !== ''){
        if (JSON.stringify(item).toLowerCase().includes(args) && JSON.stringify(item).toLowerCase().includes(date)){
          return (JSON.stringify(item).toLowerCase());
        }else{
          return null;
        }
      }else{
        return null;
      }
      
      
    });
  }
    

}