<div class="container target" id="target">
    <div class="row">
        <div class="col" *ngIf="art01">
            <h4>Fortress Annual Results Presentation 2024 <span>30 August 2024</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/WDtK9Iw9wgM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art01">
            <h4>Fortress Interim Results Presentation 2024 <span>11 March 2024</span></h4>
            <a href="https://www.corpcam.com/Fortress11032024" style="width: 100%;" target="_blank"><img style="width: 100%;" src="../../../../../assets/images/press/Group_1.png" alt=""/></a>
        </div>
        <div class="col" *ngIf="art01">
            <h4>Fortress Annual Results Presentation 2023 <span>1 September 2023</span></h4>
            <a href="https://www.corpcam.com/Fortress01092023" style="width: 100%;" target="_blank"><img style="width: 100%;" src="../../../../../assets/images/press/Group_1.png" alt=""/></a>
        </div>
        <div class="col" *ngIf="art01">
            <h4>Fortress Interim Results Presentation 2022 <span>8 March 2023</span></h4>
            <a href="https://www.corpcam.com/Fortress09032023" style="width: 100%;" target="_blank"><img style="width: 100%;" src="../../../../../assets/images/press/Group_1.png" alt=""/></a>
        </div>
        <div class="col" *ngIf="art01">
            <h4>Fortress Annual Results Presentation 2022 <span>2 September 2022</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/LkTQuBCETyE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art02">
            <h4>Webinar – Frequently asked questions and discussion regarding Firm Intention Announcement <span>23 June 2022</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/2b6rjFrclsc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art0">
            <h4>Fortress Interim Results Presentation 2022 <span>11 March 2022</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/hZpwzVfSeBg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art1">
            <h4>Fortress Interim Results Presentation 2021 <span>11 March 2021</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/94diZsGVItQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art2">
            <h4>Fortress Annual Results presentation 2020 <span>4 September 2020</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/K49lXqaMIik" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
   
        <div class="col" *ngIf="art3">
            <h4>Fortress Real Estate Investments : The Great Rebuild Webinar <span>6 August 2020</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/EYaQkOebIyE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art4">
            <h4>Fortress Real Estate Investments  Half Year Results Presentation 2020</h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/aepG22YeU8U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
   
        <div class="col" *ngIf="art5">
            <h4>What is the logistics real estate opportunity in SA right now?</h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/Vj59WJOEvlg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art6">
            <h4>Fortress: The Great Rebuild Webinar: 5 minute highlight of panel discussion <span> 6 August 2020</span></h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/XyRadYazRCE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
   
        <div class="col" *ngIf="art7">
            <h4>Pre-Close Webcast December 2019</h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/V69cKtY6GV4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col" *ngIf="art8">
            <h4>Logistics is the backbone of an e-commerce world</h4>
            <iframe loading="lazy" width="100%" height="100%" src="https://www.youtube.com/embed/qrw_LexZSU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
    
</div>