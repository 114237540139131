import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrls: ['./featured-properties.component.scss']
})
export class FeaturedPropertiesComponent implements OnInit {

  slides = [
    {img: "https://dummyimage.com/350x150/423b42/fff"},
    {img: "https://dummyimage.com/350x150/2a2b7a/fff"},
    {img: "https://dummyimage.com/350x150/1a2b7a/fff"},
    {img: "https://dummyimage.com/350x150/7a2b7a/fff"},
    {img: "https://dummyimage.com/350x150/9a2b7a/fff"},
    {img: "https://dummyimage.com/350x150/5a2b7a/fff"},
    {img: "https://dummyimage.com/350x150/4a2b7a/fff"}
  ];

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    // centerPadding: '20px',
    dots: false,
    responsive: [
      {
        // tablet
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '20px',
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '20px',
        }
      }
    ],
    prevArrow: `<svg class="slick-arrow previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow:`<svg class="slick-arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
  };
  

  

// bg:string="../../assets/images/properties/louwlardia1.webp')";

properties=[
  {
    img:"../../assets/images/featured-props/clairwood.png",
    title:"FORTRESS CLAIRWOOD LOGISTICS PARK",
    province:"KWAZULU-NATAL",
    gla:"358 000",
    desc:"Clairwood Logistics Park sets a new standard in prime A-grade logistics facilities in KZN. Developed in phases, it has 24-hour security as well as large yards and office space with direct access to Durban ports by rail. There are seven warehouse structures with high-tolerance floors for stacking. The main tenants include African Sugar Logistics, Super Group, Kings Rest Container and SAMMAR Investments.",
    id:"2",
    mailto:"mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS CLAIRWOOD LOGISTICS PARK",
    link: "/properties/indivual-property?property=400",
    embed:"https://www.youtube.com/embed/V_LyXlhdty8"
  },
  {
    img:"../../assets/images/featured-props/longlake.png",
    title:"FORTRESS LONGLAKE LOGISTICS PARK",
    province:"GAUTENG",
    gla:"105 000",
    desc:"Longlake Logistics Park consists of two strategically situated portions of land and is located close to the N3 Marlboro Interchange. With access via the new K113 to the east and N3 to the north and south, the premium A-grade park is located within 15km of Sandton CBD and OR Tambo international airport. There is a Gautrain station 2km away. The main tenants include Zest and Cargo Carriers.",
    id:"4",
    mailto:"mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS LONGLAKE LOGISTICS PARK",
    link: "/properties/indivual-property?property=733",
    embed:"https://www.youtube.com/embed/V_LyXlhdty8"
  },
  {
    img:"../../assets/images/featured-props/louwlardia.png",
    title:"FORTRESS LOUWLARDIA LOGISTICS PARK",
    province:"GAUTENG",
    gla:"14 320",
    desc:"Louwlardia Logistics Park is strategically located along the N1 motorway with easy access to all arterial routes. The park offers excellent visibility from one of the busiest roads linking Pretoria to Johannesburg. It is also located in a secure business park with perimeter with 24-hour security, making it a particularly desirable location for corporates with large scale warehousing requirements. Main tenants include USN.",
    id:"5",
    mailto:"mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS LOUWLARDIA LOGISTICS PARK",
    link: "/properties/indivual-property?property=403",
    embed:"https://www.youtube.com/embed/V_LyXlhdty8"
  },
  {
    img:"../../assets/images/featured-props/eastport.png",
    title:"FORTRESS EASTPORT LOGISTICS PARK",
    province:"GAUTENG",
    gla:"500 000",
    desc:"Eastport Logistics Park has access and exposure off the R21 and R25 main arterials near OR Tambo International airport. The state-of-the-art Logistics Park has 24-hour security, central fire tanks and pumps offering fire safety, and all the utilities that big operations require. The four main tenants are Pick ‘n Pay, Takelot, Clippa and Teraco. The newly completed Pick ‘n Pay distribution centre is the largest single-phase warehousing development in SA.",
    id:"1",
    mailto:"mailto:grantl@fortressfund.co.za?subject=Enquire about FORTRESS EASTPORT LOGISTICS PARK",
    link: "/properties/indivual-property?property=356",
    embed:"https://www.youtube.com/embed/V_LyXlhdty8"
  }
]




selectedProp={
  img:"",
  title:"",
  province:"",
  gla:"",
  desc:"",
  id:"",
  mailto:""
}


togglePanel: boolean[] = Array(this.properties.length).fill(false);

  toggleDetails(index: number): void {
    // Close all other panels
    this.togglePanel.forEach((value, i) => {
      if (i !== index) {
        this.togglePanel[i] = false;
      }
    });

    // Toggle the selected panel
    this.togglePanel[index] = !this.togglePanel[index];
  }

  constructor() { }

  ngOnInit(): void {
    var rand = this.getRandomInt(4);
    this.selectedProp = this.properties[rand];
  }

  afterChange(e: any) {
   
    var iframes = document.querySelectorAll('iframe');
    
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
      func: 'pauseVideo' }), '*');
      });
  }

  getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }
}
