import { Component, OnInit, Input } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { ClipboardService } from 'ngx-clipboard';
@Component({
  selector: 'app-property-title-block',
  templateUrl: './property-title-block.component.html',
  styleUrls: ['./property-title-block.component.scss']
})
export class PropertyTitleBlockComponent implements OnInit {
  @Input() title: string = "";
  @Input() link: string = "";
  @Input() linkText: string = "";
  @Input() text: string = "";
  @Input() image: string = "";
  @Input() subTitle: string = "";
  @Input() contacts: any;
  
  showNr:boolean=false;
  
  constructor(private sanitizer:DomSanitizer,private clipboardApi: ClipboardService) { }

  ngOnInit(): void {
    //console.log(this.contacts);
  }

  getMail(email:string){
    return "mailto:"+email;
  }

  getNumber(num:string){
    return "tel:"+num;
  }

  getWA(){
  //  this.showNr = !this.showNr ;
    let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text=Look at this property on Fortress ' + window.location.href);
    return sanitizedUrl;
  }
  getMailShare(){
    let sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl('mailto:?subject=I wanted you to see this property&body=Check out this property ' + window.location.href);
    return sanitizedUrl;
  }

  async copyNum(num:number, e:MouseEvent){
    this.showNr = true;
    this.clipboardApi.copyFromContent(num.toString());
    await this.delay(1000);
    this.showNr = false;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
}
