import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mission-blocks',
  templateUrl: './mission-blocks.component.html',
  styleUrls: ['./mission-blocks.component.scss']
})
export class MissionBlocksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
