<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Our properties" childTitle="Property Portfolio"></app-breadcrumbs>

    <app-search-filter-all-properties></app-search-filter-all-properties>

    <app-subscribe-banner></app-subscribe-banner>

    <app-about-callout-blocks></app-about-callout-blocks>

    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>