

<div class="container" *ngIf="showText">
    <h3>Awards and partners</h3>
    <p>Many of our innovative investments and sustainable designs have been recognised with awards from industry peers;</p>
</div>
<div class="slider-container">
    <carousel
        [cellsToShow]="5"
        [loop]="true"
        [autoplay]="true"
        [dots]="true"
        [margin]="20"
    >
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/Anchor.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/FoodAndTreesForAfrica.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/KidsHaven.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/PropertyCharter.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/PropertyPoint.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/RaysOfHope.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/SAIBPP.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/SparrowSchools.webp">
    </div>
    <div class="carousel-cell">
        <img src="../../../../../assets/logo/partners/ThePresidentsAward.webp">
    </div>
   
   
</carousel>
    
</div>
