<div class="banner-container">
    <div class="banner-inner">
        <div class="col-content">
            <div class="col-content-inner">
                <h2 >{{title}}</h2>
                <p *ngIf="subTitle"><b>{{subTitle}}</b></p>
                <p [innerHTML]="parseText()"></p>
                <div *ngIf="!showRouter" >
                    <a *ngIf="linkText && !goHome" [routerLink]="[link]" class="primary-btn-arrow">{{linkText}}</a>
                </div>
                <div *ngIf="showRouter">
                    <a *ngIf="linkText && !goHome" [href]="[link]" target="_blank" class="primary-btn-arrow">{{linkText}}</a>
                </div>
                
                <a *ngIf="goHome" [routerLink]="['/']" [queryParams]="{ latest: 'developments' }" class="primary-btn-arrow">{{linkText}}</a>
            </div>
            
        </div>
        <div class="col-image">
            <img [src]="imageAlone" alt=""/>
        </div>
    </div>
</div>
