import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-member-carousel',
  templateUrl: './team-member-carousel.component.html',
  styleUrls: ['./team-member-carousel.component.scss'],
})
export class TeamMemberCarouselComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    variableWidth: true,
    responsive: [
      {
        // tablet
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '20px',
        },
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
    prevArrow: `<svg class="slick-arrow-corpo previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow: `<svg class="slick-arrow-corpo next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
  };

  teamMembers = [
    {
      profileImage: './assets/images/coporate-governance/carousel/image.png',
      name: 'Susan (Sue) Melanie Ludolph',
      jobTitle: 'Audit',
    },
    {
      profileImage: './assets/images/coporate-governance/carousel/image-1.png',
      name: 'Jan Naudé Potgieter',
      jobTitle: 'Property and investment & independent subcommittee',
    },
    {
      profileImage: './assets/images/coporate-governance/carousel/image-2.png',
      name: 'Robin Lockhart-Ross',
      jobTitle: 'Nomination',
    },
    {
      profileImage: './assets/images/coporate-governance/carousel/image-3.png',
      name: 'Hermina (Ina) Christina Lopion',
      jobTitle: 'Risk',
    },
    {
      profileImage: './assets/images/coporate-governance/carousel/image-4.png',
      name: 'Thavanesan (TC) Chetty',
      jobTitle: 'Social, ethics and sustainability',
    },
    {
      profileImage: './assets/images/coporate-governance/carousel/image-5.png',
      name: 'Edwin (Eddy) Oblowitz',
      jobTitle: 'Remuneration committee',
    },
  ];

  afterChange(e: any) {
    var iframes = document.querySelectorAll('iframe');

    Array.prototype.forEach.call(iframes, (iframe) => {
      iframe.contentWindow.postMessage(
        JSON.stringify({ event: 'command', func: 'pauseVideo' }),
        '*'
      );
    });
  }
}
