<div class="container">
    <div class="col">
        <h4>Fortress logistic parks are</h4>
        <p class="text">Innovative, efficient, cost saving and strategically located buildings ensuring close proximity to transport hubs</p>
        <div class="stats-container">
            <div class="stat">
                <p class="large-number">79%</p>
                <div class="hr"></div>
                <p class="text">of our portfolio are energy efficient buildings</p>
            </div>
            <div class="stat margin-top">
                <p class="large-number">25%</p>
                <div class="hr"></div>
                <p class="text">Higher roof space than industry standard</p>
            </div>
            <div class="stat">
                <p class="large-number">24h</p>
                <div class="hr"></div>
                <p class="text">On-site security access is provided </p>
            </div>
        </div>
       
    </div>
    <div class="col col-bg">
        
    </div>
</div>