<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="" childTitle="Videos"></app-breadcrumbs>
   
    <div class="see-also-container">
        <h3>Videos</h3>
        <div class="content">
            <div class="block" *ngFor="let video of allArr;let i = index">
                <div class="inner">
                    <h4>{{video.title}}</h4>
                    <iframe loading="lazy" width="433" height="244" [src]="getURL(video.media)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>

    <app-subscribe-banner></app-subscribe-banner>

    <app-explore-our-properties></app-explore-our-properties>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div>