import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-white-icon-title-text-button-block',
  templateUrl: './white-icon-title-text-button-block.component.html',
  styleUrls: ['./white-icon-title-text-button-block.component.scss']
})
export class WhiteIconTitleTextButtonBlockComponent implements OnInit {
  @Input() icon: string = "";
  @Input() title: string = "";
  @Input() text: string = "";
  @Input() linkName: string = "";
  @Input() url: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
