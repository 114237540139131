<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Investor Relations" childTitle="Upcoming Events"></app-breadcrumbs>

    <app-banner-foursight
        title="Fortress Real Estate Investments Limited Interim Results Presentation "
        link=""
        linkText=""
        image="url(../../../assets/images/properties/Weskus.webp)"
        imageAlone="../../../assets/images/properties/Weskus.webp"
        text="Contact Ryan on ryane@fortressfund.co.za for more details."
        subTitle ="11 March 2024, 9h00 CAT"
    ></app-banner-foursight>

    <div class="container">
        <div class="col">
            <img src="../../../../assets/images/properties/Whiterivermall.webp" alt=""/>
        </div>
        <div class="col">
            <h2>Upcoming Events Calendar</h2>
            <div class="event">
                <p class="heading">11 March 2024 9:00</p>
                <p class="text">Interim Results Presentation</p>
            </div>
           
        </div>
    </div>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div> 