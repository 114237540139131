<div class="container">
    <app-white-icon-title-text-button-block
    icon="../../../assets/icons/icon-mission.svg"
    title="Our Mission"
    text="Our mission is to be a leading South African real estate company specialising in logistics and retail with a portfolio of state-of-the-art logistics parks and warehousing as well as convenient commuter-based retail centres across South Africa and Central and Eastern Europe."
    linkName="Learn more"
    url="/"
    >
    </app-white-icon-title-text-button-block>
    <app-white-icon-title-text-button-block
    icon="../../../assets/icons/icon-goals.svg"
    title="Our Goal"
    text="Our goal is to build a portfolio that is two-thirds logistics (and be the biggest player in the local market) and one third defensive retail with credibility for Fortress as a growth-powering real estate business partner. Our focus is to build equity and a mutually-beneficial relationships with stakeholders in each of our premier logistics parks and retail centres."
    linkName="Learn more"
    url="/"
    >
    </app-white-icon-title-text-button-block>
    <app-white-icon-title-text-button-block
    icon="../../../assets/icons/icon-value.svg"
    title="Our Values"
    text="Our belief in free and fair dealings in utmost good faith and respect in all that we do for our people, our clients, our shareholders, the environment and the communities we serve."
    linkName="Learn more"
    url="/"
    >
    </app-white-icon-title-text-button-block>
    <app-white-icon-title-text-button-block
    icon="../../../assets/icons/icon-coomitment.svg"
    title="Our Commitment"
    text="We offer an unwavering commitment to real estate innovation and growth, environmental, social and corporate governance (ESG) and ethics standards as well as delivering on our business strategy to ensure sustainable growth in total shareholder returns."
    linkName="Learn more"
    url="/"
    >
    </app-white-icon-title-text-button-block>
</div>
