<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="" childTitle="Press Releases" [isLarge]=false></app-breadcrumbs>
   
    <div class="see-also-container">
        <h3>Press Releases</h3>
        <div class="content">
          <div class="block" *ngFor="let press of allArr;let i = index">
              <img [src]="getImg(press.bannerImg)" alt=""/>
              <div class="inner">
                <h4>{{press.title}}</h4>
                <p>{{press.excerpt}}</p>
                <a class="clearLink" (click)="getLocation($event)" [routerLink]="['/foursight/press-release/article']" [queryParams]="{ id: press.url }">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
              </div>
          </div>
        </div>
    </div>

    <app-subscribe-banner></app-subscribe-banner>

    <app-explore-our-properties></app-explore-our-properties>

    <app-get-in-touch-banner></app-get-in-touch-banner>

</div>