import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-team-blocks',
  templateUrl: './team-blocks.component.html',
  styleUrls: ['./team-blocks.component.scss'],
})
export class TeamBlocksComponent implements OnInit {


  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    dots: true,
    variableWidth: false,
    responsive: [
      {
        // tablet
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '20px',
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '20px',
        }
      }
    ],

  };

  afterChange(e: any) {
   
    var iframes = document.querySelectorAll('iframe');
    
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
      func: 'pauseVideo' }), '*');
      });
  }

  getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }
  
  board=[
    // Management
    {
      img:"../../../../../assets/images/team/steven-brown.png",
      name:"Steven Brown",
      category: "management",
      position:"MANAGING DIRECTOR AND CEO",
      date:"Date of appointment: April 2016 (alternate director); July 2019 (CEO)",
      quali:"BBusSci (Fin) (Hons), CA(SA), CFA",
      desc:"Steven joined Fortress from Capital Property Fund after its acquisition in December 2015. He entered the property industry in 2008 as a listed property analyst for Corovest after completing his articles. Following this, Steven joined Standard Bank’s global markets division in the equity derivatives finance team, thereafter joining the South African real estate division, where he focused on structured lending and equity transactions. He has been involved with several listed real estate companies since 2013, focusing on deal origination and structuring. Since 28 April 2020, Steven has been a non-independent non-executive director of NEPI Rockcastle.",
    },
    {
      img:"../../../../../assets/images/team/ian-david-vorster.png",
      name:"Ian David Vorster",
      category: "management",
      position:"FINANCIAL DIRECTOR AND CFO",
      date:"",
      quali:"BCom (Hons) (Accounting), CA(SA)",
      desc:"Ian completed his articles at PKF (JHB) Inc (“PKF”) and qualified as a chartered accountant in 2006. He joined the PKF corporate finance division in 2007 and was appointed partner in 2009. PKF merged with Grant Thornton Johannesburg in 2013 and, in 2015, he was appointed as head of the corporate finance division. Ian also served on the Grant Thornton executive committee. He has extensive experience in due diligence investigations, transaction structuring, valuations and JSE reporting accountants and independent expert experience.",
    },
    {
      img:"../../../../../assets/images/team/sipho-vuso.png",
      name:"Sipho Vuso (Vuso) Majija",
      category: "management",
      position:"Executive Director and Head of Retail",
      date:"Date of appointment: May 2017",
      quali:"DCE (Cape Technikon), BSc (Hons) (Property Studies), MBA (Gordon Institute of Business Science (“GIBS”))",
      desc:"Vuso has been part of Fortress since its listing in 2009. He has 15 years’ extensive experience in property and asset management of commercial, industrial and retail properties. Vuso heads up Fortress’ retail portfolio and is responsible for developments, redevelopments, extensions, national tenant relations, acquisitions and disposals. He previously served on Pangbourne Properties Limited’s board as an alternate director. Since 6 June 2018, Vuso served on the board of NEPI Rockcastle as a non-independent, non-executive director. Since 28 April 2020, he has been an alternate director to Steven Brown on the NEPI Rockcastle board. He also serves as a director of the SACSC.",
    },
    // Quote
    {
      isQuote: true,
      quote: "Our greatest asset is the relationships that we build with our tenants and the communities in which we operate to ensure we grow together. By empowering our people, we are powering growth in South Africa.",
      author: "Steven Brown, CEO",
      category: "management",
    },
    // Management continues - No modals
    {
      img:"../../../../../assets/images/team/jodie-ellinor-dreyer.png",
      name:"Jodie Ellinor-Dreyer",
      category: "management",
      position:"Head of Corporate Social Investment and Transformation",
    },
    {
      img:"../../../../../assets/images/team/ryan-eichstadt.png",
      noPhoto: true,
      name:"Ryan Eichstadt",
      category: "management",
      position:"Head of Investor Relations & Corporate Finance",
    },
    {
      img:"../../../../../assets/images/team/jason-cooper.png",
      name:"Jason Cooper",
      category: "management",
      position:"Head of Property Development",
    },
    {
      img:"../../../../../assets/images/team/pc-potgieter.png",
      name:"PC Potgieter",
      category: "management",
      position:"Head of Sustainability",
    },
    // {
    //   img:"../../../../../assets/images/team/wiko-serfontein.png",
    //   name:"Wiko Serfontein",
    //   category: "management",
    //   position:"Financial Director",
    // },
    {
      img:"../../../../../assets/images/team/bruce-collins.png",
      name:"Bruce Collins",
      category: "management",
      position:"Head of Asset Management",
    },
    {
      img:"../../../../../assets/images/team/team-placeholder.png",
      noPhoto: true,
      name:"Tamlyn Stevens",
      category: "management",
      position:"Company Secretary",
    },
    // Directors
    {
      img:"../../../../../assets/images/team/robin-lockhart-ross.png",
      name:"Robin Lockhart-Ross",
      category: "directors",
      position:"Independent non-executive Chairman",
      date:"Date of appointment: July 2018 (independent non-executive director); 1 July 2020 (independent non-executive chairperson)",
      quali:"CA(SA), BCom, Higher Diploma (Accounting), BCom (Hons) (Tax), MAcc (Tax)",
      desc:"Robin serves as an independent nonexecutive director and chairperson of the Trematon Capital Investments Limited board and as an independent non-executive director of Heriot REIT Limited. He retired from Nedbank at the end of June 2018 where he held the position of managing executive of Nedbank CIB: Commercial Property Finance from November 2014. Before that, he served as the head of credit for Nedbank Property Finance for 12 years. He was the head of risk at BoE Corporate: Property and Asset Finance, acted as managing executive of NBS Homeloans and was chairperson of Bond Choice mortgage originators. Robin chaired the property investment committee and served as a non-executive director of various investee and associate companies at Nedbank Property Partners in his capacity as managing executive of Nedbank Property Finance.",
    },
    {
      img:"../../../../../assets/images/team/tc-chetty.png",
      name:"Thavanesan (TC) Chetty",
      category: "directors",
      position:"INDEPENDENT NON-EXECUTIVE DIRECTOR",
      date:"Date of appointment: February 2021",
      quali:"BA (Economics and Geography), Master of Town and Regional Planning, Diploma in Business Management",
      desc:"TC is a professional town and regional planner, with a degree in economics. With over 25 years of experience in the property sector, he is the CEO of TC Chetty and Associates, a consultancy business providing services in business strategy, development planning, development economics, planning project management, training and development coordination. TC is also the partner development and public affairs manager in South Africa for the Royal Institute of Chartered Surveyors (“RICS”). Previously, he acted as president and board member of the South African Property Owners Association (“SAPOA”) and currently serves as the chairperson of Immedia, a specialist mobile app development and media strategy company.",
    },
    {
      img:"../../../../../assets/images/team/ina-lopion.png",
      name:"Hermina (Ina) Christina Lopion",
      category: "directors",
      position:"INDEPENDENT NON-EXECUTIVE DIRECTOR",
      date:"Date of appointment: January 2020",
      quali:"BSc, Executive Development Programme",
      desc:"Ina has a BSc degree from Stellenbosch University and qualifications in executive leadership. She has an in-depth knowledge of the South African property market, gained over more than 28 years in the industry. She plays an active role in industry leadership and was a director of the South African Council of Shopping Centres (“SACSC”). Ina previously served as an executive director of Vukile Property Fund Limited, and held various positions at Sanlam Properties and Gensec Property Services.",
    },
    // Quote
    {
      isQuote: true,
      quote: "Our greatest asset is the relationships that we build with our tenants and the communities in which we operate to ensure we grow together. By empowering our people, we are powering growth in South Africa.",
      author: "Steven Brown, CEO",
      category: "directors",
    },
    {
      img:"../../../../../assets/images/team/sue-ludolph.png",
      name:"Susan (Sue) Melanie Ludolph",
      category: "directors",
      position:"INDEPENDENT NON-EXECUTIVE DIRECTOR",
      date:"Date of appointment: December 2018",
      quali:"BCom, BAcc, CA(SA)",
      desc:"Susan played a driving role in South African and international business reporting. She has served as an independent member of the Discovery Health Medical Scheme’s audit committee for six years, and was an independent member of the Discovery Health Medical Scheme’s risk committee for four years. From 2014 to 2019, Susan was a judge for the PwC Building Public Trust Awards. She was previously the project director: financial reporting for the South African Institute of Chartered Accountants (“SAICA”), co-founded the CFO Forum of South Africa, was the national recruitment partner for Deloitte, and was the dean of the commerce faculty and director of the Midrand campus for Educor Limited. She established and implemented the strategy and work plan of South Africa’s first top 100 CFO Forum, which guides, influences and leads on issues affecting CFOs and business. Susan has experience in financial and integrated reporting, tax, risk management and governance.",
    },
    {
      img:"../../../../../assets/images/team/edwin-oblowitz.png",
      name:"Edwin (Eddy) Oblowitz",
      category: "directors",
      position:"INDEPENDENT NON-EXECUTIVE DIRECTOR",
      date:"Date of appointment: May 2023",
      quali:"BCom, CA(SA), C.P.A (Isr.)",
      desc:"Eddy holds a BCom degree and is a chartered accountant and certified public accountant. In his over 42 year professional career, Eddy has had exposure to various diverse local and international industries and business enterprises. He is currently the owner of Contineo Financial Services and serves as an independent non-executive director at Trencor Limited, The Foschini Group Limited and BNP Paribas Personal Finance South Africa Limited (“RCS Group”), as well as various non-listed companies.",
    },
    {
      img:"../../../../../assets/images/team/jan-naude-potgieter.png",
      name:"Jan Naude Potgieter",
      category: "directors",
      position:"LEAD INDEPENDENT NON-EXECUTIVE DIRECTOR",
      date:"December 2015 (independent non-executive director); January 2022 (lead independent nonexecutive director)",
      quali:"BCompt (Hons), CTA, CA(SA), Management Development Programme (University of Michigan), Strategic Planning and Management in Retailing (Monash University, Australia), Advanced Management Programme (INSEAD France)",
      desc:"Jan qualified as a chartered accountant after completing his articles at PwC. He held various managerial positions early in his career, including business manager at Clover SA, followed by seven years in various divisions of SABMiller in senior financial roles. He was headhunted by Massmart in 2005 to join its Massdiscounters team - first as financial director and then as CEO, a position he held for six years. He then consulted and joined the Italtile Group in 2014 as chief operation officer (“COO”), and served as CEO from 2016. Jan retired as CEO on 31 December 2021 and remains on the Italtile board as a non-executive director. He is also chairman of Janette Media Consulting.",
    },
    {
      img:"../../../../../assets/images/team/caswell-rampheri.png",
      name:"Moshiko (Caswell) Ramokgadi Rampheri",
      category: "directors",
      position:"INDEPENDENT NON-EXECUTIVE DIRECTOR",
      date:"Date of appointment: June 2023",
      quali:"BA (Law), LLB, HDip Tax",
      desc:"Caswell holds a BA LLB, as well as a Higher Diploma in Tax, certificates in Shopping Centre Management, Finance and Accounting and Estate Agency, and is a graduate of the Standard Bank Wings Leadership Programme. His past experience includes, as a commercial property consultant with the Investec Property Group, a project executive and centre manager with Old Mutual Properties, deputy general manager within the Peermont Group, head asset manager and strategy director at Pareto Limited, together with various senior management roles within Liberty Properties. In addition, he is a past director of the Joburg Property Company, past president of the South African Institute of Black Property Practitioners and a previous committee member of the South African Property Owners Association. Caswell currently serves as a non-executive director of the Delta Property Fund Limited, as well as chief executive officer of the Buna Group, a pan-African real estate and infrastructure development, investment and debt/equity raising and advisory company, which he founded in 2009.",
    },
    {
      img:"../../../../../assets/images/team/jon-hillary.png",
      noPhoto: false,
      name:"Jonathon (Jon) Wade Hillary",
      category: "directors",
      position:"INDEPENDENT NON-EXECUTIVE DIRECTOR",
      quali: "B Compt (Hons), CTA, CA(SA), Advanced Management Programme (Harvard University)",
      desc:"Jon founded Jika Strategies (Pty) Limited in 2021 focussing on strategic, operational and M&A advisory. Following completion of his articles at KPMG, Jon spent 6 years abroad in multiple countries working with magic circle law firms. On his return to South Africa in 2003, Jon joined Group Five Infrastructure as Group Financial Director for Intertoll. Jon was appointed as Managing Director of Group Five Property Developments in 2008 and became an Executive Director of the Investments and Concessions cluster in 2014. In 2017, Jon joined the JSE listed Torre Industries Group as Executive Chairman and CEO, which was then taken private and renamed Synerlytic in 2019. Jon’s areas of expertise focus on leadership, corporate finance and operational effectiveness of large listed organisations.",
    },
    {
      img:"../../../../../assets/images/team/steven-brown.png",
      name:"Steven Brown",
      category: "directors",
      position:"MANAGING DIRECTOR AND CEO",
      date:"Date of appointment: April 2016 (alternate director); July 2019 (CEO)",
      quali:"BBusSci (Fin) (Hons), CA(SA), CFA",
      desc:"Steven joined Fortress from Capital Property Fund after its acquisition in December 2015. He entered the property industry in 2008 as a listed property analyst for Corovest after completing his articles. Following this, Steven joined Standard Bank’s global markets division in the equity derivatives finance team, thereafter joining the South African real estate division, where he focused on structured lending and equity transactions. He has been involved with several listed real estate companies since 2013, focusing on deal origination and structuring. Since 28 April 2020, Steven has been a non-independent non-executive director of NEPI Rockcastle.",
    },
    {
      img:"../../../../../assets/images/team/ian-david-vorster.png",
      name:"Ian David Vorster",
      category: "directors",
      position:"FINANCIAL DIRECTOR AND CFO",
      date:"",
      quali:"BCom (Hons) (Accounting), CA(SA)",
      desc:"Ian completed his articles at PKF (JHB) Inc (“PKF”) and qualified as a chartered accountant in 2006. He joined the PKF corporate finance division in 2007 and was appointed partner in 2009. PKF merged with Grant Thornton Johannesburg in 2013 and, in 2015, he was appointed as head of the corporate finance division. Ian also served on the Grant Thornton executive committee. He has extensive experience in due diligence investigations, transaction structuring, valuations and JSE reporting accountants and independent expert experience.",
    },
    {
      img:"../../../../../assets/images/team/sipho-vuso.png",
      name:"Sipho Vuso (Vuso) Majija",
      category: "directors",
      position:"Executive Director and Head of Retail",
      date:"Date of appointment: May 2017",
      quali:"DCE (Cape Technikon), BSc (Hons) (Property Studies), MBA (Gordon Institute of Business Science (“GIBS”))",
      desc:"Vuso has been part of Fortress since its listing in 2009. He has 15 years’ extensive experience in property and asset management of commercial, industrial and retail properties. Vuso heads up Fortress’ retail portfolio and is responsible for developments, redevelopments, extensions, national tenant relations, acquisitions and disposals. He previously served on Pangbourne Properties Limited’s board as an alternate director. Since 6 June 2018, Vuso served on the board of NEPI Rockcastle as a non-independent, non-executive director. Since 28 April 2020, he has been an alternate director to Steven Brown on the NEPI Rockcastle board. He also serves as a director of the SACSC.",
    },

];

  currentMember=this.board[0];

  filteredBoard = this.board;
  selectedCategory: string | null = 'management';

  filterByCategory(category: string | null) {
    this.selectedCategory = category;
    if (category) {
      this.filteredBoard = this.board.filter(member => member.category === category);
    } else {
      // If no category is selected, show all members
      this.filteredBoard = this.board;
    }
  }

  isCategoryActive(category: string | null): boolean {
    return this.selectedCategory === category;
  }

  modalOpen:boolean=false;
  constructor() {}
 
  ngOnInit(): void {
    this.filterByCategory(this.selectedCategory);
  }

  closeModal(){
    this.modalOpen = false;
  }
  openModal(index:number){
    this.currentMember = this.filteredBoard[index];
    this.modalOpen = true;
  }
}
