import { Component, OnInit } from '@angular/core';

interface CardDetail {
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-communication-with-stakeholders',
  templateUrl: './communication-with-stakeholders.component.html',
  styleUrls: ['./communication-with-stakeholders.component.scss'],
})
export class CommunicationWithStakeholdersComponent implements OnInit {
  circleAccent: string;

  constructor() {
    this.circleAccent = './assets/icons/circle-accent-full.svg';
  }

  displayHandler: number = 9;

  cardDetails: CardDetail[] = [
    {
      icon: './assets/icons/stakeholder-info-icons/shareholders.svg',
      title: 'Shareholders',
      description:
        'Fortress is committed to providing shareholders with timely access to applicable information. Communication with its shareholders is open, honest and transparent. Shareholders are provided with information via circulars and integrated and interim reports. Additional information is provided on Fortress’ website, via SENS announcements and press releases.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/analysts.svg',
      title: 'Analysts',
      description:
        'Fortress holds an annual and interim results presentation in Johannesburg and roadshows in Cape Town.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/financiers.svg',
      title: 'Financiers',
      description:
        'Fortress meets with its financiers on a regular basis to discuss its requirements and theirs. Information is provided through analyst presentations, road shows, integrated reports and interim reporting.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/tenants.svg',
      title: 'Tenants',
      description:
        'Fortress strives to form mutually beneficial business relationships with its tenants. Fortress’ asset managers and property managers meet with the tenants on a regular basis and conduct regular site visits to Fortress’ properties.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/government.svg',
      title: 'Government',
      description:
        'Fortress endeavours to have mutually beneficial relationships with government, its departments and parastatals. Fortress engages with local authorities both directly and via its property managers and external consultants regarding utility issues, rates clearances, zoning, etc.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/industry-associations.svg',
      title: 'Industry associations',
      description:
        'Fortress’ asset managers belong to various industry bodies including SAPOA and the SA Council of Shopping Centres and regularly attend industry conferences. Fortress is a member of the SA REIT Association.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/analysts.svg',
      title: 'Business partners',
      description:
        'Fortress maintains professional working relationships with its business partners at the same time as fostering a culture of teamwork. Fortress ensures that all of its business partners fully understand its performance standards and requirements. Fortress’ business partners include the property managers and both Fortress’ asset managers and senior management meet with the property managers on a regular basis.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/com&env.svg',
      title: 'Communities and environment',
      description:
        'Fortress is committed to being a good corporate citizen and frequently evaluates the impact of its projects and developments on society and the environment.',
    },
    {
      icon: './assets/icons/stakeholder-info-icons/suppliers.svg',
      title: 'Suppliers',
      description:
        'Fortress maintains professional working relationships with all of its suppliers and ensures that its suppliers understand Fortress’ performance standards and requirements. Where possible, Fortress will have service level agreements or terms of reference for its relationships with suppliers, which include performance expectations.',
    },
  ];

  getCardDetails() {
    return this.cardDetails.slice(0, this.displayHandler);
  }
  readMoreHandler() {
    if (this.displayHandler === 9) {
      return (this.displayHandler = 3);
    }
    return (this.displayHandler = this.displayHandler + 3);
  }
  ngOnInit(): void {}
}
