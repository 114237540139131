<div class="container-footprint">
    <div class="content">
        <div class="col">
            <!-- <p class="small-heading">About us</p> -->
            <h2>Our Footprint</h2>
            <p>We are diversified property group with a footprint in all nine provinces of South Africa. Approximately 83% of our investment properties are concentrated in the three most populated and economically active provinces of South Africa: Gauteng (59,6%), KwaZulu-Natal (15,1%) and the Western Cape (8,1%). We have embarked on establishing a strong footprint in Central and Eastern Europe where the market is continually growing and we can evolve together with it.</p>
            <div class="stats-container">
                <div class="stat">
                    <p class="large-number">235</p>
                    <p class="text">Buildings</p>
                </div>
                <div class="stat">
                    <p class="large-number">R32,6B</p>
                    <p class="text">in total portfolio value</p>
                </div>
                <div class="stat">
                    <p class="large-number">2 873 318m<sup>2</sup></p>
                    <p class="text">GLA</p>
                </div>
               
            </div>
            <!-- <a class="primary-btn-arrow" [routerLink]="['/properties/property-portfolio']">Explore all properties</a> -->
        </div>
        <div class="col">
            <div class="toggle-container">
                <div [ngClass]="{'active' : saActive}" (click)="toggleCountry('SA')" class="toggle-btn">South Africa</div>
                <div [ngClass]="{'active' : !saActive}" (click)="toggleCountry('EE')" class="toggle-btn">Eastern Europe</div>
            </div>
            <app-southafrica *ngIf="saActive"></app-southafrica>
            <app-easterneurope *ngIf="!saActive"></app-easterneurope>
        </div>
    </div>
</div>