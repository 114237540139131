<div class="col-2">
  <div class="col-row">
    <h1>Communication <br />with stakeholders</h1>
    <p>
      Fortress is committed to ensuring timeous, effective and transparent
      communication with shareholders and other stakeholders as set out below.
    </p>
  </div>
  <div class="col-row">
    <img
      src="../../../../../assets/images/coporate-governance/stakholders-background-image.png"
    />
  </div>
  <div class="col-card-container">
    <div class="col-3">
      <app-stakeholder-info-card
        *ngFor="let cardDetail of getCardDetails()"
        [title]="cardDetail.title"
        [description]="cardDetail.description"
        [icon]="cardDetail.icon"
      ></app-stakeholder-info-card>
    </div>
  </div>
  <!--  -->

  <img class="circle-accent" [src]="circleAccent" />
</div>
<div (click)="readMoreHandler()" class="show-more">
  <a *ngIf="getCardDetails().length != 9">Show more</a>
  <a *ngIf="getCardDetails().length === 9">Show less</a>
  <svg
    [ngClass]="{ rotate: getCardDetails().length === 9 }"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16 10L12 14L8 10"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>
