<style>
    
    /* updated for Property Enquiry form - starts */
    #mauticform_genericproperyenquiryform_f_name { width: 48%; margin-right:2%; margin-bottom:5px; }
    #mauticform_genericproperyenquiryform_last_name { width: 48%; margin-right:2%; margin-bottom:5px; }
    #mauticform_genericproperyenquiryform_company { width: 98%; margin-right:2%; margin-bottom:5px; }
    #mauticform_genericproperyenquiryform_email { width: 98%; margin-right:2%; margin-bottom:5px; }
    #mauticform_genericproperyenquiryform_mobile { width: 98%; margin-right:2%; margin-bottom:5px; }
    #mauticform_genericproperyenquiryform_property_enquiry { width: 98%; margin-right:2%; margin-bottom:5px; }
    #mauticform_wrapper_genericproperyenquiryform .mauticform-label, #mauticform_wrapper_genericproperyenquiryform .mauticform-checkboxgrp-label { color: #637981; }
    .mauticform-text input { background-color: #fff; }
    #mauticform_wrapper_genericproperyenquiryform .mauticform-button-wrapper .mauticform-button, .mauticform-button-wrapper .mauticform-button.btn-default { background: #00353E; font-weight: 400; letter-spacing: -0.01em; text-transform: none; }
    #mauticform_wrapper_genericproperyenquiryform .mauticform-button-wrapper .mauticform-button { border: solid 2px #00353E;padding: 15px 38px 15px 30px; background-color: #00353E;color: white;font-family: 'Overpass', sans-serif; border: solid 2px #00353E; background-image: url('icon-arrow-button.svg'); background-repeat: no-repeat; background-position: 96% center;}
    #mauticform_wrapper_genericproperyenquiryform .mauticform-button-wrapper .mauticform-button:hover {color: #ffffff !important;background-color: #00353E  !important;}
    /* updated for Property Enquiry form - ends */


</style>

<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Our properties" parentLink="/properties/property-portfolio" anotherLink="/vacancies" anotherTitle="Our Vacancies" [childTitle]="currentProp.buildingName"></app-breadcrumbs>
    <app-property-title-block  *ngIf="doneLoading"
        [title]="currentProp.buildingName"
        link=""
        linkText="Contact"
        [image]="getImage(currentProp.imageBucketUrl, currentProp.frontPageImage)"
        [text]="currentProp.description"
        [subTitle] ="currentProp.province"
        [contacts] ="currentProp.buildingContactableList"
    ></app-property-title-block>

    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>

    <div class="container">
        <div class="col">
            
            <h2>Get in touch</h2>


            <div id="mauticform_wrapper_genericproperyenquiryform" class="mauticform_wrapper">
                <form autocomplete="false" role="form" method="post" action='https://property.fortressfund.co.za/form/submit?formId=51' id="mauticform_genericproperyenquiryform" data-mautic-form="genericproperyenquiryform" enctype="multipart/form-data" generic-property-enquiry-form>
                    <div class="mauticform-error" id="mauticform_genericproperyenquiryform_error"></div>
                    <div class="mauticform-message" id="mauticform_genericproperyenquiryform_message"></div>
                    <div class="mauticform-innerform">
                        
                    <div class="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                        <div id="mauticform_genericproperyenquiryform_f_name" data-validate="f_name" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                            <label id="mauticform_label_genericproperyenquiryform_f_name" for="mauticform_input_genericproperyenquiryform_f_name" class="mauticform-label">First Name</label>
                            <input id="mauticform_input_genericproperyenquiryform_f_name" name="mauticform[f_name]" value="" placeholder="" class="mauticform-input f_name" type="text">
                            <span class="mauticform-errormsg" style="display: none;">This is required.</span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_last_name" data-validate="last_name" data-validation-type="text" class="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                            <label id="mauticform_label_genericproperyenquiryform_last_name" for="mauticform_input_genericproperyenquiryform_last_name" class="mauticform-label">Last Name</label>
                            <input id="mauticform_input_genericproperyenquiryform_last_name" name="mauticform[last_name]" value="" placeholder="" class="mauticform-input last_name" type="text">
                            <span class="mauticform-errormsg" style="display: none;">This is required.</span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_company" class="mauticform-row mauticform-text mauticform-field-3">
                            <label id="mauticform_label_genericproperyenquiryform_company" for="mauticform_input_genericproperyenquiryform_company" class="mauticform-label">Company Name</label>
                            <input id="mauticform_input_genericproperyenquiryform_company" name="mauticform[company]" value="" class="mauticform-input company_name" type="text">
                            <span class="mauticform-errormsg" style="display: none;"></span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_email" data-validate="email" data-validation-type="email" class="mauticform-row mauticform-email mauticform-field-4 mauticform-required">
                            <label id="mauticform_label_genericproperyenquiryform_email" for="mauticform_input_genericproperyenquiryform_email" class="mauticform-label">Email Address</label>
                            <input id="mauticform_input_genericproperyenquiryform_email" name="mauticform[email]" value="" placeholder=example@domain.com class="mauticform-input email" type="email">
                            <span class="mauticform-errormsg" style="display: none;">This is required.</span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_mobile" data-validate="mobile" data-validation-type="tel" class="mauticform-row mauticform-tel mauticform-field-5 mauticform-required">
                            <label id="mauticform_label_genericproperyenquiryform_mobile" for="mauticform_input_genericproperyenquiryform_mobile" class="mauticform-label">Mobile Number</label>
                            <input id="mauticform_input_genericproperyenquiryform_mobile" name="mauticform[mobile]" value="" placeholder="" class="mauticform-input mobile" type="tel">
                            <span class="mauticform-errormsg" style="display: none;">This is required.</span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_property_enquiry" class="mauticform-row mauticform-text mauticform-field-6">
                            <label id="mauticform_label_genericproperyenquiryform_property_enquiry" for="mauticform_input_genericproperyenquiryform_property_enquiry" class="mauticform-label">Property Enquiry</label>
                            <textarea id="mauticform_input_genericproperyenquiryform_property_enquiry" name="mauticform[property_enquiry]" class="mauticform-textarea enquiry-text"></textarea>
                            <span class="mauticform-errormsg" style="display: none;"></span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_optin" data-validate="optin" data-validation-type="checkboxgrp" class="mauticform-row mauticform-checkboxgrp mauticform-field-7 mauticform-required">
                            <div class="mauticform-checkboxgrp-row">                    <input style="width:24px;height:24px;margin-right:6px;vertical-align:top" class="mauticform-checkboxgrp-checkbox" name="mauticform[optin][]" id="mauticform_checkboxgrp_checkbox_optin_Iagree0" type="checkbox" value="I agree">
                                <label id="mauticform_checkboxgrp_label_optin_Iagree0" for="mauticform_checkboxgrp_checkbox_optin_Iagree0" style="width:90%;" class="mauticform-checkboxgrp-label">I consent to receive direct marketing from Fortress by email, and to be added to its mailing list to receive news, insights, invitations and other information related to its property and real estate products and services.</label>
                            </div>
                            <span class="mauticform-errormsg" style="display: none;">This is required.</span>
                        </div>

                        <div id="mauticform_genericproperyenquiryform_not_a_robot" style="display: none;" class="mauticform-row mauticform-text mauticform-field-8">
                            <label id="mauticform_label_genericproperyenquiryform_not_a_robot" for="mauticform_input_genericproperyenquiryform_not_a_robot" class="mauticform-label">Not a robot</label>
                            <input id="mauticform_input_genericproperyenquiryform_not_a_robot" name="mauticform[not_a_robot]" value="" class="mauticform-input not_a_robot" type="text">
                            <span class="mauticform-errormsg" style="display: none;"></span>
                        </div>

                            <!-- dynamic values populated below -->
                            <input id="mauticform_input_genericproperyenquiryform_property_id" name="mauticform[property_id]" [value]="currentProp.id" class="mauticform-hidden" type="hidden">
                            <input id="mauticform_input_genericproperyenquiryform_property_name" name="mauticform[property_name]" [value]="currentProp.buildingName" class="mauticform-hidden" type="hidden">
                            <input id="mauticform_input_genericproperyenquiryform_property_url" name="mauticform[property_url]" [value]="getURL()" class="mauticform-hidden" type="hidden">
                            <input id="mauticform_input_genericproperyenquiryform_property_owner_1" name="mauticform[property_owner_1]" [value]="currentProp.buildingContactableList[0].email" class="mauticform-hidden" type="hidden">
                            <input id="mauticform_input_genericproperyenquiryform_property_owner_2" name="mauticform[property_owner_2]" value="" class="mauticform-hidden" type="hidden">
                            <input id="mauticform_input_genericproperyenquiryform_property_owner_3" name="mauticform[property_owner_3]" value="" class="mauticform-hidden" type="hidden">
                            <!-- dynamic values end -->
                        <div id="mauticform_genericproperyenquiryform_submit" class="mauticform-row mauticform-button-wrapper mauticform-field-15">
                            <button type="submit" name="mauticform[submit]" id="mauticform_input_genericproperyenquiryform_submit" value="" class="mauticform-button">Submit</button>
                        </div>
                        </div>
                    </div>

                    <input type="hidden" name="mauticform[formId]" id="mauticform_genericproperyenquiryform_id" value="51">
                    <input type="hidden" name="mauticform[return]" id="mauticform_genericproperyenquiryform_return" value="">
                    <input type="hidden" name="mauticform[formName]" id="mauticform_genericproperyenquiryform_name" value="genericproperyenquiryform">

                    </form>
            </div>
        </div>
        <div class="col">
            <app-property-gallery-block 
                *ngIf="doneLoading"
                    statText1= 'GLA'
                    [stat1] = 'currentProp.buildingGla'
                    statText2= 'Total Units'
                    [stat2] = 'currentProp.totalUnits'
                    statText3= 'Sector'
                    [stat3]= 'currentProp.sector'
                    [img1] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img2] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img3] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img4] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [img5] = 'getImageSrc(currentProp.imageBucketUrl, currentProp.frontPageImage)'
                    [images]="currentProp.buildingImages"
                    [bucket]="currentProp.imageBucketUrl"
                ></app-property-gallery-block>
        </div>
    </div>


    <div class="containerUnits" *ngIf="showUnits">
        <h3>Available Units Breakdown</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Unit Name</th>
                    <th>Category</th>
                    <th>GLA</th>
                    <th>Available lease date</th>
                    <th>Brochure</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let unit of units; let i = index">
                    <td>{{unit.unitName}}</td>
                    <td>{{unit.unitCategoryString}}</td>
                    <td>{{unit.unitGla}}</td>
                    <td *ngIf="getDate(unit.availableDate) < now">Available</td>
                    <td *ngIf="getDate(unit.availableDate) > now">{{unit.availableDate| date: 'd MMMM YYYY'}}</td>
                    <td (click)="download(unit.id)"><img style="width: 20px;" src="../../../../assets/icons/icon-download.webp" alt=""/></td>
                </tr>
            </tbody>
        </table>
    </div>


    <app-video
    *ngIf="doneLoading  && currentProp.videoUrl !=='' && currentProp.videoUrl !== null"
    title="Explore this property virtually"
    text=""
    subtitle="discover the property"
    [embed]="currentProp.videoUrl"
    sideVideo="right"    
    ></app-video>

  
   <!-- <app-video-vr
    *ngIf="doneLoading && currentProp.virtualTourUrl !=='' && currentProp.virtualTourUrl !== null"
    title="VR Footage"
    text="Explore what it is that makes us different, or take a look through our property portfolio."
    subtitle="discover the property"
    [embed]='currentProp.virtualTourUrl'
    sideVideo="right"    
    ></app-video-vr>-->

    <app-property-address-block
    *ngIf="doneLoading"
    [address]="currentProp.streetAddress"
    [province]="currentProp.province"
    [features]="currentProp.buildingFeatures"
    [lat]="currentProp.latitude"
    [long]="currentProp.longitude"
    [web]="currentProp.webUrl"
    [google]="currentProp.virtualTourUrl"
    ></app-property-address-block>

  
    <!--<div class="containerUnits">
        <h3>Get in touch</h3>
        <div class="message" *ngIf="messageSent">
            <p  *ngIf="message" class="success">Your message was sent successfully, an asset manager will be in touch with you.</p>
            <p *ngIf="!message" class="error">There was an error sending your message, please try again later.</p>
        </div>
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" *ngIf="!messageSent">
            <input  placeholder="Name and Surname" type="text" formControlName="firstName">
            <input type="text" placeholder="Email" formControlName="contactEmail">
            <input type="text" placeholder="Phone number" formControlName="contactPhone">
            <textarea  type="text" formControlName="notes" placeholder="Notes"></textarea>
            <button class="primary-btn-arrow" type="submit" [disabled]="!profileForm.valid">Send query</button>
        </form>
    </div>-->





    <app-get-in-touch-banner></app-get-in-touch-banner>

    <app-similar-properties [props]="allProp" [sector]= 'currentProp.sector' [currentID]= 'currentProp.id' *ngIf="doneLoading"></app-similar-properties>

    <app-subscribe-banner></app-subscribe-banner>

</div>