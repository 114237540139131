import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-properties-banner',
  templateUrl: './properties-banner.component.html',
  styleUrls: ['./properties-banner.component.scss']
})
export class PropertiesBannerComponent implements OnInit {
  constructor() { }
  @Output() onClose = new EventEmitter();
  ngOnInit(): void {
  }

  onclicked(){
    this.onClose.emit();
  }


}
