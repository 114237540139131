import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';  
import { Router } from '@angular/router';  

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error:boolean=false;
  password: string='';   
  title = 'auth-guard-demo';  
  constructor(private _auth: AuthenticationService, private _router: Router) {  
    if (this._auth.loggedIn) {  
      this._router.navigate(['vacancies']);  
    }  
  }  
  login(): void {  
    if (this.password != '') {  
      if (this._auth.login(this.password)) {  
        this._router.navigate(["vacancies"]);  
        this.error = false;
      }  
      else  
      this.error = true;
    }  
  }  

  ngOnInit(): void {
  }

}
