<div class="container">
    <div class="col bg-img" [style.background-image]="currentContent.bg">

    </div>
    <div class="col">
        <h4>Our history</h4>
        <p class="date">{{currentContent.date}}</p>
        <h2>{{currentContent.title}}</h2>
        <p>{{currentContent.text}}</p>
        <div class="slider">
            <img src="../../../../../assets/icons/icon-arrow-with-tail.svg" alt="" (click)="navSlider('-1')"/>
            <div>
                <p *ngFor="let date of content; let i = index" class="dateSlider" (click)="navSliderIndex(i)" [class.active]="count === i">{{date.year}}</p>
            </div>
            <img src="../../../../../assets/icons/icon-arrow-with-tail.svg" alt="" (click)="navSlider('+1')"/>
        </div>
    </div>
</div>