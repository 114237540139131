<div class="bg" *ngFor="let art of allArr; let i = index">
  <app-breadcrumbs-white
    grandparentTitle="Home"
    grandparentLink="/"
    parentTitle="Press Releases"
    parentLink="/foursight/press-release"
    [childTitle]="art.title"
  ></app-breadcrumbs-white>
  <div class="container">
    <div>
      <ng-container *ngIf="art.newsletterLink">
        <a class="clearLink" (click)="backClicked(art.newsletterLink)"
          ><img src="../../../assets/icons/icon-arrow-green.svg" alt="" />
          Back</a
        >
      </ng-container>
    </div>
    <br />
    <h1>{{ art.title }}</h1>
    <div class="carousel">
      <img [src]="getImg(art.bannerImg)" alt="" />
    </div>

    <div class="row">
      <div class="large-col articleParagraphs">
        <p [innerHTML]="_returnHtmlFromRichText(art.content)"></p>
        <iframe
          loading="lazy"
          *ngIf="art.videoId"
          height="500"
          style="width: 100%; margin-bottom: 20px"
          [src]="getURL(art.videoId)"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <a class="clearLink" [routerLink]="['/foursight/press-release']"
          ><img src="../../../assets/icons/icon-arrow-green.svg" alt="" />Read
          more articles</a
        >
      </div>
    </div>
  </div>
</div>
