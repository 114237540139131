<div class="header-container-bg">
  <div class="header-container">
    <a class="logo" [routerLink]="['/']" (click)="closeAll()"
      ><img src="../../../assets/logo/logo.svg" alt=""
    /></a>
  
    <a class="mobile-logo" [routerLink]="['/']" (click)="closeAll()"
      ><img src="../../../assets/logo/mobile-logo.svg" alt=""
    /></a>
  
    <div class="menu-items-container" >
      <div
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="showMenu('about')"
      >
        About Fortress
        <img
          [ngClass]="{ open: showSubMenu.about }"
          src="../../../assets/icons/icon-menu-arrow.svg"
          alt=""
        />
        <a style="display: none" routerLink="/about/our-impact"></a>
        <a style="display: none" routerLink="/about/about-us"></a>
        <a style="display: none" routerLink="/about/meet-the-team"></a>
        <a style="display: none" routerLink="/about/corporate-governance"></a>
        <a style="display: none" routerLink="/about/paia"></a>
      </div>
  
      <div
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="showMenu('sustainability')"
      >
        Sustainability
        <img
          [ngClass]="{ open: showSubMenu.sustainability }"
          src="../../../assets/icons/icon-menu-arrow.svg"
          alt=""
        />
        <a style="display: none" routerLink="/sustainability/our-impact"></a>
        <a style="display: none" routerLink="/sustainability/social"></a>
      </div>
  
      <div routerLinkActive="active-link" (click)="showMenu('properties')">
        Our Properties
        <img
          [ngClass]="{ open: showSubMenu.properties }"
          src="../../../assets/icons/icon-menu-arrow.svg"
          alt=""
        />
        <a style="display: none" routerLink="/properties/new-developments"></a>
        <a
          style="display: none"
          routerLink="/properties/schedule-of-properties"
        ></a>
        <a style="display: none" routerLink="/properties/property-portfolio"></a>
      </div>
  
      <div routerLinkActive="active-link" (click)="showMenu('investor')">
        Investor Relations
        <img
          [ngClass]="{ open: showSubMenu.investor }"
          src="../../../assets/icons/icon-menu-arrow.svg"
          alt=""
        />
        <a style="display: none" routerLink="/investor-relations/overview"></a>
        <a
          style="display: none"
          routerLink="/investor-relations/investor-documents"
        ></a>
        <a
          style="display: none"
          routerLink="/investor-relations/shareholder-analysis"
        ></a>
        <a
          style="display: none"
          routerLink="/investor-relations/upcoming-events"
        ></a>
      </div>
  
      <div routerLinkActive="active-link" (click)="showMenu('foursight')">
        FOURsight News
        <img
          [ngClass]="{ open: showSubMenu.foursight }"
          src="../../../assets/icons/icon-menu-arrow.svg"
          alt=""
        />
        <a style="display: none" routerLink="/foursight"></a>
        <a style="display: none" routerLink="/foursight/press-release"></a>
        <a style="display: none" routerLink="/foursight/videos"></a>
        <!-- <a style="display: none" routerLink="/event"></a> -->
      </div>
  
      <div routerLinkActive="active-link" (click)="showMenu('login')">
        Brokers<img
          [ngClass]="{ open: showSubMenu.login }"
          src="../../../assets/icons/icon-menu-arrow.svg"
          alt=""
        />
        <a style="display: none" routerLink="/login"></a>
        <a style="display: none" routerLink="/registration"></a>
        <a style="display: none" routerLink="/vacancies"></a>
      </div>
    </div>
    <div class="interact-menu-container">
      <!--<a [routerLink]="['/']"><img src="../../../assets/icons/icon-magnifier.svg" alt=""/></a>-->
      <a class="get-in-touch-icon" [routerLink]="['/get-in-touch']">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2211 15.5571H9.7761C6.58389 15.5571 3.99609 12.9693 3.99609 9.77708V9.77708C3.99609 6.58487 6.58389 3.99707 9.7761 3.99707H13.6001C17.1362 3.99707 20.0028 6.86364 20.0028 10.3997V10.3997C20.0053 12.6233 18.854 14.689 16.9615 15.8564L10.2211 20.0037V15.5571"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
  
      <!--<a (click)="showMenu('login')"><img src="../../../assets/icons/icon-profile.svg" alt=""/></a>-->
    </div>
    <div
      class="hamburger-menu"
      (click)="toggleMenu()"
      [ngClass]="{ openMenu: burgerMenu }"
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>


<div class="mobile-menu" *ngIf="burgerMenu">
  <div class="inner">
    <!--About us-->
    <a class="mobile-nav-link" (click)="toggleSubLinks(1)"
      >About Fortress
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
          stroke="#00C5CB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="mobile-nav-inner" [class.active]="showSubLinks1">
        <a
          class="heading-back-link"
          (click)="toggleSubLinks(1); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          About Fortress</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/about/about-us']"
          (click)="closeAll()"
          >About us</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/about/meet-the-team']"
          (click)="closeAll()"
          >Meet the team</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/about/corporate-governance']"
          (click)="closeAll()"
          >Corporate Governance</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/about/paia']"
          (click)="closeAll()"
          >PAIA</a
        >
      </div>
    </a>

    <!--Sustainability-->
    <a class="mobile-nav-link" (click)="toggleSubLinks(2)"
      >Sustainability
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
          stroke="#00C5CB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="mobile-nav-inner" [class.active]="showSubLinks2">
        <a
          class="heading-back-link"
          (click)="toggleSubLinks(2); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Sustainability</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/sustainability/our-impact']"
          (click)="closeAll()"
          >Our impact</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/sustainability/social']"
          (click)="closeAll()"
          >Social</a
        >
      </div>
    </a>

    <!--Our properties-->
    <a class="mobile-nav-link" (click)="toggleSubLinks(3)"
      >Our properties
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
          stroke="#00C5CB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="mobile-nav-inner" [class.active]="showSubLinks3">
        <a
          class="heading-back-link"
          (click)="toggleSubLinks(3); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Our properties</a
        >

        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/new-developments']"
          (click)="closeAll()"
          >New Developments</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/schedule-of-properties']"
          (click)="closeAll()"
          >Schedule Of Properties</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          (click)="closeAll()"
          >Our Property Portfolio</a
        >

        <a
          class="heading-back-link"
          (click)="toggleSubLinks(3); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Property type</a
        >

        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          (click)="closeAll()"
          >All</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          [queryParams]="{ type: 'logistics' }"
          (click)="closeAll()"
          >Logistics</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          [queryParams]="{ type: 'office' }"
          (click)="closeAll()"
          >Office</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          [queryParams]="{ type: 'retail' }"
          (click)="closeAll()"
          >Retail</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          [queryParams]="{ type: 'industrial' }"
          (click)="closeAll()"
          >Industrial</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/property-portfolio']"
          [queryParams]="{ type: 'CEELogisitics' }"
          (click)="closeAll()"
          >CEE Logisitics</a
        >
      </div>
    </a>

    <!--Investor relations-->
    <a class="mobile-nav-link" (click)="toggleSubLinks(4)"
      >Investor relations
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
          stroke="#00C5CB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="mobile-nav-inner" [class.active]="showSubLinks4">
        <a
          class="heading-back-link"
          (click)="toggleSubLinks(4); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Investor relations</a
        >

        <a
          class="mobile-sub-link"
          [routerLink]="['/properties/new-developments']"
          (click)="closeAll()"
          >New Developments</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/overview']"
          (click)="closeAll()"
          >Portfolio Statistics</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          (click)="closeAll()"
          >All Investor Documents</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/shareholder-analysis']"
          (click)="closeAll()"
          >Shareholder Analysis</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/upcoming-events']"
          (click)="closeAll()"
          >Upcoming Events</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'annual-reports' }"
          (click)="closeAll()"
          >Integrated Financial Reports</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'presentations' }"
          (click)="closeAll()"
          >Results Presentation</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'webcast' }"
          (click)="closeAll()"
          >Webcast</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'dmtn-programme' }"
          (click)="closeAll()"
          >DMTN Programme</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'bbbee-certification' }"
          (click)="closeAll()"
          >BBBEE Certification</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'circulars' }"
          (click)="closeAll()"
          >Circulars</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'right-offers' }"
          (click)="closeAll()"
          >Rights Offers</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'moi-capital-conversion' }"
          (click)="closeAll()"
          >MOI & Capital Conversion</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/investor-relations/investor-documents']"
          [queryParams]="{ document: 'prospectus-listing-particulars' }"
          (click)="closeAll()"
          >Prospectus & Listing Particulars</a
        >
      </div>
    </a>

    <!--FOURsight-->
    <a class="mobile-nav-link" (click)="toggleSubLinks(5)"
      >FOURsight News
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
          stroke="#00C5CB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="mobile-nav-inner" [class.active]="showSubLinks5">
        <a
          class="heading-back-link"
          (click)="toggleSubLinks(5); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          FOURsight News</a
        >

        <a
          class="mobile-sub-link"
          [routerLink]="['/foursight']"
          (click)="closeAll()"
          >FOURsight</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/foursight/press-release']"
          (click)="closeAll()"
          >Press Releases</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/foursight/videos']"
          (click)="closeAll()"
          >Videos</a
        >
        <!-- <a class="mobile-sub-link" [routerLink]="['/foursight/events']" (click)="closeAll()">Events</a> -->
      </div>
    </a>

    <!--Brokers-->
    <a class="mobile-nav-link" (click)="toggleSubLinks(6)"
      >Brokers
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
          stroke="#00C5CB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="mobile-nav-inner" [class.active]="showSubLinks6">
        <a
          class="heading-back-link"
          (click)="toggleSubLinks(6); $event.stopPropagation()"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33341 6.66675L11.6667 10.0001L8.33341 13.3334"
              stroke="#00C5CB"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Brokers</a
        >

        <a
          class="mobile-sub-link"
          [routerLink]="['/login']"
          (click)="closeAll()"
          >Login</a
        >
        <a
          class="mobile-sub-link"
          [routerLink]="['/registration']"
          (click)="closeAll()"
          >Registration</a
        >
      </div>
    </a>
    <div class="interact-menu-container-mobile">
      <a [routerLink]="['/get-in-touch']"
        ><img src="../../../assets/icons/icon-chat.svg" alt=""
      /></a>
      <a
        href="https://www.linkedin.com/company/fortressreitlimited/"
        target="_blank"
        ><img src="../../../assets/icons/icon-linkedin.svg" alt=""
      /></a>
      <a href="tel:+27112822800" class="icon-link"
        ><img src="../../../assets/icons/icon-phone.svg" alt=""
      /></a>
    </div>
  </div>
  <!-- <div (click)="showMenuMobile('about')">About Fortress <img [ngClass]="{'open': showSubMenuMobile.about}" src="../../../assets/icons/icon-menu-arrow.svg" alt=""/></div>
    <div class="sub-menu-holder" *ngIf="showSubMenuMobile.about">
        <app-about-banner (onClose)="execOnClose($event)"></app-about-banner>
    </div>

    <div (click)="showMenuMobile('sustainability')">Sustainability <img [ngClass]="{'open': showSubMenuMobile.sustainability}" src="../../../assets/icons/icon-menu-arrow.svg" alt=""/></div>
    <div class="sub-menu-holder" *ngIf="showSubMenuMobile.sustainability">
        <app-sustainability-banner (onClose)="execOnClose($event)"></app-sustainability-banner>
    </div>

    <div (click)="showMenuMobile('properties')">Our properties <img [ngClass]="{'open': showSubMenuMobile.properties}" src="../../../assets/icons/icon-menu-arrow.svg" alt=""/></div>
    <div class="sub-menu-holder" *ngIf="showSubMenuMobile.properties">
        <app-properties-banner (onClose)="execOnClose($event)"></app-properties-banner>
    </div>

    <div (click)="showMenuMobile('investor')">Investor Relations <img [ngClass]="{'open': showSubMenuMobile.investor}" src="../../../assets/icons/icon-menu-arrow.svg" alt=""/></div>
    <div class="sub-menu-holder" *ngIf="showSubMenuMobile.investor">
        <app-investor-banner (onClose)="execOnClose($event)"></app-investor-banner>
    </div>

    <div (click)="showMenuMobile('foursight')">Foursight<img [ngClass]="{'open': showSubMenuMobile.foursight}" src="../../../assets/icons/icon-menu-arrow.svg" alt=""/></div>
    <div class="sub-menu-holder" *ngIf="showSubMenuMobile.foursight">
        <app-foursight-banner (onClose)="execOnClose($event)"></app-foursight-banner>
    </div>
    <a [routerLink]="['/login']">Login</a>
    <a [routerLink]="['/registration']">Registration</a>
    <div class="interact-menu-container-mobile">
        <a [routerLink]="['/get-in-touch']"><img src="../../../assets/icons/icon-chat.svg" alt=""/></a>
        <a href="https://www.linkedin.com/company/fortressreitlimited/" target="_blank"><img src="../../../assets/icons/icon-linkedin.svg" alt=""/></a>
        <a href="tel:+27112822800" class="icon-link"><img src="../../../assets/icons/icon-phone.svg" alt=""/></a>
    </div> -->
</div>

<div
  class="sub-menu-holder"
  *ngIf="showSubMenu.about"
  [ngClass]="{ visi: showSubMenu.about }"
>
  <app-about-banner (onClose)="execOnClose($event)"></app-about-banner>
</div>

<div
  class="sub-menu-holder"
  *ngIf="showSubMenu.sustainability"
  [ngClass]="{ visi: showSubMenu.sustainability }"
>
  <app-sustainability-banner
    (onClose)="execOnClose($event)"
  ></app-sustainability-banner>
</div>

<div class="sub-menu-holder" *ngIf="showSubMenu.properties">
  <app-properties-banner
    (onClose)="execOnClose($event)"
  ></app-properties-banner>
</div>
<div class="sub-menu-holder" *ngIf="showSubMenu.investor">
  <app-investor-banner (onClose)="execOnClose($event)"></app-investor-banner>
</div>
<div class="sub-menu-holder" *ngIf="showSubMenu.login">
  <app-login-banner (onClose)="execOnClose($event)"></app-login-banner>
</div>
<div class="sub-menu-holder" *ngIf="showSubMenu.foursight">
  <app-foursight-banner (onClose)="execOnClose($event)"></app-foursight-banner>
</div>

<div class="gradient-background" *ngIf="showSubMenu.showGradient" (click)="showMenu('empty')"></div>

<div class="header-spacer"></div>