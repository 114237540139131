<div  class="sub-menu">
    <div class="col line-right">
        <a (click)="onclicked()" [routerLink]="['/investor-relations/overview']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Portfolio Statistics</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">All Investor Documents</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/shareholder-analysis']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Shareholder Analysis</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/upcoming-events']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Upcoming Events</a>
    </div>
    <div class="col">
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'annual-reports' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Integrated Financial Reports</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'presentations' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Results Presentation</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'webcast' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Webcast</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'dmtn-programme' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">DMTN Programme</a>
    </div>
    <div class="col">
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'bbbee-certification' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">BBBEE Certification</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'circulars' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Circulars</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'right-offers' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Rights Offers</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'moi-capital-conversion' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">MOI & Capital Conversion</a>
        <a (click)="onclicked()" [routerLink]="['/investor-relations/investor-documents']" [queryParams]="{ document: 'prospectus-listing-particulars' }" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Prospectus & Listing Particulars</a>
    </div>
    <div class="col padding-top">
        <app-contact-header></app-contact-header>
    </div>
</div>