import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sus-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class SusSocial implements OnInit {

  activeTab: string = 'tab1';

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    dots: true,
    variableWidth: false,
    responsive: [
      {
        // tablet
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
    prevArrow: `<svg class="slick-arrow previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow:`<svg class="slick-arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
  };


  slideConfigMobile = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    centerMode: true,
    centerPadding: '20px',
    dots: true,
    variableWidth: false,
    prevArrow: `<svg class="slick-arrow previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow:`<svg class="slick-arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
  };

  slideConfigMobile2 = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    dots: true,
    variableWidth: false,

    responsive: [
      {
        // tablet
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '10px',
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '10px',
        }
      }
    ],
    prevArrow: `<svg class="slick-arrow previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow:`<svg class="slick-arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
  };

  afterChange(e: any) {
   
    var iframes = document.querySelectorAll('iframe');
    
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
      func: 'pauseVideo' }), '*');
      });
  }

  getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }


  constructor() { }

  ngOnInit(): void {
  }

  slide1IconImages = [
    './assets/images/our-social-icons/icon-1.png',
    './assets/images/our-social-icons/icon-2.png',
    './assets/images/our-social-icons/icon-3.png',
    './assets/images/our-social-icons/icon-4.png',
    './assets/images/our-social-icons/icon-5.png',
    './assets/images/our-social-icons/icon-6.png',
    './assets/images/our-social-icons/icon-7.png',
    './assets/images/our-social-icons/icon-8.png',
    './assets/images/our-social-icons/icon-9.png',
    './assets/images/our-social-icons/icon-10.png',
  ];

  activeSlide1Index: number = 0;
  desiredIndex: number = 0;

  afterChange1(e: any) {
    this.activeSlide1Index = e.currentSlide;
  }



}
