<div class="bg">
    <div class="container">
        <div class="content">
            <h1 class="main-title-colour">Let’s get</h1>
            <h1 class="main-title">in touch</h1>
        </div>
    </div>
    <div class="form-container">
        <div class="form-inner">
            <div class="col">
                <img src="../../../../assets/icons/icon-mail-large.svg" alt=""/>
                <p>tell us about yourself</p>
            </div>
            <div class="col">
                <p class="success" *ngIf="sentSuccess">Your message has been sent successfully, someone will be in contact soon.</p>
                <form *ngIf="!sentSuccess" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <p>Name</p>
                    <input id="first-name" type="text" formControlName="firstName">
                    <div class="hr"></div>
                    <p>interests</p>
                    <div class="checkOptions">
                        <div [ngClass]="{'active' : activeDevelopments}" (click)="toggleOption('Developments')" class="option">Developments<img *ngIf="activeDevelopments" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeBroker}" (click)="toggleOption('Broker')" class="option">Broker Info<img *ngIf="activeBroker" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeInvesting}" (click)="toggleOption('Investing')" class="option">Investing<img *ngIf="activeInvesting" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeProperties}" (click)="toggleOption('Properties')" class="option">Properties<img *ngIf="activeProperties" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeCareers}" (click)="toggleOption('Careers')" class="option">Careers<img *ngIf="activeCareers" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeVendor}" (click)="toggleOption('Vendor')" class="option">Vendor Enquiries<img *ngIf="activeVendor" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeContractor}" (click)="toggleOption('Contractor')" class="option">Contractor Info<img *ngIf="activeContractor" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                        <div [ngClass]="{'active' : activeGeneral}" (click)="toggleOption('General')" class="option">General<img *ngIf="activeGeneral" src="../../../../assets/icons/icon-close.svg" alt=""/></div>
                    </div>
                    <div class="hr"></div>
                    <p>Preferred contact method</p>
                    <div class="contactMethod">
                        <label>
                            <input (click)="toggleContact('email')" type="radio" value="email" formControlName="contactmethod">
                            <span>Email</span>
                        </label>
                        <label>
                            <input (click)="toggleContact('phone')" type="radio" value="phone" formControlName="contactmethod">
                            <span>Phone call</span>
                        </label>
                    </div>
                    
                    <input type="text" *ngIf="activeEmail" placeholder="Email" formControlName="contactEmail">
                    <input type="text" *ngIf="activePhone" placeholder="Phone number" formControlName="contactPhone">

                    <div class="hr"></div>
                    <p>Additional Notes</p>
                    <textarea id="first-name" type="text" formControlName="notes"></textarea>
                    <button class="primary-btn-arrow" type="submit" [disabled]="!profileForm.valid">Send query</button>
                </form>
              
               
            </div>
        </div>
    </div>

    <app-reit-with-a-difference-blocks
        title = "About Fortress"
        desc = "Explore what it is that makes us different, or take a look through our property portfolio."
        box1icon = "../../../assets/images/details-callout.svg"
        box1Title = "Find our Details"
        box1desc = ""
        box1btnText = "Learn more"
        box1link = "/our-details"
        box2icon = "../../../assets/images/whistle-callout.svg"
        box2Title = "Whistleblowers"
        box2desc = "To improve transparency and ensure the highest levels of corporate governance, we have set up an independent whistleblowers’ hotline."
        box2btnText = "Click here to find out more"
        box2link = "/whistleblower"
    ></app-reit-with-a-difference-blocks>

    <app-subscribe-banner></app-subscribe-banner>

</div>