<div class="container">
    <div class="menu-select">
        <h3>Our Ambition</h3>
        <div class="topic">
            <p class="subTitle">for the environment</p>
            <h2 class="title">OUR ENVIRONMENTAL IMPACT AMBITION</h2>
            <p class="text">We are committed to operating a sustainable business. Our main focus to date has been in solar energy instalments at our properties. We seek to integrate renewable energy practices into all facets of our business. We strive to reduce our impact on the environment, increase the benefit we bring to clients and society and create long-term value for all our stakeholders.</p>
           <!-- <div class="btn-holder">
                <a (click)="openTopic()" class="clearLink">Learn more<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
            </div>-->
        </div>
        <div class="topic">
            <p class="subTitle">for the COMMUNITY</p>
            <h2 class="title">OUR COMMUNITY IMPACT AMBITION</h2>
            <p class="text">Fortress is committed to purposeful and active corporate citizenship. We have an established programme of supporting several community-based and charitable organisations in the areas in which our properties are located to uplift those communities with solution initiatives that result in long-term </p>
           
        </div>
        <div class="topic">
            <p class="subTitle">for the people</p>
            <h2 class="title">PEOPLE AMBITION</h2>
            <p class="text">Our employees are a fundamental part of our business. Their specialised knowledge, skills and experience are essential for the business’ long-term success. Therefore, we aim to attract and retain motivated, high-calibre executives and employees. We leverage our employees’ skills to ensure sound management of our properties. Investing in our people, challenging them and developing their capabilities will ensure our business’ sustainability.</p>
            
        </div>
        <div class="topic">
            <p class="subTitle">for the economy</p>
            <h2 class="title">OUR ECONOMY IMPACT AMBITION</h2>
            <p class="text">Through our ongoing commitments to occupational health and safety, diversity and equal opportunities, economic inclusion, employee training and education, as well as various other commitments, we contribute to the inclusive and sustainable growth of the South African economy and decent jobs for all.</p>
            
        </div>
    </div>
    <div class="pull-out-container">
        <h3 *ngIf="!showTopic">Our Initiatives</h3>
        <div class="row" *ngIf="!showTopic">
            <div class="block">
                <div class="top" style="background-image: url('../../../../../assets/images/linkdin/wetlands.webp');">
                    <p class="topic">#WorldWetlandsDay #ActForWetlands</p>
                </div>
                <div class="bottom">
                    <div>
                        <p class="tag">Linkedin</p>
                        <h4>World wetlands day initiative</h4>
                    </div>
                    <a class="clearLink" href="https://www.linkedin.com/posts/fortressreitlimited_logistics-worldwetlandsday-actforwetlands-activity-6894537298541977600-O96S/" target="_blank">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <div class="top" style="background-image: url('../../../../../assets/images/linkdin/bicycles.webp');">
                    <p class="topic">#PoweringGrowth</p>
                </div>
                <div class="bottom">
                    <div>
                        <p class="tag">Linkedin</p>
                        <h4>Community Police Forum (CPF) bicycles initiative</h4>
                    </div>
                    <a class="clearLink" href="https://www.linkedin.com/posts/fortressreitlimited_poweringgrowth-activity-6875060038315085824-osfQ/" target="_blank">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <div class="top" style="background-image: url('../../../../../assets/images/linkdin/food.webp');">
                    <p class="topic">#PoweringGrowth</p>
                </div>
                <div class="bottom">
                    <div>
                        <p class="tag">Linkedin</p>
                        <h4>Phase 2 of the Sterkspruit Plaza Food & Trees for Africa project has kicked off!</h4>
                    </div>
                    <a class="clearLink" href="https://www.linkedin.com/posts/fortressreitlimited_phase-2-of-the-sterkspruit-plaza-food-trees-activity-6872484223974961153-iqid/" target="_blank">Read More<img src="../../../assets/icons/icon-arrow-green.svg" alt=""></a>
                </div>
            </div>
            <div class="block">
                <div class="top" style="background-image: url('../../../../../assets/images/linkdin/game.webp');">
                    <p class="topic">#FortressRetail #sustainability #renewableenergy #solarenergy</p>
                </div>
                <div class="bottom">
                    <div>
                        <p class="tag">Linkedin</p>
                        <h4>We have launched a total of R900 million sustainability-linked bonds</h4>
                    </div>
                    <a class="clearLink" href="https://www.linkedin.com/feed/update/urn:li:activity:6830830458851360768/" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</div>