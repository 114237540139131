import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { ContentfulService } from "../../../services/contentful.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from "@angular/common";


@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss']
})
export class AllComponent implements OnInit {
  searchText:string='';
  document: string = '';
 
dateRange=[
  {name: "2022"},
  {name: "2021"},
  {name: "2020"},
  {name: "2019"},
  {name: "2018"},
];

showDates:boolean=false;
selectedDate:string = this.dateRange[0].name;

lessons$!: Observable<any>;

currentIndex = -1;
title = '';
page = 1;
count = 0;
pageSize = 6;
pageSizes = [6, 12, 18];

  constructor(private scroller: ViewportScroller,private contentful: ContentfulService, private route: ActivatedRoute,private router: Router) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.queryParams.subscribe(params => {
      this.document = params.document;
    });
    if (this.document !== undefined){
      this.scroller.scrollToAnchor("target");
      switch (this.document){
        case 'sens':
                this.selectCategory(1);
                break;
        case 'annual-reports':
                this.selectCategory(2);
                break;
        case 'interim-reports':
                this.selectCategory(3);
                break;
        case 'presentations':
                this.selectCategory(4);
                break;
        case 'webcast':
                this.selectCategory(5);
                break;
        case 'dmtn-programme':
                this.selectCategory(6);
                break;
        case 'bbbee-certification':
                this.selectCategory(7);
                break;
        case 'circulars':
                this.selectCategory(8);
                break;
        case 'right-offers':
                this.selectCategory(9);
                break;
        case 'moi-capital-conversion':
                this.selectCategory(10);
                break;
        case 'prospectus-listing-particulars':
                this.selectCategory(11);
                break;
      }
     }else{

     }
  }

  ngOnInit(): void {
    this.lessons$ = this.contentful.getContents();
   
   
    


  }

  

  handlePageChange(event: number): void {
    this.page = event;
  }


  toggleDate(){
    this.showDates =!this.showDates;
  }

  selectDate(date:string){
    this.selectedDate = date;
    this.toggleDate();
  }

  selectCategory(index:number){
    switch (index){
      case 0:
              this.searchText="";
              break;
      case 1:
              this.searchText="SENS";
              break;
      case 2:
              this.searchText="Annual Reports";
              break;
      case 3:
              this.searchText="Interim Reports";
              break;
      case 4:
              this.searchText="Presentations";
              break;
      case 5:
              this.searchText="Webcast";
              break;
      case 6:
              this.searchText="DMTN Programme";
              break;
      case 7:
              this.searchText="BBBEE Certification";
              break;
      case 8:
              this.searchText="Circulars";
              break;
      case 9:
              this.searchText="Right Offers";
              break;
      case 10:
              this.searchText="MOI & Capital Conversion";
              break;
      case 11:
              this.searchText="Prospectus & Listing Particulars";
              break;
    }
  }

  getName(name:string){
    return "View";
  }

  getURL(url:string){
    let newURL = "https:"+url;
    return newURL;
  }

  getImage(cat:string){
    let img = "";
    switch(cat) {
      case "SENS": 
          img = "../../../../../assets/icons/document/SENS.svg";
          break;
      case "Annual Reports": 
          img = " ../../../../../assets/icons/document/annual.svg";
          break;
      case "Interim Reports": 
          img = " ../../../../../assets/icons/document/interim.svg";
          break;
      case "Presentations":   
          img = " ../../../../../assets/icons/document/presentation.svg";
          break;
      case "Webcast":         
          img = " ../../../../../assets/icons/document/webcast.svg";
          break;
      case "DMTN Programme":  
          img = " ../../../../../assets/icons/document/dmtn.svg";
          break;
      case "BBBEE Certificate": 
          img = " ../../../../../assets/icons/document/bbbee.svg";
          break;
      case "Circulars": 
          img = " ../../../../../assets/icons/document/circulars.svg";
          break;
      case "Right Offers": 
          img = " ../../../../../assets/icons/document/offers.svg";
          break;
      case "MOI & Capital Conversion": 
          img = " ../../../../../assets/icons/document/moi.svg";
          break;
      case "Prospectus & Listing Particulars": 
          img = " ../../../../../assets/icons/document/prospectus.svg";
          break;
    }
    return img;
  }

  clearFilter(){
    this.searchText='';
  }

}
