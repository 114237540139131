import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-content',
  templateUrl: './hero-content.component.html',
  styleUrls: ['./hero-content.component.scss'],
})
export class HeroContentComponent implements OnInit {
  @Input() smallTopTitle: string = 'this is undefined';
  @Input() title: string = 'this is undefined';
  @Input() description: string = 'this is undefined';
  @Input() heroImage: string | null = null;
  @Input() appBreadcrumbs: any | null = null;

  constructor() {}

  ngOnInit(): void {
    console.log(this.appBreadcrumbs);
    
  }
}
