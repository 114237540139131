import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-details',
  templateUrl: './our-details.component.html',
  styleUrls: ['./our-details.component.scss']
})
export class OurDetailsComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
  search='';


  folderObjs=[{
    name:'john doe',
    phone:'+27 11 000 0000',
    email:'johndoe@fortress.com'
  },{
    name:'john smith',
    phone:'+27 11 000 0000',
    email:'johnsmith@fortress.com'
  },{
    name:'test name',
    phone:'+27 11 000 0000',
    email:'testname@fortress.com'
  }]




  clear(){
    this.search='';
  }
}
