import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() grandparentTitle: string = "";
  @Input() grandparentLink: string = "";
  @Input() parentTitle: string = "";
  @Input() parentLink: string = "";
  @Input() anotherLink: string = "";
  @Input() anotherTitle: string = "";
  @Input() childTitle: string = "";
  @Input() isLarge: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
