import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByGLA'
})
export class SortByGLAPipe implements PipeTransform {
  transform(array: any[]): any[] {
    if (!array || array.length === 0) {
      return array;
    }

    return array.slice().sort((a, b) => b.buildingGla - a.buildingGla);
  }
}
