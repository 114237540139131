import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import {ReactiveFormsModule} from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home.component';
import { HeaderComponent } from './core/header/header.component';
import { ContactHeaderComponent } from './shared/contact-header/contact-header.component';
import { AboutBannerComponent } from './core/header/about-banner/about-banner.component';
import { SustainabilityBannerComponent } from "./core/header/sustainability-banner/sustainability-banner.component";
import { PropertiesBannerComponent } from './core/header/properties-banner/properties-banner.component';
import { InvestorBannerComponent } from './core/header/investor-banner/investor-banner.component';
import { LoginBannerComponent } from './core/header/login-banner/login-banner.component';
import { FooterComponent } from './core/footer/footer.component';
import { PopularLinksComponent } from "./shared/popular-links/popular-links.component";
import { GetInTouchBannerComponent } from './shared/get-in-touch-banner/get-in-touch-banner.component';
import { SubscribeBannerComponent } from './shared/subscribe-banner/subscribe-banner.component';
import { AboutCalloutBlocksComponent } from './shared/about-callout-blocks/about-callout-blocks.component';
import { ReitWithADifferenceBlocksComponent } from './shared/reit-with-a-difference-blocks/reit-with-a-difference-blocks.component';
import { TestimonialComponent } from './shared/testimonial/testimonial.component';
import { OurImpactComponent } from './modules/about/our-impact/our-impact.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { AboutPageBannerComponent } from './shared/about-page-banner/about-page-banner.component';
import { AboutTwoBlockIntroComponent } from './shared/about-two-block-intro/about-two-block-intro.component';
import { StaggeredFourBlocksComponent } from './shared/staggered-four-blocks/staggered-four-blocks.component';
import { WhiteImageTitleLinkBlockComponent } from './shared/white-image-title-link-block/white-image-title-link-block.component';
import { SharesBlockComponent } from './shared/shares-block/shares-block.component';
import { OurHistoryComponent } from './modules/about/our-history/our-history.component';
import { SouthafricaComponent } from './modules/about/our-history/map/southafrica/southafrica.component';
import { EasterneuropeComponent } from './modules/about/our-history/map/easterneurope/easterneurope.component';
import { FootprintComponent } from './modules/about/our-history/footprint/footprint.component';
import { ContactUsComponent } from './modules/contact/contact-us/contact-us.component';
import { OurDetailsComponent } from './modules/contact/our-details/our-details.component';
import { WhistleblowerComponent } from './modules/contact/whistleblower/whistleblower.component';
import { FilterPipe } from './filter.pipe'
import { FilterDocsPipe } from './pipes/filter-docs.pipe'
import { FormsModule } from '@angular/forms';
import { SliderComponent } from './modules/about/our-history/slider/slider.component';
import { MissionBlocksComponent } from './modules/about/our-history/mission-blocks/mission-blocks.component';
import { WhiteIconTitleTextButtonBlockComponent } from './shared/white-icon-title-text-button-block/white-icon-title-text-button-block.component';
import { AwardsComponent } from './modules/about/our-history/awards/awards.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { MeetTheTeamComponent } from './modules/about/meet-the-team/meet-the-team.component';
import { FoursightComponent } from './modules/foursight/foursight.component';
import { BannerFoursightComponent } from './modules/foursight/banner-foursight/banner-foursight.component';
import { TeamBlocksComponent } from './modules/about/meet-the-team/team-blocks/team-blocks.component';
import { NewDevelopmentsComponent } from './modules/properties/new-developments/new-developments.component';
import { TitleColComponent } from './modules/properties/components/title-col/title-col.component';
import { SolutionsPropertiesComponent } from './modules/properties/components/solutions-properties/solutions-properties.component';
import { StatsPropertiesComponent } from './modules/properties/components/stats-properties/stats-properties.component';
import { InvestorDocumentsComponent } from './modules/investor-relations/investor-documents/investor-documents.component';
import { DocumentsSearchFilterComponent } from './modules/investor-relations/components/documents-search-filter/documents-search-filter.component';
import { ContentfulService } from './services/contentful.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { VideoComponent } from './shared/video/video.component';
import { FoursightNewsComponent } from './shared/foursight-news/foursight-news.component';
import { PowerOfCollabComponent } from './modules/about/our-impact/power-of-collab/power-of-collab.component';
import { OverviewComponent } from './modules/investor-relations/overview/overview.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ChartsComponent } from './modules/investor-relations/components/charts/charts.component';
import { BreadcrumbsWhiteComponent } from './shared/breadcrumbs-white/breadcrumbs-white.component';
import { LoginComponent } from './core/login/login.component';
import { VacanciesComponent } from './modules/vacancies/vacancies.component';
import { AuthenticationService } from './services/authentication.service';
import { RegistrationComponent } from './core/registration/registration.component';
import { ScheduleOfPropertiesComponent } from './modules/properties/schedule-of-properties/schedule-of-properties.component';
import { ScheduleTableComponent } from './modules/properties/components/schedule-table/schedule-table.component'; 
import {SearchFilterPipe} from './search-filter.pipe';
import {SearchFilterPropertiesPipe} from './search-filter-properties.pipe';
import {SafePipe} from './safe.pipe';
import { IndividualPropertyComponent } from './modules/properties/individual-property/individual-property.component';
import { PropertyTitleBlockComponent } from './modules/properties/components/property-title-block/property-title-block.component';
import { PropertyGalleryBlockComponent } from './modules/properties/components/property-gallery-block/property-gallery-block.component';
import { PropertyAddressBlockComponent } from './modules/properties/components/property-address-block/property-address-block.component';
import { SelectedProvincesComponent } from './modules/about/our-history/map/selected-provinces/selected-provinces.component';
import { OurAmbitionComponent } from './modules/about/components/our-ambition/our-ambition.component';
import { PropertyPortfolioComponent } from './modules/properties/property-portfolio/property-portfolio.component';
import { SearchFilterAllPropertiesComponent } from './modules/properties/components/search-filter-all-properties/search-filter-all-properties.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FeaturedPropertiesComponent } from './shared/featured-properties/featured-properties.component';
import { ExploreOurPropertiesComponent } from './shared/explore-our-properties/explore-our-properties.component';
import { StatsComponent } from './modules/investor-relations/components/stats/stats.component';
import { AllComponent } from './modules/foursight/all/all.component';
import { HttpClientModule } from '@angular/common/http';
import { DraftsComponent } from './modules/investor-relations/drafts/drafts.component';
import { LoginDraftComponent } from './modules/investor-relations/login-draft/login-draft.component';
import { ShareholderAnalysisComponent } from './modules/investor-relations/shareholder-analysis/shareholder-analysis.component';
import { UpcomingEventsComponent } from './modules/investor-relations/upcoming-events/upcoming-events.component';
import {NgPipesModule} from 'ngx-pipes';
import { FoursightBannerComponent } from './core/header/foursight-banner/foursight-banner.component';
import { VideosComponent } from './modules/foursight/videos/videos.component';
import { PressReleaseComponent } from './modules/foursight/press-release/press-release.component';
import { WebcastsComponent } from './modules/investor-relations/components/webcasts/webcasts.component';
import { ArticleComponent } from './modules/investor-relations/components/article/article.component';
import { EditionsComponent } from './modules/foursight/editions/editions.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from './_modal';
import { CorporateGovernanceComponent } from './modules/about/corporate-governance/corporate-governance.component';
  
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { CompanyPaiComponent } from './modules/about/company-pai/company-pai.component';
import { IndividualPropertiesVacanciesComponent } from './modules/properties/individual-properties-vacancies/individual-properties-vacancies.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { DataAccessComponent } from './modules/data-access/data-access.component';
import { SimilarPropertiesComponent } from './modules/properties/components/similar-properties/similar-properties.component';
import { VideoVrComponent } from './shared/video-vr/video-vr.component';
import { FormEnquiryComponent } from './modules/properties/components/form-enquiry/form-enquiry.component';
import { SuccessComponent } from './modules/form/success/success.component';
import { FailureComponent } from './modules/form/failure/failure.component';
import { ViewPdfComponent } from './modules/investor-relations/view-pdf/view-pdf.component';
import { EventsComponent } from './modules/foursight/events/events.component';
import { TestComponent } from './modules/form/test/test.component';
import { SusOurImpact } from "./modules/sustainability/our-impact/our-impact.component";
import { SusSocial } from "./modules/sustainability/social/social.component";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { SortByGLAPipe } from "./pipes/SortByGLA";
import { SponsorshipComponent } from "./modules/foursight/sponsorship/sponsorship.component";
import { HeroContentComponent } from './shared/hero-content/hero-content.component';
import { CommunicationWithStakeholdersComponent } from './modules/about/corporate-governance/communication-with-stakeholders/communication-with-stakeholders.component';
import { StakeholderInfoCardComponent } from './modules/about/corporate-governance/communication-with-stakeholders/stakeholder-info-card/stakeholder-info-card.component';
import { AuditCommitteeComponent } from './modules/about/corporate-governance/audit-committee/audit-committee.component';
import { TeamMemberCardComponent } from './modules/about/corporate-governance/team-member-card/team-member-card.component';
import { TeamMemberCarouselComponent } from './modules/about/corporate-governance/team-member-carousel/team-member-carousel.component';

@NgModule({
  declarations: [
    TruncatePipe,
    AppComponent,
    HomeComponent,
    HeaderComponent,
    ContactHeaderComponent,
    AboutBannerComponent,
    PropertiesBannerComponent,
    InvestorBannerComponent,
    LoginBannerComponent,
    FooterComponent,
    GetInTouchBannerComponent,
    SubscribeBannerComponent,
    AboutCalloutBlocksComponent,
    ReitWithADifferenceBlocksComponent,
    TestimonialComponent,
    OurImpactComponent,
    BreadcrumbsComponent,
    AboutPageBannerComponent,
    AboutTwoBlockIntroComponent,
    StaggeredFourBlocksComponent,
    WhiteImageTitleLinkBlockComponent,
    SharesBlockComponent,
    OurHistoryComponent,
    SouthafricaComponent,
    EasterneuropeComponent,
    FootprintComponent,
    PopularLinksComponent,
    ContactUsComponent,
    OurDetailsComponent,
    WhistleblowerComponent,
    FilterPipe,
    FilterDocsPipe,
    SliderComponent,
    MissionBlocksComponent,
    WhiteIconTitleTextButtonBlockComponent,
    AwardsComponent,
    MeetTheTeamComponent,
    FoursightComponent,
    SustainabilityBannerComponent,
    BannerFoursightComponent,
    TeamBlocksComponent,
    NewDevelopmentsComponent,
    TitleColComponent,
    SolutionsPropertiesComponent,
    StatsPropertiesComponent,
    InvestorDocumentsComponent,
    DocumentsSearchFilterComponent,
    VideoComponent,
    FoursightNewsComponent,
    PowerOfCollabComponent,
    OverviewComponent,
    ChartsComponent,
    BreadcrumbsWhiteComponent,
    LoginComponent,
    VacanciesComponent,
    RegistrationComponent,
    ScheduleOfPropertiesComponent,
    ScheduleTableComponent,
    SearchFilterPipe,
    SearchFilterPropertiesPipe,
    IndividualPropertyComponent,
    PropertyTitleBlockComponent,
    SafePipe,
    PropertyGalleryBlockComponent,
    PropertyAddressBlockComponent,
    SelectedProvincesComponent,
    OurAmbitionComponent,
    PropertyPortfolioComponent,
    SearchFilterAllPropertiesComponent,
    FeaturedPropertiesComponent,
    ExploreOurPropertiesComponent,
    StatsComponent,
    AllComponent,
    DraftsComponent,
    LoginDraftComponent,
    ShareholderAnalysisComponent,
    UpcomingEventsComponent,
    FoursightBannerComponent,
    VideosComponent,
    PressReleaseComponent,
    WebcastsComponent,
    ArticleComponent,
    EditionsComponent,
    CorporateGovernanceComponent,
    CompanyPaiComponent,
    IndividualPropertiesVacanciesComponent,
    PrivacyComponent,
    DataAccessComponent,
    SimilarPropertiesComponent,
    VideoVrComponent,
    FormEnquiryComponent,
    SuccessComponent,
    FailureComponent,
    ViewPdfComponent,
    EventsComponent,
    TestComponent,
    SusOurImpact,
    SusSocial,
    SortByGLAPipe,
    SponsorshipComponent,
    HeroContentComponent,
    CommunicationWithStakeholdersComponent,
    StakeholderInfoCardComponent,
    AuditCommitteeComponent,
    TeamMemberCardComponent,
    TeamMemberCarouselComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NoopAnimationsModule,
    IvyCarouselModule,
    NgxPaginationModule,
    NgxSliderModule,
    HttpClientModule,
    NgPipesModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    SlickCarouselModule,
    ModalModule,
    CdkAccordionModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
  ],
  providers: [ContentfulService, AuthenticationService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
