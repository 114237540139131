<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Financials" childTitle="Drafts"></app-breadcrumbs>
   
    
    <div class="container" id="target">
        <button (click)="logout()" class="btn btn-danger">Logout</button>    
        <div class="results-col">
            <h2>Draft Documents</h2>
            <div class="search-bar">
                <div class="col">
                    <img src="../../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
                    <input class="searchbox" type="text" placeholder="Search by keyword" [(ngModel)]="searchText"/>
                </div>
                <div class="col">
                    <img src="../../../../../assets/icons/icon-filter.svg" alt=""/>
                    <img (click)="clearFilter()" class="borderLeft" src="../../../../../assets/icons/icon-close-black.svg" alt=""/>
                </div>
            </div>
            <div class="content" *ngIf="(lessons$ | async) as lessons">
                <div class="block" *ngFor="let lesson of lessons| searchFilter: searchText; let i = index">
                    <div class="blockInner" *ngIf="!lesson.fields.published">
                        <p class="cat">{{lesson.fields.category}}</p>
                        <h5>{{lesson.fields.title}}</h5>
                        <div class="meta">
                            <p class="date">{{lesson.fields.date | date: 'd MMMM YYYY'}}</p>
                            <p *ngIf="lesson.fields.time" class="time"><img src="../../../../../assets/icons/icon-time.svg" alt=""/>{{lesson.fields.time}}</p>
                        </div>
                       <!-- <a class="clearLink" target="_new" [href]="getURL(lesson.fields.media.fields.file.url)">{{getName(lesson.fields.category)}} <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                       <a class="clearLink" target="_new" (click)="viewPDF(lesson.fields.media.fields.file.url)">{{getName(lesson.fields.category)}} <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>-->
                       <a class="clearLink" [href]="'/financials/view-pdf?id='+lesson.fields.title">{{getName(lesson.fields.category)}} <img src="../../../../../assets/icons/icon-arrow-green.svg" alt=""/></a>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
   
   

</div>