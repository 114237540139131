<div class="container">
  <h1>Quick Links</h1>
  <p>
    Get acquainted with how we connect businesses with growth-powering real
    estate opportunities. Our culture of collaboration ensures collective growth
    for our people, tenants, partners, the environment and the communities that
    we support, no matter what it takes.
  </p>
  <div class="card-container">
    <app-white-image-title-link-block
      icon="../../../assets/icons/icon-financial-report.svg"
      title="Financial Reports"
      text="At Fortress, we proactively grow the balance sheet, preserve liquidity, recycle capital and focus on operating from a solid base and an active asset management approach which allows us to position the business for growth going forward."
      linkName="Learn more"
      url="/investor-relations/investor-documents"
      [queryParams]="{ document: 'annual-reports' }"
    ></app-white-image-title-link-block>
    
    <app-white-image-title-link-block
      icon="../../../assets/icons/icon-sustainability.svg"
      title="CSI Update"
      text="The aim of our corporate social investment projects and partnerships is to support sustainable, solution-driven initiatives that assist communities where we are based."
      linkName="Learn more"
      url="/about/our-impact"
    ></app-white-image-title-link-block>
    <app-white-image-title-link-block
      icon="../../../assets/icons/icon-new-development.svg"
      title="New Developments"
      text="We launched a total of R900 million sustainability-linked bonds aligned to our ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid. Our goal is 10.7mWP by 2024."
      linkName="Learn more"
      url="/properties/new-developments"
    ></app-white-image-title-link-block>
    <app-white-image-title-link-block
      icon="../../../assets/icons/icon-property.svg"
      title="Solaring Sustainability"
      text="We launched a total of R900 million sustainability-linked bonds aligned to our ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid. Our goal is 10.7mWP by 2024."
      linkName="Learn more"
      url="/about/our-impact"
    ></app-white-image-title-link-block>
  </div>
</div>
