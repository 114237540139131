<div class="bg">
    <div class="container">
        <div class="content">
            <h1 class="main-title-colour">Let’s get</h1>
            <h1 class="main-title">in touch</h1>
        </div>
    </div>
    <div class="form-container">
        <div class="form-inner">
            <div class="col">
                <img src="../../../../assets/images/details-callout.svg" alt=""/>
                <p>We’re looking forward to hearing from you!</p>
            </div>
            <div class="col">
                <p>General Enquiries</p>
                <div class="row">
                    <p class="text">Call us: </p>
                    <p class="text">+27 (0) 86 231 7314</p>
                </div>
                <div class="row">
                    <p class="text">Email us:</p>
                    <p class="text">info@fortressfund.co.za</p>
                </div>
                <div class="hr"></div>
                <p>Find us</p>
                <div class="row">
                    <p class="text">Address: </p>
                    <p class="text">Block C, Cullinan Place,<br/> 
                        Cullinan Close, <br/>
                        Morningside, <br/>
                        2196</p>
                </div>
                <div class="row">
                    <p class="text">Johannesburg:</p>
                    <p class="text">+27 (0) 11 282 2800</p>
                </div>
                <div class="row">
                    <p class="text">Cape Town:</p>
                    <p class="text">+27 (0) 21 945 2960</p>
                </div>
                <div class="row">
                    <p class="text">Durban:</p>
                    <p class="text">+27 (0) 83 626 7161</p>
                </div>
                <div class="hr"></div>
            <!-- <p>Looking for someone specifc?</p>
                <p class="text">Use our company directory</p>
                <div class="input-holder">
                    <input type="text"  placeholder="Search by keyword" [(ngModel)]="search">
                    <img class="magnifier" src="../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
                    <img (click)="clear()" class="close" src="../../../../assets/icons/icon-close-black.svg" alt=""/>
                </div>-->
                <div  *ngIf="search!==''" >
                    <div *ngFor="let f of (folderObjs | filter:search)as folder">
                        <p>{{f.name}}</p>
                        <div class="row">
                            <p class="text">Phone number:</p>
                            <p class="text">{{f.phone}}</p>
                        </div>
                        <div class="row">
                            <p class="text">Email:</p>
                            <p class="text">{{f.email}}</p>
                        </div>
                        <div class="hr"></div>
                    </div>
                    <div *ngIf="(folderObjs | filter:search).length <1">
                        Not Found
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <app-reit-with-a-difference-blocks
        title = "About Fortress"
        desc = "Explore what it is that makes us different, or take a look through our property portfolio."
        box1icon = "../../../assets/images/details-callout.svg"
        box1Title = "Get in Touch"
        box1desc = "We strive to provide our shareholders with consistent returns through income and capital growth. Liquidity remains our focus. We continue achieving organic growth from our existing assets by developing core assets and recycling non-core assets. We regularly assess new opportunities and geographies, remaining opportunistic in a challenging market to create new avenues of growth for investors."
        box1btnText = "Learn more"
        box1link = "/get-in-touch"
        box2icon = "../../../assets/images/whistle-callout.svg"
        box2Title = "Whistleblowers"
        box2desc = "To improve transparency and ensure the highest levels of corporate governance, we have set up an independent whistleblowers’ hotline."
        box2btnText = "Click here to find out more"
        box2link = "/whistleblower"
    ></app-reit-with-a-difference-blocks>

    <app-subscribe-banner></app-subscribe-banner>

    <app-about-callout-blocks></app-about-callout-blocks>
</div>