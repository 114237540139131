<div class="container">
  <app-hero-content
    smallTopTitle="Lorem"
    title="Sponsorship"
    description="Lorem ipsum dolor sit amet consectetur. Congue blandit vivamus diam quis. Quisque mattis feugiat dignissim pulvinar rhoncus tortor interdum amet. Congue non morbi ultricies cras dolor egestas tincidunt consectetur. Ullamcorper  tempor enim facilisi."
    [heroImage]="heroImage"
    [appBreadcrumbs]="appBreadcrumbs"

  >
  </app-hero-content>
  <iframe
    src="https://www.youtube.com/embed/KlBlvrSYEhs?si=7ZwD2EzXoHj4SaBU"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
  <div class="row">
    <div class="fortress-invitational">
      <div class="copy-container">
        <div class="titles">
          <h5>Lorem</h5>
          <h2>Fortress Invitational</h2>
          <h4>17-20 October 2024</h4>
        </div>
        <div class="article">
          <p>
            Lorem ipsum dolor sit amet consectetur. Congue blandit vivamus diam
            quis. Quisque mattis feugiat dignissim pulvinar rhoncus tortor
            interdum amet. Congue non morbi ultricies cras dolor egestas
            tincidunt consectetur. Ullamcorper tempor enim facilisi.
          </p>
          <p class="saying">
            Powering the growth of <span>South Africa golf</span>
          </p>
        </div>
      </div>
      <div class="company-carousel-container">
        <div class="company-carousel">
          <img src="../../../../assets/images/sponsorship/image 10.png" />
          <img src="../../../../assets/images/sponsorship/image 11.png" />
          <img src="../../../../assets/images/sponsorship/image 9.png" />
        </div>
      </div>
    </div>
    <div class="articles">
      <div class="copy">
        <h1>The Fortress Rookie of the Year</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur. Pellentesque justo tellus
          dictumst ipsum ornare. Massa euismod tincidunt tristique mattis proin
          metus turpis gravida nunc. Tincidunt vivamus massa laoreet quis purus
          dui enim pellentesque. Egestas odio quam scelerisque velit
          pellentesque.
          <br />
          <br />
          Consequat tortor velit cum fermentum. Et viverra blandit sed sed risus
          quis. Nunc vitae massa imperdiet vel amet. Volutpat mauris tempor leo
          eu. Sollicitudin dolor morbi dignissim sapien dolor egestas. Interdum
          odio at porta purus nibh eget pellentesque sit. Volutpat ut nunc
          sagittis sit turpis tincidunt. Orci.
        </p>
      </div>

      <img src="../../../../assets/images/sponsorship/rookie-of-the-year.png" />
    </div>
    <div class="articles">
      <div class="copy">
        <h1>Cycling</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur. Pellentesque justo tellus
          dictumst ipsum ornare. Massa euismod tincidunt tristique mattis proin
          metus turpis gravida nunc. Tincidunt vivamus massa laoreet quis purus
          dui enim pellentesque. Egestas odio quam scelerisque velit
          pellentesque.
          <br />
          <br />
          Consequat tortor velit cum fermentum. Et viverra blandit sed sed risus
          quis. Nunc vitae massa imperdiet vel amet. Volutpat mauris tempor leo
          eu. Sollicitudin dolor morbi dignissim sapien dolor egestas. Interdum
          odio at porta purus nibh eget pellentesque sit. Volutpat ut nunc
          sagittis sit turpis tincidunt. Orci.
        </p>
      </div>
      <img src="../../../../assets/images/sponsorship/cycling.png" />
    </div>
  </div>
  <app-featured-properties></app-featured-properties>
  <app-subscribe-banner id="foursight"></app-subscribe-banner>
  <app-foursight-news></app-foursight-news>
</div>
