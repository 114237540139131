import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-member-card',
  templateUrl: './team-member-card.component.html',
  styleUrls: ['./team-member-card.component.scss']
})
export class TeamMemberCardComponent implements OnInit {

  teamImage: string

  constructor() { 
    this.teamImage = './assets/images/team/team-placeholder.png'
  }

  ngOnInit(): void {
  }

}
