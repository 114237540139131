import { Component, NgModule, OnInit } from '@angular/core';
// import { BreadcrumbsComponent } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.scss'],
})
export class SponsorshipComponent implements OnInit {

  heroImage: string
  appBreadcrumbs = {
    grandparentTitle: 'Home',
    grandparentLink: '/',
    parentTitle: 'foursights',
    childTitle: 'Sponsorship',
    isLarge: false,
  };
  constructor() { 
    this.heroImage = './assets/images/foursight/Sponsorship-header-banner.png'
  }

  ngOnInit(): void {
  }

}
