import { Component, Input, OnInit } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
@Component({
  selector: 'app-video-vr',
  templateUrl: './video-vr.component.html',
  styleUrls: ['./video-vr.component.scss']
})
export class VideoVrComponent implements OnInit {
  @Input() title: string = "";
  @Input() text: string = "";
  @Input() subtitle: string = "";
  @Input() embed: string = "";
  @Input() sideVideo: string = "";
  html:SafeHtml | undefined;
  constructor(private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.embed);
  }

  getLocation() {

    let sanitizedUrl =this.sanitizer.bypassSecurityTrustResourceUrl(this.embed);

   return sanitizedUrl;
  }

}
