import { Component, OnInit } from '@angular/core';
import { FortressPropertyService } from "../../services/fortress-property.service";
import { Observable } from "rxjs";


@Component({
  selector: 'app-popular-links',
  templateUrl: './popular-links.component.html',
  styleUrls: ['./popular-links.component.scss']
})
export class PopularLinksComponent implements OnInit {


  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    dots: false,
    variableWidth: false,
    responsive: [
      // {
        
      //   breakpoint: 1442,
      //   settings: {
      //     slidesToShow: 3,
      //   }
      // },
      {
        // tablet
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '50px',
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '20px',
        }
      }
    ],
    prevArrow: `<svg class="slick-arrow previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow:`<svg class="slick-arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
  };

  afterChange(e: any) {
   
    var iframes = document.querySelectorAll('iframe');
    
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
      func: 'pauseVideo' }), '*');
      });
  }
  properties=[
    {
    proptype:'Retail',
    proptypeURL:'Retail',
    desc:'Our retail portfolio focuses on the time-sensitive commuter who requires a modern, safe and convenient offering. We are located mainly in the non-metropolitan areas.',
    id:'1',
    image:"url('../../../assets/images/portfolioRetail.webp')",
    icon: '../../../assets/icons/property-features/icon-retail.svg'
  },
  {
    proptype:'Logistics',
    proptypeURL:'LOGISTICS',
    desc:'Most of the logistics portfolio are in secure state-of-the-art logistics parks in prime and convenient locations which we developed either as a pre-let or a speculative warehouse to provide our tenants with best-in-class facilities.',
    id:'2',
    image:"url('../../../assets/images/portfolioLogistcs.webp')",
    icon: '../../../assets/icons/property-features/icon-logistics.svg'
  },
  {
    proptype:'Office',
    proptypeURL:'OFFICE',
    desc:'Comprising less than 5% of our total portfolio, we have offices in excellent locations in sought-after urban areas.',
    id:'3',
    image:"url('../../../assets/images/latest-article.webp')",
    icon: '../../../assets/icons/property-features/icon-offices.svg'
  },
  {
    proptype:'Industrial',
    proptypeURL:'Industrial',
    desc:'Subscribe to receive our latest real-time vacancy and For Sale newsletter with easy-to-use search capabilities and detailed overview of current vacancies across all of our properties.',
    id:'4',
    image:"url('../../../assets/images/latest-video.webp')",
    icon: '../../../assets/icons/property-features/icon-industrial.svg'
  }
];
  constructor(private property: FortressPropertyService) { }

  ngOnInit(): void {
    
  }


}
