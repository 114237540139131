import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  profileForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    position: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    number: new FormControl('', Validators.required),
    consent: new FormControl('', Validators.required),
    answer: new FormControl('', Validators.required),
  });

  errorMessage:string='';
  num1:number=0;
  num2:number=0;
  correctAns:number=0; 
  constructor() { }

  ngOnInit(): void {
    this.num1 = this.getRandomInt(10);
    this.num2 = this.getRandomInt(10);
    this.correctAns = this.num1 + this.num2;
  }

  getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }

  get f(){
    return this.profileForm.controls;
  }

  toggle(){

  }
  onSubmit() {
    if (this.profileForm.value['answer'] != this.correctAns){
      this.errorMessage = "The sum above is incorrect";
    }else{
      this.errorMessage = "";
    }

    console.warn(this.profileForm.value);
  }

}
