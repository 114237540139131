import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reit-with-a-difference-blocks',
  templateUrl: './reit-with-a-difference-blocks.component.html',
  styleUrls: ['./reit-with-a-difference-blocks.component.scss']
})
export class ReitWithADifferenceBlocksComponent implements OnInit {
  @Input() title: string = "";
  @Input() intro: string = "";
  @Input() desc: string = "";
  @Input() box1icon: string = "";
  @Input() box1Title: string = "";
  @Input() box1desc: string = "";
  @Input() box1btnText: string = "";
  @Input() box1link: string = "";
  @Input() box2icon: string = "";
  @Input() box2Title: string = "";
  @Input() box2desc: string = "";
  @Input() box2btnText: string = "";
  @Input() box2link: string = "";
  @Input() routerQuery2: boolean = false;
  @Input() backgroundSVG: boolean = true


  constructor() { }

  ngOnInit(): void {
  }

}
