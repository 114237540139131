import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investor-documents',
  templateUrl: './investor-documents.component.html',
  styleUrls: ['./investor-documents.component.scss']
})
export class InvestorDocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
