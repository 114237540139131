import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_modal';

@Component({
  selector: 'app-foursight',
  templateUrl: './foursight.component.html',
  styleUrls: ['./foursight.component.scss']
})
export class FoursightComponent implements OnInit {

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openModal(id: string) {
    this.modalService.open(id);
}

closeModal(id: string) {
    this.modalService.close(id);
}
}
