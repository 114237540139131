import { Component, OnInit, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-white-image-title-link-block',
  templateUrl: './white-image-title-link-block.component.html',
  styleUrls: ['./white-image-title-link-block.component.scss'],
})
export class WhiteImageTitleLinkBlockComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() linkName: string = '';
  @Input() url: string = '';
  @Input() queryParams?: { [key: string]: any } = {};
  constructor() {}

  ngOnInit(): void {}
}
