<div class="bg">
    <app-breadcrumbs grandparentTitle="Home" grandparentLink="/" parentTitle="Our properties" childTitle="Schedule of properties"></app-breadcrumbs>
    <app-banner-foursight
        title="Schedule Of Properties"
        link=""
        linkText=""
        image="url(../../../assets/images/weskus.webp)"
        imageAlone="../../../assets/images/weskus.webp"
        text="Better accessibility to major arterials. custom bult, advanced security and control, more efficient storage techniques, unrivalled energy efficiency and ultimately greater cost reduction across the supply-chain."
        subTitle ="State -of-the-art warehousing that powers your business growth"
    ></app-banner-foursight>
    
    <app-schedule-table></app-schedule-table>

    <app-reit-with-a-difference-blocks
    title = "Where We Invest"
    desc = "Our diversified real estate portfolio primarily focuses on the logistics and convenience and commuter-based retail sectors in South Africa. We are actively looking for new retail and prime logistics development opportunities. <br/><br/>Our investment in NEPI Rockcastle provides us with exposure to the high-growth retail sector in Central and Eastern European (CEE) markets. We are selective and intend growing our high-quality logistics portfolio across the CEE region in the coming years. The market is in its development phase with a lot of opportunities for real estate investment for Fortress. As retail competition in the advanced economies of Western Europe is increasingly determined by the ability to successfully support e-commerce via omnichannel, growth in the logistics real estate sector in CEE will continue for decades to come."
    box1icon = "../../../assets/images/schedule-properties-callout.svg"
    box1Title = "New Developments"
    box1desc = "Our state-of-the-art developments encompass all of the needs and modern solutions that businesses require to move forward and are focussed on providing valued-based solutions for our clients."
    box1btnText = "Learn more"
    box1link = "/properties/new-developments"
    box2icon = "../../../assets/images/properties-callout.svg"
    box2Title = "Our Property Portfolio"
    box2desc = "Our diversified real estate portfolio primarily focuses on the logistics and retail sectors in South Africa, with industrial and office assets complementing our offering. Our investment in NEPI Rockcastle provides us with exposure to high-growth European economies in the Central and Eastern European market."
    box2btnText = "Explore Our Properties"
    box2link = "/"
></app-reit-with-a-difference-blocks>


    <app-subscribe-banner></app-subscribe-banner>

    <app-foursight-news></app-foursight-news>

    <app-get-in-touch-banner></app-get-in-touch-banner>
</div>