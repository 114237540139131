<div class="bg">
  <div class="container">
    <app-breadcrumbs
      grandparentTitle="Home"
      grandparentLink="/"
      parentTitle="Investor Relations"
      childTitle="Portfolio Statistics"
      [isLarge]="false"
    ></app-breadcrumbs>

    <div class="hero">
      <div class="col">
        <p class="overline">Portfolio Statistics</p>
        <h1>POWERING OUR INVESTORS</h1>
        <p>
          We strive to provide our shareholders with consistent returns through
          income and capital growth. Liquidity remains our focus. We continue
          achieving organic growth from our existing assets by developing core
          assets and recycling non-core assets. We regularly assess new
          opportunities and geographies, remaining opportunistic in a
          challenging market to create new avenues of growth for investors.
        </p>
        <a
          class="secondary-btn"
          routerLink="/get-in-touch"
          target="_blank"
          >Get in touch</a
        >
      </div>
    </div>

    <app-stats></app-stats>
    <div class="text-container">
      <p>We currently have a total of R3,6 billion in cash and available facilities at group level and remain comfortably within all debt covenants. Our unencumbered asset ratio is 38% and our loan-to-value ratio is approximately 35,5% at the date of this announcement.</p>
    </div>

    <app-reit-with-a-difference-blocks
      title="Investor Relations"
      desc="Our sustainability as a business depends on the strength of our relationships with our key stakeholders and investors. We are committed to ensuring timeous, effective and transparent communication."
      box1icon="../../../assets/images/document-callout.svg"
      box1Title="Investor Documents"
      box1desc="We aim to provide our shareholders with all the information they may require."
      box1btnText="All Investor Documents"
      box1link="/investor-relations/investor-documents"
      box2icon="../../../assets/images/sens-callout.svg"
      box2Title="SENS"
      box2desc="We provide our shareholders with information via Stock Exchange News Service (“SENS”) announcements and press releases. We provide the market with regular operational and distribution SENS announcements. "
      box2btnText="See Latest SENS"
      box2link="/investor-relations/investor-documents"
      [routerQuery2]="true"
      [backgroundSVG]="false"
    ></app-reit-with-a-difference-blocks>

    <app-staggered-four-blocks></app-staggered-four-blocks>

    <app-subscribe-banner></app-subscribe-banner>

    <app-foursight-news></app-foursight-news>


   <!--  -->

  </div>
</div>
