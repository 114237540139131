<div class="hero-container">
  <div class="hero">
    <app-breadcrumbs
      [grandparentTitle]="appBreadcrumbs.grandparentTitle"
      [grandparentLink]="appBreadcrumbs.grandparentLink"
      [parentTitle]="appBreadcrumbs.parentTitle"
      [childTitle]="appBreadcrumbs.childTitle"
      [isLarge]="appBreadcrumbs.isLarge"
    ></app-breadcrumbs>
    <div class="hero-content">
      <h4 class="overline-2">{{ smallTopTitle }}</h4>
      <h1 class="H0">{{ title }}</h1>
      <p class="body-medium">
        {{ description }}
      </p>
    </div>
  </div>
  <img *ngIf="heroImage" [src]='heroImage' />
</div>
