import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate  {
  path:string =window.location.href;
  constructor(private _router: Router) { }  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  
   
    if (localStorage.getItem('currentUser')) {  
        return true;  
    }  

    if (this.path?.includes('vacancies') && !localStorage.getItem('currentUser')) {
          this._router.navigate(['/login']);  
          return false; 
      }
    this._router.navigate(['']);  
    return false;  
}  
}
