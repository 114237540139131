import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from "rxjs";
import { ContentfulService } from "../../../services/contentful.service";
import {DomSanitizer} from '@angular/platform-browser';



@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {
  id: string = '';
  show:boolean=false;
  hold:any;
  constructor(private sanitizer:DomSanitizer,private route: ActivatedRoute,private contentful: ContentfulService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params.id;
      console.log(this.id);
    });
    this.contentful.getContents().subscribe((result) => {
      for (let i = 0; i < result.length; i++) {
        var holdTitle = (result[i].fields as { title: string }).title;

        if (holdTitle === this.id){
          var holdPublished = (result[i].fields as { published: string })
          .published;
          if (!holdPublished){
           
            this.show = false;
          }else{
            var holdtest = (result[i].fields as { media: any }).media.fields;
            this.hold = holdtest;
            this.show = true;
            
          }
        }
        
        
        
      }
    });
  }
 

  getURL(url:string){
    let newURL = "https:"+url;
  //  let sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(newURL);
    return newURL;
  }

 
 
}
